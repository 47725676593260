import { css, jsx } from "@emotion/core";
import { DefaultButton, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { EmptyNotification } from "../../components/empty-notification.component";
import { InformationRow } from "../../components/information-row.component";
import { Permission } from "../../components/permission.component";
import { status_options } from "../../config/status-map";
import { usePermission } from "../../hooks/use-permission.hook";
import { getPatient, getPatientUpload } from "../../services/patients.service";
import { DATE_FORMAT, dateFormatter } from "../../utils/date-format";
import { getFullName, getFullNameKanji } from "../../utils/full-name";

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

const removeSlashFromResultString = (str) => {
  if (!str) return;
  return str.replace(/\//g, "");
};

/** @jsx jsx */
export const ViewPage = () => {
  console.log("patient ViewPage is called");
  const { t } = useTranslation();
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);
  const [patientUpload, setPatientUpload] = useState("");
  const isAllowedViewRegistration = usePermission("Registration.View") || false;
  const history = useHistory();

  useEffect(() => {
    if (patientId) {
      getPatient(patientId).then((response) => {
        setPatient(response.data);
        response.data.identificationUpload?.fileName
          && getPatientUpload(response.data.identificationUpload?.fileName).then((response) => {
            setPatientUpload(response);
          });
      }).catch((error) => console.error("get patient", error));
    }
  }, [patientId]);

  return patient && (
    <div css={styles} className="animationIn">
      <div className="left-right">
        <h1>
          {getFullNameKanji(patient)}{" "}
          <span className="subtitle ms-fontSize-14 ms-fontWeight-regular">
            {t("Patient detail")}
          </span>
        </h1>
        <div style={{ display: "flex", gap: 12 }}>
          <DefaultButton onClick={() => history.push(`/patients`)}>
            {t("Back to patient list")}
          </DefaultButton>
          <Permission only="Patient.Edit">
            <DefaultButton
              text={t("Operation")}
              split
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              menuProps={{
                "items": [{
                  key: "editDetails",
                  text: t("Edit patient details"),
                  iconProps: { iconName: "Edit" },
                  onClick: () => history.push(`/patients/edit/${patientId}`),
                }],
              }}
            />
          </Permission>
        </div>
      </div>

      <div className="information-section" dir="ltr">
        <div className="ms-depth-4 bg-white section-container">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <InformationRow label={t("Customer number")}>{patient.id}</InformationRow>
              <InformationRow label={t("Company name")}>{patient.companyName}</InformationRow>
              <InformationRow label={t("Name - Kanji")}>{getFullNameKanji(patient)}</InformationRow>
              <InformationRow label={t("Name - Katakana")}>{getFullName(patient)}</InformationRow>
              <InformationRow label={t("Gender")}>{t(patient.gender)}</InformationRow>
              <InformationRow label={t("Phone number")}>{patient.phoneNumber}</InformationRow>
              <InformationRow label={t("Email address")}>{patient.emailAddress}</InformationRow>
              <InformationRow label={t("Date of birth")}>
                {dayjs(patient.dateOfBirth).format(DATE_FORMAT.JP.YEAR_MONTH_DAY)}
              </InformationRow>
              <InformationRow label={t("Age")}>
                {dayjs().diff(dayjs(patient.dateOfBirth), "year")}
              </InformationRow>
              <InformationRow label={t("Classification")}>
                {patient.patientClassification}
              </InformationRow>
            </div>
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <InformationRow label={t("Zipcode")}>{patient.postalCode}</InformationRow>
              <InformationRow label={t("Prefecture name")}>{patient.prefectureName}</InformationRow>
              <InformationRow label={t("City")}>{patient.city}</InformationRow>
              <InformationRow label={t("Address one")}>{patient.addressOne}</InformationRow>
              <InformationRow label={t("Address two")}>{patient.addressTwo}</InformationRow>
              <InformationRow label={t("Health insurance card")}>
                {patient.identificationUpload === null
                  ? t("Identification has not uploaded")
                  : t("Identification has uploaded")}
              </InformationRow>
              {patientUpload && (
                <img style={{ width: "75%" }} alt={t("Patient uploaded")} src={patientUpload} />
              )}
              <InformationRow label={t("Expiration Year/Date")}>
                {patient.identificationExpirationDate
                  ? dayjs(patient.identificationExpirationDate).format(DATE_FORMAT.JP.YEAR_MONTH)
                  : "-"}
              </InformationRow>
              <InformationRow label={t("Created Date")}>
                {dayjs(patient.createdAt).format(DATE_FORMAT.JP.YMD_HM)}
              </InformationRow>
              <InformationRow label={t("Note")}>{patient.note}</InformationRow>
            </div>
          </div>
        </div>
      </div>
      <h3>{t("Registered Testing Kit")}</h3>
      <div className="ms-depth-4 bg-white section-container">
        {patient.registrations.length !== 0
          ? (
            <ShimmeredDetailsList
              setKey="items"
              items={patient.registrations}
              columns={registrationsColumns(t, isAllowedViewRegistration)}
              selectionMode={SelectionMode.none}
              enableShimmer={patient.registrations.length === 0}
              ariaLabelForShimmer="Content is being fetched"
              ariaLabelForGrid="Item details"
              listProps={shimmeredDetailsListProps}
            />
          )
          : <EmptyNotification>{t("Registration list is empty")}</EmptyNotification>}
      </div>
    </div>
  );
};

const styles = css`
    max-width: 960px;
    .left-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ms-DetailsRow-fields {
      height: 60px;
      align-items: center;
    }

.result-assign-buttons {
    width: 270px;
    display: flex;
}

.result-assign-button {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 4px;
    padding-left: 16px;
    min-width: 80px;
    height: 32px;
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(138, 136, 134);
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
    &--positive.active {
        color: #fff;
        border-color: #FF5209;
        background-color: #FF5209;
    }
    
    &--negative.active {
        color: #fff;
        border-color: #66AC46;
        background-color: #66AC46;
    }
    &--retest.active {
        color: #fff;
        border-color: #FFB526;
        background-color: #FFB526;
    }

    &--ε2ε2.active {
      color: rgb(50, 49, 48);
      border-color: #CBEBFE;
      background-color: #CBEBFE;
  }

  &--ε3ε2.active {
      color: rgb(50, 49, 48);
      border-color: #A6CFE7;
      background-color: #A6CFE7;
  }

  &--ε3ε3.active {
      color: rgb(50, 49, 48);
      border-color: #B5EACE;
      background-color: #B5EACE;
  }

  &--ε4ε2.active {
      color: rgb(50, 49, 48);
      border-color: #C8F392;
      background-color: #C8F392;
  }

  &--ε4ε3.active {
      color: rgb(50, 49, 48);
      border-color: #FFE299;
      background-color: #FFE299;
  }

  &--ε4ε4.active {
      color: rgb(50, 49, 48);
      border-color: #FAA28F;
      background-color: #FAA28F;
  }
}
`;

const registrationsColumns = (t, isAllowedViewRegistration) => [
  {
    key: "name",
    name: t("Created at"),
    fieldName: "createdtime",
    onRender: (item) => dateFormatter(item.createdAt),
  },
  {
    key: "productname",
    name: t("Product Name"),
    fieldName: "productname",
    onRender: (item) => item.inventory?.product?.name || "",
  },
  {
    key: "barcode",
    name: t("Barcode"),
    fieldName: "barcode",
    onRender: (item) =>
      item.barcode
        ? (isAllowedViewRegistration
          ? <Link to={`/registrations/${item.id}`}>{item.barcode}</Link>
          : item.barcode)
        : "N/A",
  },
  {
    key: "status",
    name: t("Status"),
    fieldName: "status",
    onRender: (item) => t(status_options[item.status]),
  },
  {
    key: "testingDate",
    name: t("Finished date"),
    fieldName: "testingDate",
    onRender: (item) =>
      item.testingDate ? dayjs(item.testingDate).format(DATE_FORMAT.JP.YEAR_MONTH_DAY) : "",
  },
  {
    key: "result",
    name: t("Result"),
    fieldName: "result",
    onRender: (item) => ResultLabel(t, item.result),
  },
];

const ResultLabel = (t, result) => {
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    );
  };
  return result && (
    <div className="result-assign-buttons">
      <div
        className={`result-assign-button result-assign-button--${removeSlashFromResultString(result.toLowerCase())} active`}
      >
        {t(toTitleCase(result))}
      </div>
    </div>
  );
};
