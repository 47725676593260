import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { SelectionMode, DetailsList, DetailsListLayoutMode, CheckboxVisibility, SearchBox, Dropdown, Stack, DatePicker } from 'office-ui-fabric-react';
import { getDateObj } from '../utils/date-format';
import dayjs from 'dayjs';

const filtersRegistrationStatus = t => [
    { key: 'all', name: t('All')},
    { key: 'completed', name: t('Completed')},
    { key: 'pending_follow_up', name: t('Pending follow up')},
    { key: 'in_testing', name: t('In testing')},
    { key: 'received_in_lab', name: t('Received in lab')},
    { key: 'patient_completed', name: t('Patient Completed')},
    { key: 'in_progress_barcode_registered', name: t('In Progress Barcode Registered')},
    { key: 'in_progress', name: t('In Progress')},
];

const invisibleColumns = [
    { key: 'column1', name: 'View by', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true, className: 'results-filter-options' }
];

const fromOptions = [
    { key: 'all', text: 'All' },
    { key: '1', text: 'Today' },
    { key: '7', text: 'Last week' },
    { key: '40', text: 'Last 30 days' },
    { key: '90', text: 'Last 90 days' },
    { key: '365', text: 'Last year' },
];

const numberOfRowsOptions = [
    { key: 10, text: '10' },
    { key: 50, text: '50' },
    { key: 100, text: '100' },
]


/** @jsx jsx */
export const Filters = ({ 
    onChange,
    selectionRegistrationStatus,
    dateFrom,
    numberOfRows,
    setQuery,
    keywords
 }) => {
    const { t } = useTranslation();

    return (
        <Stack tokens={{ childrenGap: 30 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <span className="filter-header ms-fontSize-12">{t('Number of rows')}</span>
                <Dropdown
                    placeholder={t('Number of rows')}
                    options={numberOfRowsOptions}
                    selectedKey={numberOfRows}
                    onChange={(_, item) => setQuery({ limit: item.key })}
                />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
                <span className="filter-header ms-fontSize-12">{t('Search')}</span>
                <SearchBox placeholder={t("Search")} value={keywords} onSearch={newValue => setQuery({ keywords: (newValue || '') })} onClear={() => setQuery({ keywords: ''})} />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
                <span className="filter-header ms-fontSize-12">{t('Registration status')}</span>
                <DetailsList
                    isHeaderVisible={false}
                    compact={true}
                    items={filtersRegistrationStatus(t)}
                    columns={invisibleColumns}
                    setKey="set"
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.single}
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={selectionRegistrationStatus.current}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
                <span className="filter-header ms-fontSize-12">{t('Date added')}</span>
                <DatePicker
                    value={getDateObj(dateFrom)}
                    placeholder={t('Date added')}
                    formatDate={date => dayjs(date).format('YYYY年MM月DD日')}
                    onSelectDate={value => setQuery({ dateFrom: value.toDateString() })}
                />
            </Stack>
        </Stack>);
};