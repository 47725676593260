import { css, jsx } from "@emotion/core";
import { DefaultButton, MessageBar, MessageBarType, PrimaryButton } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { getPatient, updatePatient } from "../../services/patients.service";
import { getFullNameKanji } from "../../utils/full-name";
import { PatientEditForm } from "./components/patient-form.component";

/** @jsx jsx */
export const EditPage = () => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);
  const [patientUpload, setPatientUpload] = useState("");
  const [form, setForm] = useState({});
  const [errorBarState, setErrorBarState] = useState({ show: false, text: "" });
  const history = useHistory();

  useEffect(() => {
    if (patientId) {
      getPatient(patientId).then((response) => {
        setPatient(response.data);
      }).catch((error) => console.error("get patient", error));
    }
  }, [patientId]);

  useEffect(() => {
    if (patient) {
      setForm({
        ...form,
        id: patient?.id || 0,
        companyName: patient?.companyName || "",
        firstName: patient?.firstName || "",
        firstNameJa: patient?.firstNameJa || "",
        lastName: patient?.lastName || "",
        lastNameJa: patient?.lastNameJa || "",
        emailAddress: patient?.emailAddress || "",
        phoneNumber: patient?.phoneNumber || "",
        gender: patient?.gender || "",
        dateOfBirth: dayjs(patient?.dateOfBirth, "YYYY-MM-DD").toDate() || new Date(),
        addressOne: patient?.addressOne || "",
        addressTwo: patient?.addressTwo || "",
        postalCode: patient?.postalCode || "",
        prefectureName: patient?.prefectureName || "",
        city: patient?.city || "",
        country: patient?.country || "",
        note: patient?.note || "",
        patientClassification: patient?.patientClassification || "",
        selectedOrganization: patient.organization
          ? { value: patient.organization.id, label: patient.organization.name }
          : null,
      });
    }
  }, [patient]);

  useEffect(() => {
    if (errorBarState.show) {
      setTimeout(() => {
        setErrorBarState({ show: false, text: "" });
      }, 2000);
    }
  }, [errorBarState.show]);

  const handleSaveChange = useCallback(() => {
    const updateDOB = { ...form };
    updateDOB.dateOfBirth = form.dateOfBirth.toDateString();
    updateDOB.organizationId = form.selectedOrganization ? form.selectedOrganization.value : null;

    updatePatient(updateDOB)
      .then(() => {
        history.push(`/patients/${patientId}`);
      })
      .catch((error) =>
        setErrorBarState((s) => ({
          ...s,
          show: true,
          text: error.response?.status === 400
            ? t(error.response?.data.error.message)
            : t("Something Went Wrong"),
        }))
      );
  }, [form]);

  return (
    <React.Fragment>
      {errorBarState.show
        ? (
          <MessageBar
            messageBarType={MessageBarType.error}
            onDismiss={() => setErrorBarState({ show: false, text: "" })}
          >
            {errorBarState.text}
          </MessageBar>
        )
        : null}
      {patient && (
        <div css={styles} className="animationIn">
          <div className="left-right">
            <h1>
              {getFullNameKanji(patient)}{" "}
              <span className="subtitle ms-fontSize-14 ms-fontWeight-regular">
                {t("Patient detail")}
              </span>
            </h1>
            <div className="buttons">
              <DefaultButton
                text={t("Back")}
                onClick={() => history.push(`/patients/${patientId}`)}
              />
              <PrimaryButton text={t("Save")} onClick={() => handleSaveChange()} />
            </div>
          </div>
          <div className="information-section" dir="ltr">
            <div className="ms-depth-4 bg-white section-container">
              <PatientEditForm form={form} setForm={setForm} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const styles = css`
    max-width: 960px;
    .left-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .buttons {
            button {
                margin-left: 15px;
            }
        }
    }
`;
