import { css, jsx } from "@emotion/core";
import { Dropdown, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getRoles } from "../../services/roles.service";
import { createAdministrator } from "../../services/users.service";

/** @jsx jsx */
export const CreatePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [administrator, setAdministrator] = useState({});
  const [errors, setErrors] = useState({});
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getRoles().then((response) => {
      const roleData = response.data.map((role) => ({
        key: role.id,
        text: role.name,
      }));
      setRoles(roleData);
      setAdministrator({ ...administrator, role: roleData[0].key });
    });
  }, [setRoles]);

  const onChange = useCallback((e, newValue) => {
    setAdministrator({ ...administrator, [e.target.name]: newValue });
  }, [setAdministrator, administrator]);

  const onSelectChange = useCallback((e, newValue) => {
    setAdministrator({ ...administrator, [e.target.getAttribute("name")]: newValue.key });
  }, [setAdministrator, administrator]);

  const parseErrors = useCallback((errors) => {
    if (!Array.isArray(errors.message)) {
      return;
    }
    const newErrors = {};
    for (let err of errors.message) {
      if (err.property) {
        // translate the messages returned from the backend and join them together
        t(err.errors.map((message) => t(message)));
        newErrors[err.property] = err.errors.map((message) => t(message)).join(", ");
      }
    }
    setErrors(newErrors);
  }, [setErrors, t]);

  const onSaveAdministrator = useCallback(() => {
    createAdministrator(administrator).then((response) => {
      setErrors({});
      history.push(`/administrators/${response.data.id}`);
    }).catch((error) => {
      if (error.response && error.response.data) {
        parseErrors(error.response.data);
      }
    });
  }, [administrator, setErrors, history, parseErrors]);

  return administrator && (
    <div>
      <div className="left-right">
        <h1>{t("Add Administrator")}</h1>
        <PrimaryButton
          text={t("Save")}
          onClick={onSaveAdministrator}
        />
      </div>
      <Stack>
        <div css={inputFieldsStyle}>
          <TextField
            name="firstName"
            label={t("First name")}
            value={administrator.firstName}
            errorMessage={errors.firstName}
            onChange={onChange}
          />
          <TextField
            name="lastName"
            label={t("Last name")}
            value={administrator.lastName}
            errorMessage={errors.lastName}
            onChange={onChange}
          />
          <TextField
            name="emailAddress"
            label={t("Email address")}
            value={administrator.emailAddress}
            errorMessage={errors.emailAddress}
            onChange={onChange}
          />
          <TextField
            name="password"
            type="password"
            label={t("Password")}
            value={administrator.password}
            errorMessage={errors.password}
            onChange={onChange}
          />
          <Dropdown
            name="defaultLanguage"
            label={t("Default language")}
            selectedKey={administrator.defaultLanguage}
            errorMessage={errors.defaultLanguage}
            options={[
              {
                key: "en-us",
                text: t("English (US)"),
              },
              {
                key: "ja",
                text: t("Japanese"),
              },
            ]}
            onChange={onSelectChange}
          />
          <Dropdown
            name="role"
            label={t("Role")}
            selectedKey={administrator.role}
            errorMessage={errors.role}
            options={roles}
            onChange={onSelectChange}
          />
        </div>
      </Stack>
    </div>
  );
};

const inputFieldsStyle = css`
    gap: 24px;
    display: grid;
    max-width: 640px;
    grid-template-columns: repeat(2, 1fr);
`;
