import { css, jsx } from "@emotion/core";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  Stack,
} from "@fluentui/react";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Badge } from "../../../components/badge.component";
import { NoteToolTip } from "../../../components/note-tooltip.component";
import { PlainCycleList } from "../../../components/plain-cycle-list.component";
import { getCycleById, sendEmailToRegistration } from "../../../services/scanning.service";
import { format } from "../../../utils/code-format";
import { getFullNameKanji } from "../../../utils/full-name";
import { RESULT_MASTER } from "../../../utils/result-enums";

/** @jsx jsx */
export const ResultPage = () => {
  const { t } = useTranslation();
  const { cycleId } = useParams();
  let history = useHistory();
  const [isHiddenModal, setIsHiddenModal] = useState(true);
  const [currentCycleList, setCurrentCycleList] = useState([]);
  const [cycle, setCycle] = useState({});
  const [selectedRegistration, setSelectedRegistration] = useState({});

  const [isSendingResult, setIsSendingResult] = useState(false);

  const fetchCycleDetail = useCallback(() => {
    getCycleById(cycleId)
      .then((response) => {
        setCycle(response.data);
        setCurrentCycleList(response.data.bulkScanItems);
      })
      .catch((error) => console.error(`fetching cycle ${cycleId} error`, error));
  }, [cycleId]);

  const handleSendEmail = () => {
    if (selectedRegistration) {
      setIsSendingResult(true);
      const confirmEmail = { id: selectedRegistration.id, result: selectedRegistration.result };
      sendEmailToRegistration(confirmEmail)
        .then((response) => {
          setIsSendingResult(false);
          setSelectedRegistration({});
          setIsHiddenModal(true);
          fetchCycleDetail();
        })
        .catch((error) => {
          setIsSendingResult(false);
          setSelectedRegistration({});
          setIsHiddenModal(false);
          console.error("send email ", error);
        });
    }
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    );
  };

  useEffect(() => {
    if (!cycleId) history.push("/scan/cycles");
    fetchCycleDetail();
  }, [cycleId, fetchCycleDetail, history]);

  const ResultAssignButtons = ({ item }) => (
    item.barcode !== "000000000000" && item.barcode !== "FFFFFFFFFFFF"
      && item.result
      ? (
        <div className="result-assign-buttons">
          <div
            className={`result-assign-button result-assign-button--${item.result.toLowerCase()} active`}
          >
            {t(toTitleCase(item.result))}
          </div>
        </div>
      )
      : <React.Fragment></React.Fragment>
  );

  const getInventoryCompanyNameByBarcode = (barcode) =>
    cycle.inventoryItems.find((a) => a.barcode === barcode)?.preassignedOrganizationName || "";

  const ConfirmEmail = ({ item }) => (
    (item.registration.result === RESULT_MASTER.COVID.NEGATIVE
      || item.registration.result === RESULT_MASTER.COVID.POSITIVE)
    && (
      <button
        type="button"
        className="result-assign-button result-assign-button--confirmemial"
        onClick={() => {
          setIsHiddenModal(false);
          setSelectedRegistration(item.registration);
        }}
      >
        {item.registration.isEmailed ? t("Resend email") : t("Send email")}
      </button>
    )
  );

  const cycleListColumnsConfig = () => {
    return [
      {
        minWidth: 30,
        maxWidth: 30,
        name: "#",
        key: "positionnumber",
        fieldName: "positionnumber",
        className: "cycleListItem cycleListItem--number",
        onRender: (item) => `# ${item.trayPosition}`,
      },
      {
        key: "result",
        name: t("Result"),
        fieldName: "result",
        className: "cycleListItem",
        isResizable: true,
        minWidth: 120,
        maxWidth: 120,
        onRender: (item) => <ResultAssignButtons item={item} />,
      },
      {
        minWidth: 120,
        maxWidth: 120,
        key: "barcode",
        name: t("Testing number"),
        fieldName: "barcode",
        className: "cycleListItem",
        isResizable: true,
        onRender: (item) => (
          <p className="cycleListItem__barcode">
            {item.barcode === "000000000000" ? "その他" : format(item.barcode)}
          </p>
        ),
      },
      {
        minWidth: 120,
        maxWidth: 160,
        key: "fullname",
        name: t("Full name"),
        fieldName: "fullname",
        className: "cycleListItem",
        isResizable: true,
        onRender: (item) => (
          <span className="cycleListItem__name">
            {item.barcode === "FFFFFFFFFFFF"
              ? "Custom"
              : getFullNameKanji(item.registration?.patient)}
          </span>
        ),
      },
      {
        minWidth: 120,
        maxWidth: 200,
        key: "orgName",
        name: t("Org name"),
        fieldName: "orgName",
        className: "cycleListItem",
        isResizable: true,
        onRender: (
          item,
        ) => (`${item.registration?.patient?.companyName || ""}/${
          getInventoryCompanyNameByBarcode(item.registration?.barcode) || ""
        }`),
      },
      {
        key: "registered",
        name: t("Registered"),
        fieldName: "registered",
        className: "cycleListItem",
        minWidth: 100,
        maxWidth: 120,
        onRender: (item) => (item.registration?.createdAt
          ? dayjs(item.registration.createdAt).format("YYYY年MM月DD日")
          : "N/A"),
      },
      {
        key: "note",
        name: t("Note"),
        fieldName: "note",
        className: "cycleListItem",
        minWidth: 80,
        maxWidth: 80,
        onRender: (item) =>
          item.registration?.patient?.note
            ? <NoteToolTip noteDetail={item.registration?.patient?.note} tooltipId={item.barcode} />
            : "N/A",
      },
      {
        key: "additions",
        name: t("Additions"),
        fieldName: "additions",
        className: "additions",
        minWidth: 200,
        onRender: (item) => (
          <div className="badges">
            <Badge type={item?.registration?.isPriority ? "danger" : "default"}>{t("Rapid")}
            </Badge>&nbsp;
            <Badge type={item?.registration?.requiresProof ? "primary" : "default"}>
              {t("PONR")}
            </Badge>
          </div>
        ),
      },
      {
        key: "lastEmailSent",
        name: t("Last email sent"),
        fieldName: "lastEmailSent",
        className: "cycleListItem",
        minWidth: 140,
        maxWidth: 160,
        onRender: (item) => (item.registration?.lastEmailSentAt
          ? dayjs(item.registration.lastEmailSentAt).format("YYYY年MM月DD日 HH:mm A")
          : "N/A"),
      },

      {
        key: "confirmemail",
        name: t("Confirm"),
        fieldName: "confirmemail",
        className: "cycleListItem",
        onRender: (item) => item.registration?.result && <ConfirmEmail item={item} />,
      },
    ];
  };

  const cycleListColumns = cycleListColumnsConfig();

  console.log("currentCycleList ", currentCycleList);

  return (
    <div css={styles} className="animationIn">
      <h1>{`${t("Cycle result")} #${cycleId}`}</h1>
      <div className="ms-depth-4 bg-white section-container">
        <Stack tokens={{ childrenGap: 40 }} style={{ overflowX: "scroll" }}>
          <PlainCycleList cycleList={currentCycleList} cycleListColumns={cycleListColumns} />
          <Stack className="cycle-operations" horizontal tokens={{ childrenGap: 40 }}>
            <DefaultButton text={t("Back")} onClick={() => history.push("/scan/cycles")} />
            <DefaultButton
              text={t("Print")}
              onClick={() => {
                window.print();
              }}
            />
          </Stack>
        </Stack>
      </div>
      <Dialog
        hidden={isHiddenModal}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("Confirm email result"),
          subText: t("Are you sure you want to send email result?"),
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        <DialogFooter>
          {isSendingResult
            ? (
              <Spinner
                label={t("Sending email, please wait...")}
                ariaLive="assertive"
                labelPosition="right"
              />
            )
            : (
              <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
                <DefaultButton
                  text={t("No")}
                  onClick={() => {
                    setIsHiddenModal(true);
                  }}
                />
                <PrimaryButton
                  text={t("Yes")}
                  onClick={() => {
                    handleSendEmail();
                  }}
                />
              </Stack>
            )}
        </DialogFooter>
      </Dialog>
    </div>
  );
};

const styles = css`

.cycleListItem {
    display: inline-flex;
    align-items: center;
    
    &--number {
        font-size: 14px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
    }
    &--delete{
        .clearButton {
            margin-left: 16px;
        }
    }
    &__barcode {
        opacity: .9;
        margin-bottom: 2px;
        font-weight: bold;
        margin-top: 0;
    }
}
.result-assign-buttons {
    width: 270px;
    display: flex;
}

.result-assign-button {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    min-width: 80px;
    height: 32px;
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(138, 136, 134);
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
    &--positive.active {
        color: #fff;
        border-color: #FF5209;
        background-color: #FF5209;
    }
    
    &--negative.active {
        color: #fff;
        border-color: #66AC46;
        background-color: #66AC46;
    }
    &--retest.active {
        color: #fff;
        border-color: #FFB526;
        background-color: #FFB526;
    }

    &--confirmemial {
        cursor: pointer;
        &:hover {
            background-color: rgb(243, 242, 241);
            color: rgb(32, 31, 30);
        }
    }
}
.result-assign-button:not(:first-of-type) {
    margin-left: -1px;
}

@media only print {
    padding: 5px 10px;
    h1 {
        font-size: 14px;
    }
    .cycle-operations {
        display: none;
    }
    .section-container {
        padding: 0;
        box-shadow: none;
    }
    table {
        .result-assign-button {
            border: none;
            background: none;
            font-size: 12px;
            color: #000;
        }
        th, th > * , td, td > * {
            font-size: 12px !important;
            padding: 0 !important;
            margin: 0 !important;
        }
        tr, tr > * {
            height: auto !important;
            padding: 0 !important;
            max-height: 16px !important;
            margin: 0 !important;
        }

        tr td:nth-of-type(6),
        tr td:nth-of-type(7),
        tr td:nth-of-type(9),
        tr th:nth-of-type(6),
        tr th:nth-of-type(7),
        tr th:nth-of-type(9) {
            display: none;
        }
    }
}
`;
