import { css, jsx } from "@emotion/core";
import {
  DatePicker,
  DefaultButton,
  DetailsRow,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from "@fluentui/react";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams } from "use-query-params";

import { Permission } from "../../components/permission.component";
import { status_options } from "../../config/status-map";
import { geShippedInventories } from "../../services/inventory.service";
import { generateShippedReport } from "../../services/scanning.service";
import { dateTimeFormatJp } from "../../utils/date-format";
import { getDateObj } from "../../utils/date-format";

/** @jsx jsx */
export const ShippedBarcode = () => {
  const { t } = useTranslation();
  const [inventories, setInventories] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [selectedAddition, setSelectedAddition] = useState("");

  const [query, setQuery] = useQueryParams({
    dateFrom: StringParam,
    dateTo: StringParam,
  });
  const { dateFrom, dateTo } = query;

  const AdditionsOptions = [
    { key: "All", text: t("All") },
    { key: "isPriority", text: t("Rapid") },
    { key: "needsProof", text: t("PONR") },
  ];

  const shimmeredDetailsListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
  };

  const onKeywordSearch = (value) => setKeywords(value);

  const handleSearchBoxChange = (_, value) => {
    if (!value) {
      setKeywords("");
    }
  };

  const loadInventories = () => {
    geShippedInventories()
      .then((response) => setInventories(response.data))
      .catch((error) => console.error("fetching inventories", error));
  };

  // load barcodes
  useEffect(() => {
    loadInventories();
  }, []);

  const keywordsFilter = useCallback((inventory) => {
    return inventory.barcode.includes(keywords)
      || inventory.organization?.name?.toLowerCase().includes(keywords.toLowerCase());
  }, [inventories, keywords]);

  const additionFilter = useCallback((inventory) => {
    if (selectedAddition === "isPriority") {
      return inventory.isPriority;
    }
    if (selectedAddition === "needsProof") {
      return inventory.requiresProof;
    }
    return true;
  }, [inventories, selectedAddition]);

  const handleExportToCsv = useCallback(() => {
    generateShippedReport({ dateFrom, dateTo });
  }, [dateFrom, dateTo]);

  return (
    <div css={styles} className="animationIn">
      <h1>{t("Shipped Barcode")}</h1>
      <div className="ms-depth-4 bg-white section-container">
        <Stack tokens={{ childrenGap: 30 }}>
          {inventories.length > 0 && (
            <React.Fragment>
              <Stack tokens={{ childrenGap: 12 }}>
                <h2>{t("Shipped Barcode")}</h2>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
                  <Stack horizontal tokens={{ childrenGap: 24 }} verticalAlign="end">
                    <SearchBox
                      styles={{ root: { width: 200 } }}
                      placeholder={t("Search")}
                      onChange={handleSearchBoxChange}
                      onSearch={onKeywordSearch}
                      onClear={() => setKeywords("")}
                    />
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: 24 }}>
                    <DatePicker
                      value={getDateObj(dateFrom)}
                      placeholder={t("Date from")}
                      formatDate={(date) => dayjs(date).format("YYYY年MM月DD日")}
                      onSelectDate={(value) => setQuery({ dateFrom: value.toDateString() })}
                    />
                    <DatePicker
                      value={getDateObj(dateTo)}
                      placeholder={t("Date to")}
                      formatDate={(date) => dayjs(date).format("YYYY年MM月DD日")}
                      onSelectDate={(value) => setQuery({ dateTo: value.toDateString() })}
                    />
                    <Permission only="Inventory.View">
                      <DefaultButton
                        iconProps={{ iconName: "Download" }}
                        onClick={handleExportToCsv}
                        disabled={!dateFrom}
                        text={t("Export as CSV")}
                      />
                    </Permission>
                  </Stack>
                </Stack>
                <ShimmeredDetailsList
                  setKey="items"
                  items={inventories.filter(keywordsFilter).filter(additionFilter)}
                  columns={inventoriesColumns(t)}
                  selectionMode={SelectionMode.none}
                  enableShimmer={inventories.length === 0}
                  ariaLabelForShimmer="Inventories are being fetched"
                  ariaLabelForGrid="Inventory details"
                  listProps={shimmeredDetailsListProps}
                  onRenderRow={onRenderRow}
                />
              </Stack>
            </React.Fragment>
          )}
        </Stack>
      </div>
    </div>
  );
};

const styles = css`
.inventory-barcode {
    display: inline-flex;
    align-items: center;
}
.additions-filter {
    width: 160px;
}
.new-added-row {
    animation-name: fadeIn;
    animation-duration: 2.5s;
}

@keyframes fadeIn {
    from {background-color: #deecf9;}
    to {background-color: #fff;}
  }
`;

export const onRenderRow = (props) => {
  const customStyles = {};

  if (props) {
    if (props.item.isNewAdded) {
      return (
        <DetailsRow
          {...props}
          className="new-added-row"
          styles={customStyles}
        />
      );
    } else {
      return (
        <DetailsRow
          {...props}
          styles={customStyles}
        />
      );
    }
  }
  return null;
};

export const inventoriesColumns = (t) => {
  const getFormattedCode = (rawCode) =>
    rawCode.substring(0, 4) + " " + rawCode.substring(4, 8) + " " + rawCode.substring(8);

  return [
    {
      key: "barcode",
      name: t("Testing number"),
      fieldName: "barcode",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 200,
      onRender: (item) => getFormattedCode(item.barcode),
    },
    {
      key: "organizationName",
      name: t("Organization name"),
      fieldName: "organization.name",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 200,
      onRender: (item) => item?.organization?.name,
    },
    {
      key: "registrationStatus",
      name: t("Status"),
      fieldName: "registration.status",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 100,
      onRender: (item) => t(status_options[item?.registration?.status]),
    },
    {
      key: "createdAt",
      name: t("Shipped at"),
      fieldName: "createdAt",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 150,
      onRender: (item) => dateTimeFormatJp(item.createdAt),
    },
  ];
};
