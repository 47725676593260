import { css, jsx } from "@emotion/core";
import dayjs from "dayjs";
import {
  CheckboxVisibility,
  DatePicker,
  DetailsList,
  DetailsListLayoutMode,
  SearchBox,
  SelectionMode,
  Stack,
} from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import { getDateObj } from "../../../utils/date-format";

const filtersTestResults = (t) => [
  { key: "all", name: t("All") },
  { key: "ε2/ε2", name: "ε2/ε2" },
  { key: "ε3/ε2", name: "ε3/ε2" },
  { key: "ε3/ε3", name: "ε3/ε3" },
  { key: "ε4/ε2", name: "ε4/ε2" },
  { key: "ε4/ε3", name: "ε4/ε3" },
  { key: "ε4/ε4", name: "ε4/ε4" },
  { key: "retest", name: t("re test") },
];

const invisibleColumns = [
  {
    key: "column1",
    name: "View by",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
    className: "results-filter-options",
  },
];

/** @jsx jsx */
export const Filters = ({
  selectionTestResult,
  dateFrom,
  dateTo,
  setQuery,
  keywords,
}) => {
  const { t } = useTranslation();

  return (
    <Stack tokens={{ childrenGap: 30 }}>
      <Stack tokens={{ childrenGap: 10 }}>
        <span className="filter-header ms-fontSize-12">{t("Search Barcode")}</span>
        <SearchBox
          placeholder={t("Search Barcode")}
          value={keywords}
          onSearch={(newValue) => setQuery({ keywords: (newValue || "") })}
          onClear={() => setQuery({ keywords: "" })}
        />
      </Stack>
      <Stack tokens={{ childrenGap: 10 }}>
        <span className="filter-header ms-fontSize-12">{t("Test result")}</span>
        <DetailsList
          isHeaderVisible={false}
          compact={true}
          items={filtersTestResults(t)}
          columns={invisibleColumns}
          setKey="set"
          checkboxVisibility={CheckboxVisibility.always}
          selectionMode={SelectionMode.single}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selectionTestResult.current}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
        />
      </Stack>
      <Stack tokens={{ childrenGap: 10 }}>
        <span className="filter-header ms-fontSize-12">{t("Finished date")}</span>
        <DatePicker
          value={getDateObj(dateFrom)}
          placeholder={t("Date from")}
          formatDate={(date) => dayjs(date).format("YYYY年MM月DD日")}
          onSelectDate={(value) => setQuery({ dateFrom: value.toDateString() })}
        />
        <DatePicker
          value={getDateObj(dateTo)}
          placeholder={t("Date to")}
          formatDate={(date) => dayjs(date).format("YYYY年MM月DD日")}
          onSelectDate={(value) => setQuery({ dateTo: value.toDateString() })}
        />
      </Stack>
    </Stack>
  );
};
