import { css, jsx } from '@emotion/core';
import { IconButton, TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

const calloutProps = { gapSpace: 0 };
const hostStyles = { root: { display: 'inline-block' } };
const NoteIcon = { iconName: 'QuickNote' };

/** @jsx jsx */
export const NoteToolTip = ({ noteDetail, tooltipId }) => {
    const { t } = useTranslation();
    return (
        <TooltipHost
            content={noteDetail}
            id={tooltipId}
            calloutProps={calloutProps}
            styles={hostStyles}
        >
          <IconButton iconProps={NoteIcon} title={t('Note')} ariaLabel={t('Note')} aria-describedby={tooltipId} />
      </TooltipHost>
    );
}