import { jsx } from "@emotion/core";
import { DefaultButton, Dropdown, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getRoles } from "../../services/roles.service";
import {
  deleteAdministrator,
  getAdministrator,
  saveAdministrator,
} from "../../services/users.service";

/** @jsx jsx */
export const EditPage = () => {
  const { user } = useSelector((s) => s.authentication);
  const history = useHistory();
  const { t } = useTranslation();
  const { administratorId } = useParams();
  const [administrator, setAdministrator] = useState(null);
  const [errors, setErrors] = useState({});
  const [roles, setRoles] = useState([]);
  const isMe = Number(administratorId) === user.id;

  useEffect(() => {
    if (administratorId) {
      getAdministrator(administratorId).then((response) => {
        const user = response.data;
        if (user.role !== null && typeof user.role === "object") {
          user.role = user.role.id;
        }
        setAdministrator(user);
      });

      getRoles().then((response) => {
        setRoles(
          response?.data?.map((role) => ({
            key: role.id,
            text: role.name,
          })),
        );
      }).catch(() => {
        setRoles([]);
      });
    }
  }, [administratorId, setAdministrator, setRoles]);

  const onChange = useCallback((e, newValue) => {
    setAdministrator({ ...administrator, [e.target.name]: newValue });
  }, [setAdministrator, administrator]);

  const onSelectChange = useCallback((e, newValue) => {
    setAdministrator({ ...administrator, [e.target.getAttribute("name")]: newValue.key });
  }, [setAdministrator, administrator]);

  const parseErrors = useCallback((errors) => {
    if (!Array.isArray(errors.message)) {
      return;
    }
    const newErrors = {};
    for (let err of errors.message) {
      if (err.property) {
        // translate the messages returned from the backend and join them together
        newErrors[err.property] = err.errors.map((message) => t(message)).join(", ");
      }
    }
    setErrors(newErrors);
  }, [setErrors, t]);

  const onSaveAdministrator = useCallback(() => {
    saveAdministrator(administratorId, administrator).then((response) => {
      setErrors({});
      history.push(`/administrators/${administratorId}`);
    }).catch((error) => {
      if (error.response && error.response.data) {
        parseErrors(error.response.data);
      }
    });
  }, [administrator, administratorId, setErrors, history, parseErrors]);

  const onDeleteAdministrator = useCallback(() => {
    deleteAdministrator(administratorId).then(() => {
      setErrors({});
      history.push("/administrators");
    });
  }, [administratorId, setErrors, history]);

  return administrator && (
    <div>
      <div className="left-right">
        <h1>{t("Edit Administrator")}</h1>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <DefaultButton
            text={t("Delete")}
            onClick={onDeleteAdministrator}
            disabled={isMe}
          />
          <PrimaryButton
            text={t("Save")}
            onClick={onSaveAdministrator}
          />
        </Stack>
      </div>
      <Stack>
        <TextField
          name="firstName"
          label={t("First name")}
          value={administrator.firstName}
          errorMessage={errors.firstName}
          onChange={onChange}
        />
        <TextField
          name="lastName"
          label={t("Last name")}
          value={administrator.lastName}
          errorMessage={errors.lastName}
          onChange={onChange}
        />
        <TextField
          name="emailAddress"
          label={t("Email address")}
          value={administrator.emailAddress}
          errorMessage={errors.emailAddress}
          onChange={onChange}
        />
        <TextField
          name="password"
          label={t("Password")}
          value={administrator.password}
          errorMessage={errors.password}
          onChange={onChange}
        />
        <TextField
          name="defaultLanguage"
          label={t("Default language")}
          value={administrator.defaultLanguage}
          errorMessage={errors.defaultLanguage}
          onChange={onChange}
        />
        <Dropdown
          name="defaultLanguage"
          label={t("Default language")}
          selectedKey={administrator.defaultLanguage}
          errorMessage={errors.defaultLanguage}
          options={[
            {
              key: "en-us",
              text: t("English (US)"),
            },
            {
              key: "ja",
              text: t("Japanese"),
            },
          ]}
          onChange={onSelectChange}
        />
        <Dropdown
          name="role"
          label={t("Role")}
          selectedKey={administrator.role}
          errorMessage={errors.role}
          options={roles}
          onChange={onSelectChange}
        />
      </Stack>
    </div>
  );
};
