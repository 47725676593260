import { useSelector } from "react-redux";

export const usePermission = (permission) => {
  const { permissions } = useSelector((s) => s.authentication);
  if (permissions.includes("*")) {
    return true;
  }

  if (typeof permission === "string") {
    return permissions.includes(permission);
  } else if (Array.isArray(permission)) {
    return permission.every((perm) => permissions.includes(perm));
  } else {
    return false;
  }
};
