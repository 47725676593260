import { css, jsx } from "@emotion/core";
import { DefaultButton, Stack } from "@fluentui/react";
import dayjs from "dayjs";
import Pagination from "office-ui-fabric-react-pagination";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberParam, StringParam, useQueryParams } from "use-query-params";

import { generateResultsReport, getAllCyclesResults } from "../../services/scanning.service";

import { Permission } from "../../components/permission.component";
import { PlainCycleList } from "../../components/plain-cycle-list.component";
import { usePermission } from "../../hooks/use-permission.hook";
import { useSingleSelection } from "../../hooks/use-selection.hook";
import { format } from "../../utils/code-format";
import { getFullNameKanji } from "../../utils/full-name";
import { Filters } from "./components/results.filters";

const SearchResultMap = {
  "ε4/ε4": "ε4/ε4",
  "ε4/ε3": "ε4/ε3",
  "ε4/ε2": "ε4/ε2",
  "ε3/ε3": "ε3/ε3",
  "ε3/ε2": "ε3/ε2",
  "ε2/ε2": "ε2/ε2",
  "retest": "RETEST",
};

/** @jsx jsx */
export const IndexPage = () => {
  const { t } = useTranslation();
  const [results, setResults] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [tresult, selectionTestResult] = useSingleSelection("all");

  const isAllowedViewRegistration = usePermission("Registration.View");
  const isAllowedViewPatient = usePermission("Patient.View");

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    keywords: StringParam,
    dateFrom: StringParam,
    dateTo: StringParam,
    testResult: StringParam,
  });

  const { page, keywords, dateFrom, dateTo, testResult } = query;

  useEffect(() => {
    getAllCyclesResults(query)
      .then((res) => {
        setResults(res.data.items);
        setTotalPages(Number(res.data.meta?.totalPages || 1));
        setQuery({ page: res.data.meta.currentPage });
      })
      .catch((error) => console.error("get all results results", error));
  }, [page, keywords, dateFrom, dateTo, testResult, query, setQuery]);

  useEffect(() => {
    if (tresult === "all") {
      setQuery({ testResult: "" });
    } else {
      setQuery({ testResult: SearchResultMap[tresult] });
    }
  }, [tresult, setQuery]);

  const toTitleCase = (str) => {
    if (!str) {
      return "N/A";
    }
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    );
  };

  const removeSlashFromResultString = (str) => {
    if (!str) return;
    return str.replace(/\//g, "");
  };

  const ResultAssignButtons = ({ item }) => {
    return (
      item.registration
        ? (
          <div className="result-assign-buttons">
            <div
              className={`result-assign-button result-assign-button--${
                removeSlashFromResultString(item?.registration?.result?.toLowerCase())
              } active`}
            >
              {t(toTitleCase(item?.registration?.result))}
            </div>
          </div>
        )
        : <React.Fragment></React.Fragment>
    );
  };

  const BarcdeWithNameTag = ({ item }) => (
    <div>
      <p className="cycleListItem__barcode">
        {isAllowedViewRegistration
          ? (
            <Link to={`/registrations/${item?.registration?.id}`}>
              {item.barcode === "000000000000" ? "CUSTOM SAMPLE" : format(item.barcode)}
            </Link>
          )
          : (item.barcode === "000000000000" ? "CUSTOM SAMPLE" : format(item.barcode))}
      </p>
      <span className="cycleListItem__name">
        {isAllowedViewPatient
          ? (
            <Link to={`/patients/${item.registration?.patient?.id}`}>
              {item.barcode === "FFFFFFFFFFFF"
                ? "Custom"
                : getFullNameKanji(item.registration?.patient)}
            </Link>
          )
          : (item.barcode === "FFFFFFFFFFFF"
            ? "Custom"
            : getFullNameKanji(item.registration?.patient))}
      </span>
    </div>
  );

  const cycleListColumnsConfig = () => {
    return [
      {
        minWidth: 60,
        maxWidth: 60,
        name: t("Cycle"),
        key: "cyclenumber",
        fieldName: "cyclenumber",
        className: "cycleListItem cycleListItem--cycle",
        onRender: (item) => `# ${item.cycleId}`,
      },
      {
        minWidth: 30,
        maxWidth: 30,
        name: t("Tray"),
        key: "positionnumber",
        fieldName: "positionnumber",
        className: "cycleListItem cycleListItem--number",
        onRender: (item) => `# ${item.trayPosition}`,
      },
      {
        key: "result",
        name: t("result"),
        fieldName: "result",
        className: "cycleListItem",
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item) => <ResultAssignButtons item={item} />,
      },
      {
        minWidth: 120,
        maxWidth: 180,
        key: "barcode",
        name: t("Testing number"),
        fieldName: "barcode",
        className: "cycleListItem",
        isResizable: true,
        onRender: (item) => <BarcdeWithNameTag item={item} />,
      },
      {
        key: "finishedDate",
        name: t("Finished date"),
        fieldName: "finishedDate",
        className: "cycleListItem",
        minWidth: 100,
        maxWidth: 120,
        onRender: (item) => (item.registration?.testingDate
          ? dayjs(item.registration.testingDate).format("YYYY年MM月DD日")
          : "N/A"),
      },
      {
        key: "companyname",
        name: t("Company name"),
        fieldName: "companyname",
        className: "cycleListItem",
        minWidth: 120,
        maxWidth: 160,
        onRender: (item) => (`${item?.registration?.patient?.organization?.name || ""}`),
      },
      {
        key: "isEmailed",
        name: t("Is emailed"),
        fieldName: "isEmailed",
        className: "cycleListItem",
        minWidth: 80,
        onRender: (item) => (item.registration?.isEmailed ? t("Yes") : t("No")),
      },
    ];
  };

  const cycleListColumns = cycleListColumnsConfig();

  const handleExportToCsv = useCallback(() => {
    generateResultsReport(query);
  }, [query]);

  return (
    <div css={styles} className="animationIn">
      <Stack
        className="page-heading"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <h1>{t(`Completed tests`)}</h1>
        <Permission only="Registration.Export">
          <DefaultButton
            iconProps={{ iconName: "Download" }}
            onClick={handleExportToCsv}
            text={t("Export as CSV")}
          />
        </Permission>
      </Stack>
      <div className="ms-depth-4 bg-white section-container">
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack
            tokens={{ childrenGap: 20 }}
            horizontal
            horizontalAlign="space-between"
            verticalAlign="start"
          >
            <PlainCycleList
              cycleList={results.filter((r) => r.registration)}
              cycleListColumns={cycleListColumns}
            />
            <Filters
              testResult={testResult}
              selectionTestResult={selectionTestResult}
              dateFrom={dateFrom}
              dateTo={dateTo}
              setQuery={setQuery}
              keywords={keywords}
            />
          </Stack>
          <Pagination
            currentPage={(page || 1) > totalPages ? totalPages : (page || 1)}
            totalPages={totalPages}
            onChange={(page) => setQuery({ page })}
          />
        </Stack>
      </div>
    </div>
  );
};

const styles = css`

.page-heading {
    max-width: 1280px;
    margin-bottom: 24px;

    h1 {
        margin: 0;
    }
}

.section-container {
    max-width: 1280px;
    box-sizing: border-box;
}
.cycleListItem {
    display: inline-flex;
    align-items: center;
    
    &--number {
        font-size: 14px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
    }
    &--delete{
        .clearButton {
            margin-left: 16px;
        }
    }
    &__barcode {
        opacity: .9;
        margin-bottom: 2px;
        font-weight: bold;
        margin-top: 0;
        a {
            text-decoration: none;
            &:-webkit-any-link {
                color: rgb(96, 94, 92);
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__name {
        opacity: .6;
        font-size: 11px;
        a {
            text-decoration: none;
            &:-webkit-any-link {
                color: rgb(96, 94, 92);
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.result-assign-buttons {
    display: flex;
    max-width: 120px;
}

.result-assign-button {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    min-width: 80px;
    height: 32px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(138, 136, 134);
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
    &--positive.active {
        color: #fff;
        border-color: #FF5209;
        background-color: #FF5209;
    }
    
    &--negative.active {
        color: #fff;
        border-color: #66AC46;
        background-color: #66AC46;
    }
    &--retest.active {
        color: rgb(50, 49, 48);
        border-color: rgb(50, 49, 48);
    }

    &--ε2ε2.active {
        color: rgb(50, 49, 48);
        border-color: #CBEBFE;
        background-color: #CBEBFE;
    }

    &--ε3ε2.active {
        color: rgb(50, 49, 48);
        border-color: #A6CFE7;
        background-color: #A6CFE7;
    }

    &--ε3ε3.active {
        color: rgb(50, 49, 48);
        border-color: #B5EACE;
        background-color: #B5EACE;
    }

    &--ε4ε2.active {
        color: rgb(50, 49, 48);
        border-color: #C8F392;
        background-color: #C8F392;
    }

    &--ε4ε3.active {
        color: rgb(50, 49, 48);
        border-color: #FFE299;
        background-color: #FFE299;
    }

    &--ε4ε4.active {
        color: rgb(50, 49, 48);
        border-color: #FAA28F;
        background-color: #FAA28F;
    }

    &--confrimemial {
        cursor: pointer;
        &:hover {
            background-color: rgb(243, 242, 241);
            color: rgb(32, 31, 30);
        }
    }
}
.result-assign-button:not(:first-of-type) {
    margin-left: -1px;
}
.result-filters {
    display: flex;
}
.result-filter {
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 12px;
    min-width: 80px;
    height: 28px;
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    border-color: rgb(138, 136, 134);
    &:hover {
        background-color: rgb(243, 242, 241);
        color: rgb(32, 31, 30);
    }
    &:not(:last-of-type) {
        margin-right: -1px;
    }
    &--positive.active {
        color: #fff;
        border-color: #FF5209;
        background-color: #FF5209;
    }
    
    &--negative.active {
        color: #fff;
        border-color: #66AC46;
        background-color: #66AC46;
    }
    &--retest.active {
        color: #fff;
        border-color: #FFB526;
        background-color: #FFB526;
    }
}

.pagination {
    margin: 50px auto 0 auto;
}
.pagination .ms-Button {
    min-width: 40px;
    border: none;
}
.pagination .ms-Button i {
    font-size: 12px;
}

.pagination .ms-Button--primary {
    background-color: #66AC46;
    &:hover {
        background-color: #5c9a3f;
    }
}

.results-filter-options {
    cursor: pointer;
}
`;
