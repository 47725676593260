import { css, jsx } from "@emotion/core";
import {
  DatePicker,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";

import { StringParam, useQueryParams } from "use-query-params";

import dayjs from "dayjs";
import { RemoveItemButton } from "../../components/delete-button.component";
import { Permission } from "../../components/permission.component";
import { status_options } from "../../config/status-map";
import { usePermission } from "../../hooks/use-permission.hook";
import {
  bulkImport,
  checkOrganizationDefaultUser,
  deleteOrganization,
  getOrganization,
  getOrganizationReport,
  inviteOrganizationUser,
  updateOrganization,
} from "../../services/organizations.service";
import { invite } from "../../services/users.service";
import { getFullNameKanji } from "../../utils/full-name";

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

/** @jsx jsx */
export const ViewPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const importResultTimerRef = useRef();
  const { organizationId } = useParams();

  const [organization, setOrganization] = useState(null);
  const [form, setForm] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hideImportDialog, setHideImportDialog] = useState(true);
  const [hideInviteUserDialog, setHideInviteUserDialog] = useState(true);
  const [defaultUserEmail, setDefaultUserEmail] = useState("");
  const [isImporting, setIsImporting] = useState(false);
  const [isImportError, setIsImportError] = useState(false);
  const [importResult, setImportResult] = useState("");
  const [errorBarState, setErrorBarState] = useState({ show: false, text: "" });

  const [query, setQuery] = useQueryParams({
    sortBy: StringParam,
    sortByDirection: StringParam,
    importDate: StringParam,
  });
  const { sortBy, sortByDirection, importDate } = query;

  const [importErrorDetails, setImportErrorDetails] = useState([]);
  const [organizationManager, setOrganizationManager] = useState(null);
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [inviteOrganizationUserError, setInviteOrganizationUserError] = useState("");
  const [organizationReportGenerating, setOrganizationReportGenerating] = useState(false);
  const isAllowedViewRegistration = usePermission("Registration.View");
  const isAllowedViewPatient = usePermission("Patient.View");

  const fetchOrganization = (q) => {
    getOrganization(organizationId, q).then((response) => {
      setOrganization(response.data);
      setForm(response.data);
    }).catch((error) => console.error("get organization"));
  };

  const checkOrganizationUser = () => {
    checkOrganizationDefaultUser(organizationId)
      .then((response) => {
        if (response.data) {
          setOrganizationManager(response.data);
          setDefaultUserEmail(response.data?.emailAddress);
        }
      })
      .catch((error) => console.log("organization manager", error));
  };

  const generateOrganizationReport = () => {
    setOrganizationReportGenerating(true);
    getOrganizationReport(organizationId, query).then((response) => {
      setOrganizationReportGenerating(false);
    }).catch((error) => console.error("get organization"));
  };

  useEffect(() => {
    if (organizationId) {
      fetchOrganization();
      checkOrganizationUser();
    }
  }, [organizationId]);

  useEffect(() => {
    fetchOrganization(query);
  }, [query]);

  useEffect(() => {
    if (errorBarState.show) {
      setTimeout(() => {
        setErrorBarState({ show: false, text: "" });
      }, 2000);
    }
  }, [errorBarState.show]);

  const handleOrganizationUpdate = (event, newValue, fieldName) => {
    const newOrganizationInfo = { ...form };
    newOrganizationInfo[fieldName] = newValue;
    setForm(newOrganizationInfo);
    setIsChanged(true);
  };

  const handleOnSave = () => {
    updateOrganization(organizationId, form)
      .then(() => {
        fetchOrganization();
        setIsChanged(false);
      })
      .catch((error) => console.error("update organization"));
  };

  const handleImportClick = useCallback(() => {
    if (selectedFile) {
      setIsImporting(true);
      bulkImport(selectedFile, organizationId)
        .then((response) => {
          setIsImportError(false);
          setImportResult(t("CSV file has been imported"));

          setIsImporting(false);
          setHideImportDialog(true);

          if (importResultTimerRef.current) {
            clearTimeout(importResultTimerRef.current);
          }

          importResultTimerRef.current = setTimeout(() => {
            setImportResult("");
          }, 3600);

          fetchOrganization();
        })
        .catch((error) => {
          setIsImporting(false);
          setImportResult("Server error");
          setIsImportError(true);
          setHideImportDialog(true);

          error.response?.data?.errors
            && setImportErrorDetails((s) => [...s, ...[error.response.data?.errors]]);

          if (importResultTimerRef.current) {
            clearTimeout(importResultTimerRef.current);
          }

          importResultTimerRef.current = setTimeout(() => {
            setImportResult("");
            setIsImportError(false);
          }, 3600);

          fetchOrganization();
        });
    }
  }, [selectedFile]);

  const dialogContentProps = {
    type: DialogType.normal,
    title: t("Import CSV"),
    closeButtonAriaLabel: "Close",
    subText: selectedFile
      ? `${t("Are you sure you want to import")}`
      : t("Choose a csv file to import"),
  };

  const inviteDialogContentProps = {
    type: DialogType.normal,
    title: t("Invite organization user"),
    closeButtonAriaLabel: "Close",
  };

  const dialogStyles = { main: { maxWidth: 450 } };

  const modalProps = () => ({
    isBlocking: false,
    styles: dialogStyles,
  });
  const handleInvite = useCallback((userId) => {
    invite(userId).then((response) => {
      fetchOrganization();
    });
  }, []);

  const handleRemoveOrganization = (organizationId) => {
    deleteOrganization(organizationId)
      .then(() => history.push("/organizations"))
      .catch((error) =>
        setErrorBarState((s) => ({
          ...s,
          show: true,
          text: error.response?.status === 400
            ? t(error.response?.data.error.message)
            : t("Something Went Wrong"),
        }))
      );
  };

  const registrationsColumns = [
    {
      key: "registration.createdAt",
      name: t("Date added"),
      fieldName: "createdAt",
      maxWidth: 140,
      onRender: (item) =>
        item.registration?.createdAt
          ? dayjs(item.registration?.createdAt).format("YYYY年MM月DD日")
          : "N/A",
    },
    {
      key: "registration.barcode",
      name: t("Testing number"),
      fieldName: "barcode",
      maxWidth: 160,
      onRender: (item) =>
        item.barcode
          ? item.registration
            ? (isAllowedViewRegistration
              ? <Link to={`/registrations/${item.registration?.id}`}>{item.barcode}</Link>
              : item.barcode)
            : item.barcode
          : "N/A",
    },
    {
      key: "registration.importDate",
      name: t("Import date"),
      fieldName: "importDate",
      maxWidth: 160,
      onRender: (item) =>
        item.registration?.importDate
          ? dayjs(item.registration?.importDate).format("YYYY年MM月DD日")
          : "N/A",
    },
    {
      key: "patient.lastName",
      name: t("Name"),
      fieldName: "name",
      onRender: (item) =>
        item.registration?.patient
          ? (isAllowedViewPatient
            ? (
              <Link to={`/patients/${item.registration?.patient.id}`}>
                {getFullNameKanji(item.registration.patient)}
              </Link>
            )
            : getFullNameKanji(item.registration.patient))
          : "N/A",
    },
    {
      key: "patient.emailAddress",
      name: t("E-mail address"),
      fieldName: "emailAddress",
      isResizable: true,
      minWidth: 220,
      onRender: (item) => item.registration?.patient?.emailAddress || "N/A",
    },
    {
      key: "registration.status",
      name: t("Status"),
      fieldName: "status",
      onRender: (item) => t(status_options[item.registration?.status]),
    },
    {
      key: "user.verificationEmailSent",
      name: t("Invited?"),
      fieldName: "verificationEmailSent",
      minWidth: 60,
      maxWidth: 60,
      onRender: (item) =>
        item.registration?.patient?.user
          ? t(item.registration?.patient?.user?.verificationEmailSent ? "Yes" : "No")
          : null,
    },
    {
      key: "emailVerified",
      name: "",
      fieldName: "emailVerified",
      onRender: (item) =>
        item.registration?.patient?.user?.emailVerified
          ? null
          : item.registration?.patient?.user
          ? (
            <PrimaryButton
              text={t("Invite")}
              onClick={() => handleInvite(item.registration?.patient?.user.id)}
            />
          )
          : null,
    },
  ].map((item) => {
    item.isSorted = sortBy === item.key;
    item.isSortedDescending = sortByDirection === "DESC";
    return item;
  });

  const onColumnClick = useCallback((column) => {
    if (column.key === sortBy) {
      setQuery({ sortByDirection: (sortByDirection === "ASC" ? "DESC" : "ASC") });
    } else {
      setQuery({
        sortByDirection: "ASC",
        sortBy: column.key,
      });
    }
  }, [sortBy, sortByDirection, importDate]);

  const handleExportToCsv = useCallback(() => {
    generateOrganizationReport();
  }, [sortBy, sortByDirection, importDate]);

  const handleInviteOrganizationUser = useCallback(() => {
    if (organizationId) {
      setIsSendingInvite(true);
      setInviteOrganizationUserError("");
      const organization = {
        organizationId,
        defaultUserEmail,
      };

      inviteOrganizationUser(organization)
        .then((response) => {
          checkOrganizationUser();
          setHideInviteUserDialog(true);
          setInviteOrganizationUserError("");
        })
        .catch((error) => {
          /**
           * status 400: dupricate user
           * status 404: not found organization
           * status 422: sendgrid error
           * status 500: something went wrong
           */
          setInviteOrganizationUserError(t(error.response?.data.error?.message));
        })
        .finally(() => setIsSendingInvite(false));
    }
  }, [defaultUserEmail, organizationId]);

  return (
    <React.Fragment>
      {errorBarState.show
        ? (
          <MessageBar
            messageBarType={MessageBarType.error}
            onDismiss={() => setErrorBarState({ show: false, text: "" })}
          >
            {errorBarState.text}
          </MessageBar>
        )
        : null}
      {organization && (
        <div css={styles} className="animationIn">
          <div className="left-right">
            <h1>
              {organization.name}{" "}
              <span className="subtitle ms-fontSize-14 ms-fontWeight-regular">
                {t("Organization detail")}
              </span>
            </h1>
            <Stack horizontal>
              <Permission only="Organization.Edit">
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                  {organization?.inventories?.length === 0 && (
                    <RemoveItemButton
                      deleteMessage={t("Are you sure you want to remove this organization?")}
                      onClick={() => handleRemoveOrganization(organization.id)}
                    />
                  )}
                  {!organizationManager && (
                    <DefaultButton
                      iconProps={{ iconName: "FollowUser" }}
                      onClick={() => setHideInviteUserDialog(false)}
                      text={t("Invite user")}
                    />
                  )}
                  <DefaultButton
                    iconProps={{ iconName: "CloudImportExport" }}
                    onClick={() => setHideImportDialog(false)}
                    text={t("Import CSV")}
                  />
                  {isChanged
                    && (
                      <PrimaryButton
                        iconProps={{ iconName: "Save" }}
                        text={t("Save")}
                        onClick={handleOnSave}
                      />
                    )}
                </Stack>
              </Permission>
            </Stack>
          </div>
          <div className="information-section" dir="ltr">
            <div className="ms-depth-4 bg-white section-container">
              {importResult && (
                <MessageBar
                  messageBarType={isImportError ? MessageBarType.error : MessageBarType.success}
                >
                  {importResult}
                </MessageBar>
              )}
              {importErrorDetails.length > 0
                && (
                  <MessageBar
                    onDismiss={() => setImportErrorDetails([])}
                    dismissButtonAriaLabel={t("Close")}
                    messageBarType={MessageBarType.error}
                  >
                    {importErrorDetails.map((err, i) => (
                      <p className="t-error" key={`error-${i}`}>{err}</p>
                    ))}
                  </MessageBar>
                )}
              <Stack tokens={{ childrenGap: 36 }} horizontal>
                <TextField
                  className="organization-name"
                  label={t("Organization name")}
                  value={form.name}
                  onChange={(event, newValue) => handleOrganizationUpdate(event, newValue, "name")}
                />
                <Stack tokens={{ childrenGap: 8 }}>
                  <Stack className="organization-manager">
                    <label>{t("Organization manager Email")}</label>
                    <p>
                      {organizationManager
                        ? `${organizationManager.emailAddress} (${
                          organizationManager.emailVerified ? t("Verified") : t("Not Verified")
                        })`
                        : t("No manager Email found yet")}
                    </p>
                  </Stack>
                  {organizationManager && !organizationManager.emailVerified && (
                    <DefaultButton
                      text={t("Re Invite")}
                      onClick={() => setHideInviteUserDialog(false)}
                    />
                  )}
                </Stack>
              </Stack>
            </div>
            <div className="information-section" dir="ltr">
              <div className="ms-depth-4 bg-white section-container">
                <main>
                  <section>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 10 }}
                      styles={{
                        root: {
                          "justify-content": "flex-end",
                        },
                      }}
                    >
                      <DatePicker
                        placeholder={t("Import date")}
                        value={importDate ? new Date(importDate) : ""}
                        onSelectDate={(val) => setQuery({ importDate: val })}
                      />
                      <Permission only="Organization.View">
                        <DefaultButton
                          iconProps={{ iconName: "Download" }}
                          onClick={handleExportToCsv}
                          disabled={organizationReportGenerating}
                          text={t("Export as CSV")}
                        />
                      </Permission>
                    </Stack>
                    <ShimmeredDetailsList
                      setKey="items"
                      items={organization.inventories}
                      columns={registrationsColumns}
                      onColumnHeaderClick={(_, column) => onColumnClick(column)}
                      selectionMode={SelectionMode.none}
                      sortBy={sortBy}
                      sortByDirection={sortByDirection}
                      enableShimmer={organization.inventories === null}
                      ariaLabelForShimmer="Content is being fetched"
                      ariaLabelForGrid="Item details"
                      listProps={shimmeredDetailsListProps}
                    />
                  </section>
                </main>
              </div>
            </div>
            <Dialog
              hidden={hideInviteUserDialog}
              onDismiss={() => {
                setHideInviteUserDialog(true);
                setDefaultUserEmail("");
              }}
              dialogContentProps={inviteDialogContentProps}
              modalProps={modalProps}
            >
              <TextField
                label={t("Email")}
                value={defaultUserEmail}
                errorMessage={inviteOrganizationUserError}
                onChange={(_, value) => setDefaultUserEmail(value)}
              />

              <DialogFooter>
                {isSendingInvite
                  ? <Spinner label={t("Sending email, please wait...")} />
                  : (
                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                      <DefaultButton
                        onClick={() => {
                          setHideInviteUserDialog(true);
                          setDefaultUserEmail("");
                          setInviteOrganizationUserError("");
                        }}
                        text={t("Cancel")}
                      />
                      <PrimaryButton
                        disabled={defaultUserEmail === "" ? true : false}
                        onClick={handleInviteOrganizationUser}
                        text={t("Invite")}
                      />
                    </Stack>
                  )}
              </DialogFooter>
            </Dialog>

            <Dialog
              hidden={hideImportDialog}
              onDismiss={() => {
                setHideImportDialog(true);
                setSelectedFile(null);
              }}
              dialogContentProps={dialogContentProps}
              modalProps={modalProps}
            >
              <input
                type="file"
                accept=".csv"
                css={ImportInput}
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />

              <DialogFooter>
                {isImporting
                  ? <Spinner label={t("Importing, please wait...")} />
                  : (
                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                      <DefaultButton
                        onClick={() => {
                          setHideImportDialog(true);
                          setSelectedFile(null);
                        }}
                        text={t("Cancel")}
                      />
                      <PrimaryButton
                        disabled={selectedFile === null}
                        onClick={handleImportClick}
                        text={t("Confirm")}
                      />
                    </Stack>
                  )}
              </DialogFooter>
            </Dialog>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const styles = css`
    .organization-name {
        width: 50%;
    }
    .organization-manager {
        label {
            padding: 5px 0;
            font-size: 14px;
            font-weight: 600;
            color: rgb(50, 49, 48);
        }
    }
    .left-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .t-error {
        margin: 0px 0px 5px;
    }
    .t-bold {
        font-weight: bold;
    }
    main {
        display: flex;
        section {
            width: 100%;
            padding-right: 20px;
        }
        aside {
            min-width: 220px;
            margin-left: auto;
        }
    }
`;

const ImportInput = css`
    outline: none;
    border: none;
    padding: 5px;
`;
