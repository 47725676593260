import { css, jsx } from "@emotion/core";
import {
  DefaultButton,
  DetailsRow,
  Dropdown,
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BarcodePirnt from "../../components/barcode-print.component";
import { Permission } from "../../components/permission.component";
import { addInventory, geInventories, getProducts } from "../../services/inventory.service";
import { dateTimeFormatJp } from "../../utils/date-format";

/** @jsx jsx */
export const IndexPage = ({ children }) => {
  const { t } = useTranslation();
  const [isWindows, setIsWindows] = useState(false);
  const [productId, setProductId] = useState(1);
  const [inventories, setInventories] = useState([]);
  const [searchBarcode, setSearchBarcode] = useState("");
  const [products, setProducts] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  // highlight new added code
  const [newAddedCode, setNewAddedCode] = useState(null);

  const [numberOfSmallBarcode, setNumberOfSmallBarcode] = useState(2);
  const [numberOfLargeBarcode, setNumberOfLargeBarcode] = useState(2);

  const dropdownStyles = {
    dropdown: { width: 200 },
  };

  const shimmeredDetailsListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
  };

  const onKeywordSearch = (partialBarcode) => setSearchBarcode(partialBarcode);

  const onKeywordChange = (event) => {
    if (event.target.value === "") setSearchBarcode("");
  };

  const loadInventories = () => {
    geInventories()
      .then((response) => setInventories(response.data))
      .catch((error) => console.error("fetching inventories", error));
  };

  // system checking
  useEffect(() => {
    const currentPlatForm = window.navigator.platform.toLowerCase();
    if (currentPlatForm.includes("win")) {
      setIsWindows(true);
    } else {
      setIsWindows(false);
    }
  }, []);

  // load inventories and products
  useEffect(() => {
    loadInventories();
    getProducts()
      .then((response) => {
        const product_list = response?.data;
        setProducts(product_list.map((product) => {
          return { key: product.id, text: product.name };
        }));
      })
      .catch((error) => console.error("feching products", error));
  }, []);

  // highlight new added inventory
  useEffect(() => {
    if (newAddedCode) {
      setInventories(inventories.map((inventory) => {
        if (inventory.barcode === newAddedCode) {
          inventory.isNewAdded = true;
          return inventory;
        } else {
          inventory.isNewAdded = false;
          return inventory;
        }
      }));
    }
  }, [newAddedCode]);

  const handleCodePrint = async (barcode) => {
    if (isWindows) {
      window["DoPrint"](barcode, 1, 1);
    }
  };

  const handleProductChange = (event, selectedProduct) => setProductId(selectedProduct.key);

  const handleCodeGenerate = async () => {
    const response = await addInventory(productId);
    const mycode = await response.data;
    if (isWindows) {
      setIsPrinting(true);
      await window["DoPrint"](mycode, Number(numberOfSmallBarcode), Number(numberOfLargeBarcode));
      setIsPrinting(false);
    }
    geInventories()
      .then((response) => setInventories(response.data))
      .then(() => setNewAddedCode(mycode))
      .catch((error) => console.error("fetching inventories", error));
  };

  return (
    <div css={styles} className="animationIn">
      {/* Code below goes to header 👇 */}
      {isWindows && <BarcodePirnt />}

      <h1>{t("Barcode & QRCode")}</h1>
      <div className="ms-depth-4 bg-white section-container">
        {!isWindows && <p>{t("Printing label only works on Windows system")}</p>}
        <Stack tokens={{ childrenGap: 30 }}>
          <Stack tokens={{ childrenGap: 30 }} horizontal>
            <Dropdown
              defaultSelectedKey={productId}
              options={products}
              styles={dropdownStyles}
              onChange={handleProductChange}
            />
            <Permission only="Inventory.Edit">
              <PrimaryButton
                className="generate-btn"
                style={{ width: 140 }}
                disabled={isPrinting}
                text={isPrinting ? t("printing") : t("generate")}
                onClick={() => handleCodeGenerate(productId)}
              />
            </Permission>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <TextField
              type="number"
              label={t("Number of small barcode")}
              value={numberOfSmallBarcode}
              onChange={(event, value) => setNumberOfSmallBarcode(value)}
            />
            <TextField
              type="number"
              label={t("Number of large barcode")}
              value={numberOfLargeBarcode}
              onChange={(event, value) => setNumberOfLargeBarcode(value)}
            />
          </Stack>
          {inventories.length > 0 && (
            <React.Fragment>
              <Stack tokens={{ childrenGap: 10 }}>
                <h2>{t("Barcode in the inventory")}</h2>
                <SearchBox
                  styles={{ root: { width: 200 } }}
                  placeholder={t("Search")}
                  onSearch={onKeywordSearch}
                  onChange={onKeywordChange}
                />
                <ShimmeredDetailsList
                  setKey="items"
                  items={inventories.filter((inventory) =>
                    inventory.barcode.includes(searchBarcode)
                  )}
                  columns={inventoriesColumns(t, handleCodePrint)}
                  selectionMode={SelectionMode.none}
                  enableShimmer={inventories.length === 0}
                  ariaLabelForShimmer="Inventories are being fetched"
                  ariaLabelForGrid="Inventory details"
                  listProps={shimmeredDetailsListProps}
                  onRenderRow={onRenderRow}
                />
              </Stack>
            </React.Fragment>
          )}
        </Stack>
      </div>
    </div>
  );
};

const styles = css`
.inventory-barcode {
    display: inline-flex;
    align-items: center;
}

.new-added-row {
    animation-name: fadeIn;
    animation-duration: 2.5s;
}

@keyframes fadeIn {
    from {background-color: #deecf9;}
    to {background-color: #fff;}
  }
`;

export const onRenderRow = (props) => {
  const customStyles = {};

  if (props) {
    if (props.item.isNewAdded) {
      return (
        <DetailsRow
          {...props}
          className="new-added-row"
          styles={customStyles}
        />
      );
    } else {
      return (
        <DetailsRow
          {...props}
          styles={customStyles}
        />
      );
    }
  }
  return null;
};

export const inventoriesColumns = (t, handleCodePrint) => {
  const getFormattedCode = (rawCode) =>
    rawCode.substring(0, 4) + " " + rawCode.substring(4, 8) + " " + rawCode.substring(8);

  return [
    {
      key: "barcode",
      name: t("Testing number"),
      fieldName: "barcode",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 200,
      onRender: (item) => getFormattedCode(item.barcode),
    },
    {
      key: "createdAt",
      name: t("Generated timestamp"),
      fieldName: "createdAt",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 150,
      onRender: (item) => dateTimeFormatJp(item.createdAt),
    },
    {
      key: "product",
      name: t("Product"),
      fieldName: "product",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 100,
      onRender: (item) => item.product?.name || "",
    },
    {
      key: "operation",
      name: t("Operation"),
      fieldName: "operation",
      className: "inventory-barcode",
      maxWidth: 200,
      minWidth: 100,
      onRender: (item) => (
        <DefaultButton
          text={t("Print")}
          onClick={() => handleCodePrint(item.barcode)}
        />
      ),
    },
  ];
};
