import React, { useState } from 'react';
import { css, jsx, keyframes } from '@emotion/core';
import { FontIcon } from '@fluentui/react/lib/Icon'
import { mergeStyles } from '@fluentui/react/lib/Styling'
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

const fluentStyles = {
    container: (provided, state) => ({
        ...provided,
    }),
    control: (provided, state) => {
        return ({
            ...provided,
            cursor: 'pointer',
            borderRadius: 2,
            borderColor: 'rgb(96, 94, 92)',
            minHeight: 32,
            boxShadow: state.isFocused ? '0 0 0 1px rgb(102, 172, 70)' : 'none',
            ':hover': {
                borderColor: 'rgb(102, 172, 70)',
                boxShadow: '0 0 0 1px rgb(102, 172, 70)'
            },
        });
    },
    indicatorSeparator: (provided) => ({
        ...provided,
        marginBottom: 0,
        marginTop: 0,
        backgroundColor: 'transparent'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: 14,
        color: 'rgb(50, 49, 48)',

    }),
    menu: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        borderRadius: 0,
        boxShadow: 'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px'
    }),
    menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0
    }),
    option: (provided, state) => {
        return ({
            ...provided,
            color: 'rgb(32, 31, 30)',
            fontSize: 14,
            minHeight: 36,
            backgroundColor: state.isSelected ? 'rgb(237, 235, 233)' : 'rgb(255, 255, 255)',
            ':hover': {
                cursor: 'pointer',
                backgroundColor: 'rgb(243, 242, 241)'
            },
            ':active': {
                backgroundColor: 'rgb(237, 235, 233)'
            }
        });
    },
}

const iconClass = mergeStyles({
    padding: 0
});

const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
          <components.IndicatorsContainer {...props}>
            <FontIcon iconName="ChevronDown" className={iconClass} />
          </components.IndicatorsContainer>
      </components.DropdownIndicator>
    );
};

const Menu = (props) => {
    return (
        <components.Menu {...props} className="crizit-select-menu"></components.Menu>
    );
}


/** @jsx jsx */
export const CrizitSelect = ({ label, options, onLoadOptions, onChange }) => {
    return (
        <div css={selectStyle}>
            <label css={selectLabelStyle}>{label}</label>
            <AsyncSelect
                cacheOptions
                components={{ DropdownIndicator, Menu }}
                loadOptions={onLoadOptions}
                defaultOptions={options}
                onChange={onChange}
                styles={fluentStyles} />
        </div>
    );
}

const selectLabelStyle = css`
    color: rgb(50, 49, 48);
    font-weight: 600;
    font-size: 14px;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const menuOpenAnimation = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -10px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0px, 0, 0);
    }
`;

const selectStyle = css`
    .crizit-select-menu {
        animation: ${menuOpenAnimation} cubic-bezier(.1,.25,.75,.9) 167ms both;
    }
      
`;
