import { css, jsx } from "@emotion/core";
import { DefaultButton, SelectionMode, ShimmeredDetailsList, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { InformationRow } from "../../components/information-row.component";
import { getRole } from "../../services/roles.service";

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

/** @jsx jsx */
export const ViewPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { roleId } = useParams();
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (roleId) {
      getRole(roleId).then((response) => {
        setRole(response.data);
      });
    }
  }, [roleId]);

  return role && (
    <div css={styles}>
      <div className="left-right">
        <h1>{role.name}</h1>
        <DefaultButton
          text={t("Edit role")}
          onClick={() => history.push(`/roles/edit/${roleId}`)}
        />
      </div>
      <Stack>
        <InformationRow label={t("Name")}>{role.name}</InformationRow>
        <InformationRow label={t("Redirect Link")}>
          {role.redirectLinkAfterLogin || "N/A"}
        </InformationRow>
        <InformationRow label={t("Permissions")}>{role.permissions.join(", ")}</InformationRow>
      </Stack>
      <h3>{t("Administrators")}</h3>
      <ShimmeredDetailsList
        setKey="items"
        items={role.administrators}
        columns={administratorsColumns(t)}
        selectionMode={SelectionMode.none}
        enableShimmer={role.administrators == null}
        listProps={shimmeredDetailsListProps}
      />
    </div>
  );
};

const styles = css`
`;

export const administratorsColumns = (t) => [
  {
    key: "name",
    name: t("Name"),
    fieldName: "name",
    isSorted: true,
    onRender: (item) => (
      <Link to={`/administrators/${item.id}`}>{item.firstName} {item.lastName}</Link>
    ),
  },
  {
    key: "emailAddress",
    name: t("Email address"),
    fieldName: "emailAddress",
    minWidth: 250,
  },
];
