import { css, jsx } from "@emotion/core";
import { DefaultButton, SelectionMode, ShimmeredDetailsList, Stack } from "@fluentui/react";
import dayjs from "dayjs";
import Pagination from "office-ui-fabric-react-pagination";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { Filters } from "../../components/filters.component";
import { NoteToolTip } from "../../components/note-tooltip.component";
import { Permission } from "../../components/permission.component";
import { status_options } from "../../config/status-map";
import { usePermission } from "../../hooks/use-permission.hook";
import { useSingleSelection } from "../../hooks/use-selection.hook";
import { downloadRegistrationCsv, getRegistrations } from "../../services/registrations.service";
import { getFullNameKanji } from "../../utils/full-name";
const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

/** @jsx jsx */
export const IndexPage = () => {
  const { t } = useTranslation();
  const [registrations, setRegistrations] = useState([]);
  const isAllowedViewOrganization = usePermission("Organization.View");
  const isAllowedViewPatient = usePermission("Patient.View");

  // pagination
  const [totalPages, setTotalPages] = useState(1);
  const [regStatus, selectionRegistrationStatus] = useSingleSelection("all");

  const [query, setQuery] = useQueryParams({
    keywords: StringParam,
    sortBy: StringParam,
    registrationStatus: StringParam,
    dateFrom: StringParam,
    sortByDirection: StringParam,
    page: NumberParam,
    limit: NumberParam,
  });
  const {
    keywords,
    sortBy,
    registrationStatus,
    dateFrom,
    sortByDirection,
    page,
    limit,
  } = query;

  const onColumnClick = (column) => {
    if (column.key === sortBy) {
      setQuery({ sortByDirection: (sortByDirection === "ASC" ? "DESC" : "ASC") });
    } else {
      setQuery({ sortByDirection: "ASC", sortBy: column.key });
    }
  };

  useEffect(() => {
    const filter = { ...query };
    getRegistrations(filter).then((response) => {
      setRegistrations(response.data.items);
      setTotalPages(Number(response.data.meta?.totalPages || 1));
      setQuery({ page: (Number(response.data.meta?.currentPage || 1)) });
    }).catch((error) => console.error("get filtered registrations", error));
  }, [keywords, sortBy, registrationStatus, dateFrom, sortByDirection, page, limit]);

  useEffect(() => {
    switch (regStatus) {
      case "all":
        setQuery({ registrationStatus: undefined });
        break;
      case "completed":
        setQuery({ registrationStatus: "COMPLETED" });
        break;
      case "pending_follow_up":
        setQuery({ registrationStatus: "PENDING_FOLLOW_UP" });
        break;
      case "in_testing":
        setQuery({ registrationStatus: "IN_TESTING" });
        break;
      case "received_in_lab":
        setQuery({ registrationStatus: "RECEIVED_IN_LAB" });
        break;
      case "patient_completed":
        setQuery({ registrationStatus: "PATIENT_COMPLETED" });
        break;
      case "in_progress":
        setQuery({ registrationStatus: "IN_PROGRESS" });
        break;
      case "in_progress_barcode_registered":
        setQuery({ registrationStatus: "IN_PROGRESS_BARCODE_REGISTERED" });
        break;
      default:
        setQuery({ registrationStatus: undefined });
        break;
    }
  }, [regStatus]);

  const registrationsColumns = [
    {
      key: "orgName",
      name: t("Org name"),
      fieldName: "orgName",
      maxWidth: 140,
      onRender: (item) =>
        item.patient?.organization
          ? (isAllowedViewOrganization
            ? (
              <Link to={`/organizations/${item.patient.organization.id}`}>
                {item.patient.organization.name}
              </Link>
            )
            : item.patient.organization.name)
          : "N/A",
    },
    {
      key: "barcode",
      name: t("Testing number"),
      fieldName: "barcode",
      maxWidth: 160,
      onRender: (item) =>
        item.barcode ? <Link to={`/registrations/${item.id}`}>{item.barcode}</Link> : "N/A",
    },
    {
      key: "name",
      name: t("Name"),
      fieldName: "name",
      onRender: (item) =>
        item.patient
          ? (isAllowedViewPatient
            ? <Link to={`/patients/${item.patient.id}`}>{getFullNameKanji(item.patient)}</Link>
            : getFullNameKanji(item.patient))
          : "N/A",
    },
    {
      key: "status",
      name: t("Status"),
      fieldName: "status",
      onRender: (item) => t(status_options[item.status]),
    },
    {
      key: "selectedTestMethod",
      name: t("SelectedTestMethod"),
      fieldName: "selectedTestMethod",
      onRender: (item) =>
        !item.selectedTestMethod ? "N/A" : item.selectedTestMethod === "HAIR" ? "毛根" : "スワブ",
    },
    {
      key: "testingDate",
      name: t("Finished date"),
      fieldName: "testingDate",
      onRender: (item) =>
        item.testingDate ? dayjs(item.testingDate).format("YYYY年MM月DD日") : "N/A",
    },
    {
      key: "note",
      name: t("Note"),
      fieldName: "note",
      onRender: (item) =>
        item?.patient?.note
          ? <NoteToolTip noteDetail={item?.patient?.note} tooltipId={item.id} />
          : "N/A",
    },
  ].map((item) => {
    item.isSorted = sortBy === item.key;
    item.isSortedDescending = sortByDirection === "DESC";
    return item;
  });

  return (
    <div css={styles} className="animationIn">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h1 style={{ margin: 0 }}>{t("Registrations")}</h1>
        <Permission only="Registration.Export">
          <DefaultButton
            iconProps={{ iconName: "Download" }}
            onClick={() => downloadRegistrationCsv()}
            text={t("Export as CSV")}
          />
        </Permission>
      </div>
      <div className="ms-depth-4 bg-white section-container">
        <main>
          <section>
            <Stack
              tokens={{ childrenGap: 16 }}
              horizontal
              horizontalAlign="space-between"
              verticalAlign="start"
            >
              <ShimmeredDetailsList
                setKey="items"
                items={registrations}
                columns={registrationsColumns}
                onColumnHeaderClick={(_, column) => onColumnClick(column)}
                selectionMode={SelectionMode.none}
                sortBy={sortBy}
                sortByDirection={sortByDirection}
                enableShimmer={registrations === null}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={shimmeredDetailsListProps}
              />
              <Filters
                setQuery={setQuery}
                registrationStatus={registrationStatus}
                selectionRegistrationStatus={selectionRegistrationStatus}
                dateFrom={dateFrom}
                keywords={keywords}
              />
            </Stack>
            <Pagination
              currentPage={(page || 1) > totalPages ? totalPages : (page || 1)}
              totalPages={totalPages}
              onChange={(page) => setQuery({ page })}
            />
          </section>
        </main>
      </div>
    </div>
  );
};

const styles = css`

main {
    display: flex;
    section {
        width: 100%;
    }
}
.pagination {
    margin: 50px auto 0 auto;
}
.pagination .ms-Button {
    min-width: 40px;
    border: none;
}
.pagination .ms-Button i {
    font-size: 12px;
}

.pagination .ms-Button--primary {
    background-color: #66AC46;
    &:hover {
        background-color: #5c9a3f;
    }
}
.results-filter-options {
    cursor: pointer;
}
`;
