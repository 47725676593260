import fileDownload from "js-file-download";
import qs from "query-string";
import axios from "../config/axios";

const url = process.env.REACT_APP_BASE_API;

export const getRegistrations = (options) => {
  return axios.get(`${url}/registrations/admin/?${qs.stringify(options)}`);
};

export const getRegistration = (registrationId, options = {}) => {
  return axios.get(`${url}/registrations/admin/view/${registrationId}?${qs.stringify(options)}`);
};

export const getRegistrationByCode = (barcode, options = {}) => {
  return axios.get(
    `${url}/registrations/admin/view-by-barcode/${barcode}?${qs.stringify(options)}`,
  );
};

export const getOrganizations = (options) => {
  return axios.get(`${url}/organizations/admin/?${qs.stringify(options)}`);
};

export const getUploadedFile = (fileName) => {
  return axios.get(`${url}/registrations/admin/download/${fileName}`);
};

// TODO: add auth route for this service
export const updateRegistrationResult = (id, result, shouldEmail) => {
  return axios.post(`${url}/registrations/admin/v2/set-result/${id}`, { result, shouldEmail });
};

export const markContacted = (id) => {
  return axios.post(`${url}/registrations/admin/mark-contacted/${id}`);
};

export const sendEmail = (id) => {
  return axios.post(`${url}/registrations/admin/send-email/${id}`);
};

export const remove = (id) => {
  return axios.delete(`${url}/registrations/admin/remove/${id}`);
};

export const postSwitchTestMethod = (id) => {
  return axios.post(`${url}/registrations/admin/switchTestMethod/${id}`);
};

export const downloadRegistrationCsv = () => {
  return axios.get(`${url}/registrations/admin/download`, { responseType: "blob" }).then(
    (response) => {
      fileDownload(response.data, "export.csv");
    },
  );
};

export const uploadFile = (fileRef, formFields = {}) => {
  const { files } = fileRef.current;
  const formData = new FormData();
  for (let key in formFields) {
    formData.append(key, formFields[key]);
  }
  formData.append("file", files[0]);

  return axios.post(`${url}/registrations/admin/upload-file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const downloadResultPdf = (registrationId) => {
  return axios.get(`${url}/registrations/admin/print-result/${registrationId}`, {
    responseType: "blob",
  }).then((response) => {
    fileDownload(response.data, "result.pdf");
  });
};
