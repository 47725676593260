import axios from '../config/axios';

const url = process.env.REACT_APP_BASE_API;

export const addInventory = (productId) => {
    return axios.post(`${url}/products/add-inventory/${productId}`);
}

export const geInventories = () => {
    return axios.get(`${url}/products/inventories`);
}

export const geShippedInventories = () => {
    return axios.get(`${url}/products/inventories-shipped`);
}


export const getProducts = () => {
    return axios.get(`${url}/products`);
}