import { css, jsx } from "@emotion/core";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from "@fluentui/react";
import dayjs from "dayjs";
import Pagination from "office-ui-fabric-react-pagination";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { Permission } from "../../components/permission.component";
import { useSingleSelection } from "../../hooks/use-selection.hook";
import { downloadUserCsv, getAllUsers } from "../../services/users.service";
import { Filters } from "./components/users.filters";

/** @jsx jsx */
export const IndexPage = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [errorBarState, setErrorBarState] = useState({ show: false, text: "" });
  const [userStatus, selectionUserStatus] = useSingleSelection("all");

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    sortBy: StringParam,
    status: StringParam,
    keywords: StringParam,
    dateFrom: StringParam,
    sortByDirection: StringParam,
  });

  useEffect(() => {
    if (errorBarState.show) {
      setTimeout(() => {
        setErrorBarState({ show: false, text: "" });
      }, 2000);
    }
  }, [errorBarState.show]);

  const { page, sortBy, keywords, status, sortByDirection, dateFrom } = query;

  const fetchUsers = useCallback((filter, page = 1) => {
    filter.page = page;
    getAllUsers(filter)
      .then((res) => {
        // TODO
        // 全てのルートが新レスポンス構造になった時点で、 res.data.itemsに戻す
        setUsers(res.data?.data.items);
        setTotalPages(Number(res.data?.data.meta?.totalPages || 1));
        setQuery({ page: Number(res.data?.data.meta?.currentPage || 1) });
      })
      .catch((error) => {
        setErrorBarState((s) => ({
          ...s,
          show: true,
          text: error.response?.status === 400
            ? error.response?.data.error.message || ""
            : t("Something Went Wrong"),
        }));
      });
  }, [page, sortBy, keywords, status, sortByDirection, dateFrom]);

  useEffect(() => {
    fetchUsers(query, page);
  }, [page, sortBy, keywords, status, sortByDirection, dateFrom]);

  useEffect(() => {
    setQuery({ status: userStatus });
  }, [userStatus]);

  const usersListConfig = () => {
    return [
      {
        minWidth: 280,
        isResizable: true,
        key: "emailAddress",
        name: t("Email address"),
        fieldName: "emailAddress",
        onRender: (item) => <Link to={`/users/${item.id}`}>{item.emailAddress}</Link>,
      },
      {
        name: t("Created at"),
        key: "createdAt",
        fieldName: "createdAt",
        onRender: (item) => dayjs(item.createdAt).format("YYYY年MM月DD日"),
      },
      {
        name: t("Email verified"),
        key: "emailVerified",
        fieldName: "emailVerified",
        onRender: (item) => item.emailVerified ? t("Yes") : t("No"),
      },
      {
        name: t("Verification email sent"),
        key: "verificationEmailSent",
        fieldName: "verificationEmailSent",
        minWidth: 180,
        onRender: (item) => item.verificationEmailSent ? t("Yes") : t("No"),
      },
    ];
  };

  const onColumnClick = useCallback((column) => {
    if (column.key === sortBy) {
      setQuery({ sortByDirection: sortByDirection === "ASC" ? "DESC" : "ASC" });
    } else {
      setQuery({ sortBy: column.key, sortByDirection: "ASC" });
    }
  }, [page, sortBy, keywords, sortByDirection, dateFrom]);

  const usersListColumns = usersListConfig();

  return (
    <React.Fragment>
      {errorBarState.show
        ? (
          <MessageBar
            messageBarType={MessageBarType.error}
            onDismiss={() => setErrorBarState({ show: false, text: "" })}
          >
            {errorBarState.text}
          </MessageBar>
        )
        : null}
      <div css={styles} className="animationIn">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "12px",
            maxWidth: "1016px",
          }}
        >
          <h1 style={{ margin: 0 }}>{"Users"}</h1>
          <Permission only="User.Export">
            <DefaultButton
              iconProps={{ iconName: "Download" }}
              onClick={() => downloadUserCsv()}
              text={t("Export as CSV")}
            />
          </Permission>
        </div>
        <div className="ms-depth-4 bg-white section-container">
          <Stack tokens={{ childrenGap: 20 }}>
            <Stack
              tokens={{ childrenGap: 20 }}
              horizontal
              horizontalAlign="space-between"
              verticalAlign="start"
            >
              <ShimmeredDetailsList
                setKey="users"
                items={users || []}
                columns={usersListColumns}
                selectionMode={SelectionMode.none}
                enableShimmer={!users}
                onColumnHeaderClick={(_, column) => onColumnClick(column)}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
              />
              <Filters
                query={query}
                setQuery={setQuery}
                selectionUserStatus={selectionUserStatus}
              />
            </Stack>
            <Pagination
              currentPage={(page || 1) > totalPages ? totalPages : (page || 1)}
              totalPages={totalPages}
              onChange={(page) => setQuery({ page })}
            />
          </Stack>
        </div>
      </div>
    </React.Fragment>
  );
};

const styles = css`
.section-container {
    max-width: 960px;
}

.pagination {
    margin: 50px auto 0 auto;
}
.pagination .ms-Button {
    min-width: 40px;
    border: none;
}
.pagination .ms-Button i {
    font-size: 12px;
}

.pagination .ms-Button--primary {
    background-color: #66AC46;
    &:hover {
        background-color: #5c9a3f;
    }
}

.users-filter-options {
    cursor: pointer;
}
`;
