export const getFullName = (patient) => {
  if (!patient) return "N/A";
  if (!patient.lastNameJa || !patient.lastNameJa) return "N/A";
  return `${patient.lastNameJa} ${patient.firstNameJa}`;
};

export const getFullNameKanji = (patient) => {
  if (!patient) return "N/A";
  if (!patient.lastName || !patient.firstName) {
    return `${patient.lastNameJa} ${patient.firstNameJa}`;
  }
  return `${patient.lastName} ${patient.firstName}`;
};
