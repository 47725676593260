import { css, jsx } from "@emotion/core";
import {
  PrimaryButton,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from "@fluentui/react";
import dayjs from "dayjs";
import Pagination from "office-ui-fabric-react-pagination";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { Permission } from "../../components/permission.component";
import { fetchPatientsAsync } from "../../states/patients.slice";
import { getFullNameKanji } from "../../utils/full-name";

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

/** @jsx jsx */
export const IndexPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { patients, totalPages } = useSelector((s) => s.patients);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    sortBy: StringParam,
    keywords: StringParam,
    sortByDirection: StringParam,
  });
  const { page, sortBy, keywords, sortByDirection } = query;

  useEffect(() => {
    const filters = {};
    filters.page = page;
    filters.sortBy = sortBy;
    filters.keywords = keywords;
    filters.sortByDirection = sortByDirection;
    dispatch(fetchPatientsAsync(filters));
  }, [sortBy, sortByDirection, keywords, page, dispatch]);

  const getFilters = useCallback(() => {
    const filters = {};
    if (sortBy) {
      filters.sortBy = sortBy;
      filters.sortByDirection = sortByDirection;
    }
    filters.keywords = keywords;
    filters.page = page;

    return filters;
  }, [sortBy, sortByDirection, keywords, page]);

  const onColumnClick = useCallback((column) => {
    const filters = getFilters();
    if (column.key === sortBy) {
      filters.sortByDirection = sortByDirection === "ASC" ? "DESC" : "ASC";
      setQuery({ sortByDirection: filters.sortByDirection });
    } else {
      filters.sortBy = column.key;
      filters.sortByDirection = "ASC";
      setQuery({ sortBy: filters.sortBy, sortByDirection: filters.sortByDirection });
    }
  }, [getFilters, sortBy, sortByDirection, setQuery]);

  const patientsColumns = [
    {
      key: "id",
      name: t("Customer number"),
      fieldName: "id",
      minWidth: 80,
    },
    {
      key: "firstName",
      name: t("Full name"),
      fieldName: "fullName",
      minWidth: 100,
      onRender: (item) => <Link to={`/patients/${item.id}`}>{getFullNameKanji(item)}</Link>,
    },
    {
      key: "companyName",
      name: t("Company name"),
      minWidth: 150,
      fieldName: "companyName",
    },
    {
      key: "emailAddress",
      name: t("E-mail address"),
      fieldName: "emailAddress",
      minWidth: 250,
    },
    {
      key: "phoneNumber",
      name: t("Phone number"),
      fieldName: "phoneNumber",
      minWidth: 100,
    },
    {
      key: "createdAt",
      name: t("Created date"),
      fieldName: "createdAt",
      minWidth: 100,
      onRender: (item) => dayjs(item.createdAt).format("YYYY年MM月DD日"),
    },
  ].map((item) => {
    item.isSorted = sortBy === item.key;
    item.isSortedDescending = sortByDirection === "DESC";
    return item;
  });

  return (
    <div css={styles} className="animationIn">
      <h1>{t("Patients")}</h1>
      <div className="ms-depth-4 bg-white section-container">
        <Stack horizontal horizontalAlign="space-between">
          <SearchBox
            placeholder={t("Search")}
            onSearch={(value) => setQuery({ page: 1, keywords: value })}
            onClear={() => setQuery({ page: 1, keywords: "" })}
          />
          <Permission only="Patient.Edit">
            <PrimaryButton
              text={t("Add new patient")}
              onClick={() => {
                history.push(`/patients/add/0`);
              }}
            />
          </Permission>
        </Stack>
        <ShimmeredDetailsList
          setKey="items"
          items={patients}
          columns={patientsColumns}
          selectionMode={SelectionMode.none}
          enableShimmer={patients === null}
          sortBy={sortBy}
          sortByDirection={sortByDirection}
          onColumnHeaderClick={(_, column) => onColumnClick(column)}
          listProps={shimmeredDetailsListProps}
        />
        <Pagination
          currentPage={(page || 1) > totalPages ? totalPages : (page || 1)}
          totalPages={totalPages}
          onChange={(page) => setQuery({ page })}
        />
      </div>
    </div>
  );
};

const styles = css`
max-width: 960px;
.pagination {
    margin: 50px auto 0 auto;
}
.pagination .ms-Button {
    min-width: 40px;
    border: none;
}
.pagination .ms-Button i {
    font-size: 12px;
}
.pagination .ms-Button--primary {
    background-color: #66AC46;
    &:hover {
        background-color: #5c9a3f;
    }
}
`;
