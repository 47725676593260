import { css, jsx } from "@emotion/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DefaultAvatar from "../../assets/avatar.png";
import { SideNav } from "../../components/sidenav.component";
import { useScanner } from "../../hooks/use-scanner.hook";

import { MessageBar, MessageBarType, Persona, PersonaPresence, PersonaSize } from "@fluentui/react";

import { getRegistrationByCode } from "../../services/registrations.service";
import { logoutUser } from "../../states/authentication.slice";
import store from "../../store";
import { getEnvironmentFromHostName } from "../../utils/getEnvironmentFromHostName";

/** @jsx jsx */
export const MainLayout = ({ disableScanner, children }) => {
  const environmentFromHostName = getEnvironmentFromHostName();

  const history = useHistory();

  const [barcode] = useScanner();

  const { loggedIn, user } = useSelector((s) => s.authentication);
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = useTranslation();

  const checkCode = useCallback((code) => {
    // double secure
    if (disableScanner) return;
    getRegistrationByCode(code).then((response) => {
      const registration = response.data;
      setErrorMessage(null);
      history.push(`/registrations/${registration.id}`);
    }).catch((error) => {
      setErrorMessage(t("Barcode {{code}} did not return any results", { code }));
    });
  }, [setErrorMessage, history, t, disableScanner]);

  useEffect(() => {
    // redirect if not logged in
    if (!loggedIn) history.push("/");
  }, [loggedIn, history]);

  useEffect(() => {
    if (barcode && !disableScanner) {
      checkCode(barcode);
    }
  }, [barcode]);

  return user && (
    <div css={styles}>
      <div className="content-container">
        <div className="navigation">
          <div className="persona-container">
            <Persona
              imageUrl={DefaultAvatar}
              text={`${user.firstName} ${user.lastName}`}
              imageInitials={`${user.firstName[0]}${user.lastName[0]}`}
              secondaryText="Administrator"
              size={PersonaSize.size40}
              presence={PersonaPresence.online}
              imageAlt="Annie Lindqvist, no presence detected"
              hidePersonaDetails={false}
              onClick={() => store.dispatch(logoutUser())}
            />
          </div>
          <SideNav />
          {environmentFromHostName === "PRODUCTION"
            ? null
            : (
              <div
                style={{
                  backgroundColor: environmentFromHostName === "DEVELOPMENT" ? "pink" : "skyblue",
                }}
              >
                <p style={{ margin: 12 }}>{environmentFromHostName}</p>
              </div>
            )}
        </div>
        <div className="content">
          {errorMessage && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              onDismiss={() => setErrorMessage(null)}
            >
              {errorMessage}
            </MessageBar>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

const styles = css`
    .content-container {
        display: flex;

        .navigation {
            height: 100vh;
            display: flex;
            min-width: 275px;
            flex-direction: column;

            .persona-container {
                padding: 20px;
            }
            
            .ms-FocusZone {
                height: 100%;
            }
        }

        .content {
            width: 100%;
            height: 100vh;
            overflow: auto;
            padding: 20px 50px;
            box-sizing: border-box;
            background-color: #faf9f8;
            padding-left: 40px;
            padding-right: 32px;
        }
    }
`;
