import { jsx } from "@emotion/core";
import { DefaultButton, PrimaryButton, Stack, TextField, Toggle } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { deleteRole, getAllPermissions, getRole, saveRole } from "../../services/roles.service";

/** @jsx jsx */
export const EditPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { roleId } = useParams();
  const [role, setRole] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (roleId) {
      const rolePromise = getRole(roleId);
      const permsPromise = getAllPermissions();
      Promise.all([rolePromise, permsPromise]).then(([role, permissions]) => {
        setRole(role.data);
        setPermissions(permissions.data.reduce((obj, name) => {
          obj[name] = role.data.permissions.find((val) => val === name);
          return obj;
        }, {}));
      });
    }
  }, [roleId, setRole, setPermissions]);

  const onChange = useCallback((e, newValue) => {
    setRole({ ...role, [e.target.name]: newValue });
  }, [setRole, role]);

  const onToggleChange = useCallback((index) => (e, newValue) => {
    const newPerms = { ...permissions, [index]: newValue };
    setPermissions(newPerms);
    setRole({
      ...role,
      permissions: Object.keys(newPerms).reduce((arr, name) => {
        if (newPerms[name]) {
          arr.push(name);
        }
        return arr;
      }, []),
    });
  }, [setPermissions, permissions, setRole, role]);

  const parseErrors = useCallback((errors) => {
    if (!Array.isArray(errors.message)) {
      return;
    }
    const newErrors = {};
    for (let err of errors.message) {
      if (err.property) {
        // translate the messages returned from the backend and join them together
        newErrors[err.property] = err.errors.map((message) => t(message)).join(", ");
      }
    }
    setErrors(newErrors);
  }, [setErrors, t]);

  const onSaveRole = useCallback(() => {
    saveRole(roleId, role).then((response) => {
      setErrors({});
      history.push("/roles");
    }).catch((error) => {
      if (error.response && error.response.data) {
        parseErrors(error.response.data);
      }
    });
  }, [role, roleId, setErrors, history, parseErrors]);

  const onDeleteRole = useCallback(() => {
    deleteRole(roleId).then(() => {
      setErrors({});
      history.push("/roles");
    });
  }, [roleId, setErrors, history]);

  return role && (
    <div>
      <div className="left-right">
        <h1>{t("Edit Role")}</h1>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <DefaultButton
            text={t("Delete")}
            onClick={onDeleteRole}
          />
          <PrimaryButton
            text={t("Save")}
            onClick={onSaveRole}
          />
        </Stack>
      </div>
      <Stack>
        <TextField
          name="name"
          label={t("Name")}
          value={role.name}
          errorMessage={errors.name}
          onChange={onChange}
        />
        {permissions
          && Object.keys(permissions).map((index, i) => (
            <Toggle
              key={i}
              label={index}
              checked={permissions[index]}
              onText={t("Allowed")}
              offText={t("Forbidden")}
              onChange={onToggleChange(index)}
            />
          ))}
      </Stack>
    </div>
  );
};
