export const lightTheme = {
    palette: {
      themePrimary: '#66AC46',
      themeDarkAlt: '#5c9a3f',
      themeDark: '#4d8235',
      themeDarker: '#396027',
      themeSecondary: '#75b557',
      themeTertiary: '#9bcd84',
      themeLight: '#cae6bd',
      themeLighter: '#e2f2db',
      themeLighterAlt: '#f8fcf6'
    }
  };