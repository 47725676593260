import { css, jsx } from "@emotion/core";
import { FontIcon, mergeStyles } from "@fluentui/react";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// TODO: Confirm what to do with trayPositions array
// const trayPositions = [
//     1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
//     11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
//     21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
//     31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
//     41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
//     51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
//     61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
//     71, 72, 73, 74, 75, 76, 77, 78, 79, 80,
//     81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
//     91, 92, 93, 94
// ];

const columnStyle = (c) => ({
  minWidth: c.minWidth,
  maxWidth: c.maxWidth,
});

const iconClass = mergeStyles({
  color: "rgb(92, 154, 63)",
});

/** @jsx jsx */
export const CycleList = ({ cycleList, updateList, cycleListColumns, onColumnHeaderClick }) => {
  const handleDragEnd = (result) => {
    if (result.destination) {
      updateList(result.source.index, result.destination.index);
    }
    if (result.combine) {
      updateList(result.source.index, Number(result.combine.draggableId.replace("draggable-", "")));
    }
  };

  const getStyle = (style, snapshot) => {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      transitionDuration: `0.001s`,
    };
  };

  const findHighestNumber = () =>
    cycleList?.reduce((v, c) => c.trayPosition > v ? c.trayPosition : v, 0);
  const trayPositions = [];
  const heighestTrayPosition = findHighestNumber();
  for (let i = 1; i <= heighestTrayPosition; i++) {
    trayPositions.push(i);
  }

  return (
    <div css={styles}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table>
          <thead>
            <tr>
              <th></th>
              {cycleListColumns.map((c) => (
                <th style={columnStyle(c)} key={`${c.fieldName}`}>{c.name}</th>
              ))}
            </tr>
          </thead>
          <Droppable isCombineEnabled={true} droppableId="table">
            {(provided, snapshot) => (
              <React.Fragment>
                <tbody ref={provided.innerRef}>
                  {trayPositions.map((trayPosition) => {
                    const item = cycleList?.find((i) => i.trayPosition === trayPosition)
                      || { trayPosition };
                    return (
                      <Draggable
                        draggableId={`draggable-${trayPosition}`}
                        index={trayPosition}
                        key={`draggable-${trayPosition}`}
                        isDragDisabled={!item.id}
                      >
                        {(p, snapshot) => (
                          <tr
                            className={`drag${snapshot.isDragging ? " isDragging" : ""} ${
                              Boolean(snapshot.combineTargetFor) ? " isCombineTarget" : ""
                            }`}
                            ref={p.innerRef}
                            {...p.draggableProps}
                            {...p.dragHandleProps}
                            style={getStyle(p.draggableProps.style, snapshot)}
                          >
                            <td>
                              <FontIcon iconName="DragObject" className={iconClass} />
                            </td>
                            {cycleListColumns.map((c) => (
                              <td style={columnStyle(c)} key={`col-${c.fieldName}`}>
                                {c.onRender(item)}
                              </td>
                            ))}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              </React.Fragment>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
};

const styles = css`
    background: white;
    padding: 20px;
    overflow-x: scroll;

    table {
        border-collapse: collapse;
        td, th {
            padding: 5px;
            text-align: left;
            border-bottom: #F0EFEE 1px solid;
        }
        tr {
            height: 58px;
        }
    }
    tbody {
        position: relative;
    }

    .drag {
        top:auto !important;
        left: auto !important;
    }

    .drag.isDragging {
        display: table;
        background-color: #fff;
    }

    .drag.isCombineTarget {
        color: #fff;
        background-color: rgba(92, 154, 63, .75);
    }

    @media only print {
        padding: 5px 10px;
        .header-splitted {
            margin: 0;
        }
        h1 {
            font-size: 14px;
        }
        table {
            th, th > * , td, td > * {
                font-size: 12px !important;
                padding: 0 !important;
                margin: 0 !important;
            }
            tr, tr > * {
                height: auto !important;
                padding: 0 !important;
                max-height: 16px !important;
                margin: 0 !important;
            }

            tr td:nth-of-type(1),
            tr td:nth-of-type(7),
            tr td:nth-of-type(8),
            tr th:nth-of-type(1),
            tr th:nth-of-type(7),
            tr th:nth-of-type(8) {
                display: none;
            }
        }
    }
`;
