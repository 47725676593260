import { createSlice } from '@reduxjs/toolkit';
import { getPatients } from '../services/patients.service';

const initialState = {
    patients: null,
    totalPages: 1,
    isLoading: false,
}

export const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        setPatients: (state, action) => {
            state.patients = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setKeywords: (state, action) => {
            state.keywords = action.payload;
        },
        setSortColumn: (state, action) => {
            state.sortColumn = action.payload;
        },
        setSortColumnDirection: (state, action) => {
            state.sortColumnDirection = action.payload;
        },
    },
});

export const {
    setPatients,
    setCurrentPage,
    setTotalPages,
    setIsLoading,
    setKeywords,
    setSortColumn,
    setSortColumnDirection,
} = patientsSlice.actions;

export const fetchPatientsAsync = filters => dispatch => {
    dispatch(setIsLoading(true))
    getPatients(filters)
        .then(response => {
            dispatch(setPatients(response.data.items));
            dispatch(setTotalPages(Number(response.data.meta.totalPages)))
        })
        .catch(error => console.error('fetch patients', error))
        .finally(() => dispatch(setIsLoading(false)));
}

export default patientsSlice.reducer