import { css, jsx } from "@emotion/core";
import { PrimaryButton, Stack, TextField, Toggle, Dropdown } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createRole, getAllPermissions } from "../../services/roles.service";

/** @jsx jsx */
export const CreatePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [role, setRole] = useState({
    redirectLinkAfterLogin: "/registrations",
  });
  const [permissions, setPermissions] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getAllPermissions().then((response) => {
      setPermissions(response.data.reduce((obj, name) => {
        obj[name] = false;
        return obj;
      }, {}));
    });
  }, [setPermissions]);

  const onChange = useCallback((e, newValue) => {
    setErrors({});
    setRole({ ...role, [e.target.name]: newValue });
  }, [setRole, role]);

  const onToggleChange = useCallback((index) => (e, newValue) => {
    const newPerms = { ...permissions, [index]: newValue };
    setPermissions(newPerms);
    setRole({
      ...role,
      permissions: Object.keys(newPerms).reduce((arr, name) => {
        if (newPerms[name]) {
          arr.push(name);
        }
        return arr;
      }, []),
    });
  }, [setPermissions, permissions, setRole, role]);

  const parseErrors = useCallback((errors) => {
    if (!Array.isArray(errors.message)) {
      return;
    }
    const newErrors = {};
    for (let err of errors.message) {
      if (err.property) {
        // translate the messages returned from the backend and join them together
        newErrors[err.property] = err.errors.map((message) => t(message)).join(", ");
      }
    }
    setErrors(newErrors);
  }, [setErrors, t]);

  const onSaveRole = useCallback(() => {
    createRole(role).then((response) => {
      setErrors({});
      history.push("/roles");
    }).catch((error) => {
      if (error.response && error.response.data) {
        parseErrors(error.response.data);
      }
    });
  }, [role, setErrors, history, parseErrors]);

  return role && (
    <div css={styles}>
      <div className="left-right">
        <h1>{t("Add Role")}</h1>
        <PrimaryButton
          text={t("Save")}
          onClick={onSaveRole}
        />
      </div>
      <Stack tokens={{ childrenGap: 16 }}>
        <Stack className="inputFields" tokens={{ childrenGap: 12 }} horizontal verticalAlign="end">
          <TextField
            name="name"
            label={t("Name")}
            value={role.name}
            errorMessage={errors.name}
            onChange={onChange}
          />
          <Dropdown
            name="redirectLinkAfterLogin"
            label={t("Redirect Link")}
            defaultSelectedKey={role.redirectLinkAfterLogin}
            options={[
              {
                key: "/registrations",
                text: "/registrations",
              },
              {
                key: "/scan/cycles",
                text: "/scan/cycles",
              },
              {
                key: "/users",
                text: "/users",
              },
              {
                key: "/patients",
                text: "/patients",
              },
            ]}
            onChange={(e, options) => setRole({ ...role, "redirectLinkAfterLogin": options.key })}
          />
        </Stack>

        <div css={togglesStyle}>
          {permissions
            && Object.keys(permissions).map((index, i) => (
              <Toggle
                key={i}
                label={index}
                checked={permissions[index]}
                onText={t("Allowed")}
                offText={t("Forbidden")}
                onChange={onToggleChange(index)}
              />
            ))}
        </div>
      </Stack>
    </div>
  );
};

const styles = css`
    .inputFields {
        max-width: 640px;
    }
`;

const togglesStyle = css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`;
