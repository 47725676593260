import { css, jsx } from '@emotion/core';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';
import { Stack, Dialog, DialogFooter, ShimmeredDetailsList, DialogType, SelectionMode, TextField, DefaultButton, PrimaryButton, MessageBar, MessageBarType } from '@fluentui/react';
import { useScanner } from '../../../hooks/use-scanner.hook';
import { format } from '../../../utils/code-format';
import { checkDuplicate } from '../../../utils/duplicate-check';
import { RemoveItemButton } from '../../../components/delete-button.component';
import { EmptyNotification } from '../../../components/empty-notification.component';
import { 
    updateItem,
    addScannedItem,
    getScannedItems,
    addOrganization,
    removeItemByBarcode,
    processScannedItems,
    assignOrganizationName,
    getOrganizations } from '../../../services/scanning.service';
import { useZusStore } from '../../../zusStore';
import { CrizitDropdown } from '../../../components/crizit-dropdown.component';
import { Badge } from '../../../components/badge.component';
import scanErrorSfx from '../../../assets/sounds/Check.mp3';
import newAddSfx from '../../../assets/sounds/NewAdd.mp3';
import { OrganizationAssign } from '../../../components/organization-assign.component';
import { Permission } from '../../../components/permission.component';

/** @jsx jsx */
export const IndexPage = () => {
    const { t } = useTranslation();
    const [ scannedCode ] = useScanner();
    const duplicateTimerReference = useRef();
    const [isError, setIsError] = useState(false);
    const [scannedItems, setScannedItems] = useState([]);
    const [isDuplicated, setIsDuplicated] = useState(false);
    const [isHiddenModal, setIsHiddenModal] = useState(true);
    const errorTimerReference = useRef();
    const [ selectedOrganization, setSelectedOrganization ] = useState(null);
    const setIsHiddenDeleteModal = useZusStore((state) => state.setIsHiddenDeleteModal);
    
    const [ scanErrorSound ] = useSound(scanErrorSfx);
    const [ scanNewAddSound ] = useSound(newAddSfx);

    const handleOrganizationNameAssign = async () => {
        try {
            const response = await assignOrganizationName('SHIPPING', selectedOrganization.value);
            
            const updatedScannedItems = scannedItems.map(item => {
                const updatedItem = {...item};
                updatedItem.organization = { id: selectedOrganization.value, name: selectedOrganization.label };
                return updatedItem;
            });
            setScannedItems(updatedScannedItems);
        } catch (error) {
            console.error('assign organization name');
        }
    }

    // load scanned shiping 
    useEffect(() => {
        async function loadScanning () {
            try {
                const response = await getScannedItems('SHIPPING');
                setScannedItems(response.data);
            } catch (error) {
             console.error(error);   
            }
        }

        loadScanning();
    }, [])

    useEffect(() => {
        if (scannedCode !== '') {
            if (checkDuplicate(scannedItems, scannedCode)) {
                setIsDuplicated(true);
                scanErrorSound();
                if(duplicateTimerReference.current) {
                    clearTimeout(duplicateTimerReference.current);
                }
                duplicateTimerReference.current = setTimeout(() => {
                    setIsDuplicated(false)
                }, 5000);
                return;
            } else {
                addBarcodeToServer();
            }
        }
    }, [scannedCode]);



    const addBarcodeToServer = () => {
        const newScannedItem = {
            barcode: scannedCode,
            organizationName: '',
            isPriority: false,
            needsProof: false,
        }
        
        addScannedItem(scannedCode, 'SHIPPING').then((response) => {
            setScannedItems([...scannedItems, newScannedItem]);
            scanNewAddSound();
            setTimeout(() => {
                const element = document.querySelector('.content');
                element.scrollTop = element.scrollHeight;
            }, 50);
        }).catch(error => {
            scanErrorSound();
            if(errorTimerReference.current) {
                clearTimeout(errorTimerReference.current);
            }
            setIsError(true);
            errorTimerReference.current = setTimeout(() => {
                setIsError(false);
            }, 5000);

            console.error('add barcode at shipping scan', error);
        });
    }

    const handleRemoveItem = async ({ barcode }) => {
        if (barcode) {
            try {
                await removeItemByBarcode(barcode);
                setScannedItems(prevItemsList => prevItemsList.filter(item => item.barcode !== barcode));
                setIsHiddenDeleteModal(true);
            } catch (error) {
                console.error(error)
                setIsHiddenDeleteModal(true);
            }
        }
    }

    const handleConfirmClick = useCallback(() => {
        processScannedItems('SHIPPING')
        .then(response => {
            setIsHiddenModal(true)
            setScannedItems([])
        })
    }, [ setIsHiddenModal, setScannedItems ]);


    const handlePriorityClick = useCallback(({ barcode }) => {
        setScannedItems(prevItemsList => prevItemsList.map(item => { 
            if(item.barcode === barcode) { 
                item.isPriority = !item.isPriority;
                updateItem(item);
            }
            return item;
        }));
    }, [])

    const handleProofClick = useCallback(({ barcode }) => {
        setScannedItems(prevItemsList => prevItemsList.map(item => { 
            if(item.barcode === barcode) { 
                item.needsProof = !item.needsProof;
                updateItem(item);
            }
            return item;
        }));
    }, [])

    const columns = scannedCodesColumns({ 
        t, 
        handleRemoveItem,
        handlePriorityClick,
        handleProofClick
    });

    return (
        <div css={styles} className="animationIn">
            <h1>{t('Scan on shipment')}</h1>
            <div className="ms-depth-4 bg-white section-container">
                <Stack tokens={{ childrenGap: 40 }}>
                    {scannedItems.length === 0 && (
                        <EmptyNotification>{t('Current shipment list is empty. Scan a barcode to begin.')}</EmptyNotification>
                    )}
                    {scannedItems.length > 0 && (
                        <React.Fragment>
                            <h3>Current scanned items: {scannedItems.length}</h3>
                            <ShimmeredDetailsList
                                setKey="items"
                                items={scannedItems}
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                enableShimmer={scannedItems.length === 0}
                                ariaLabelForShimmer="Content is being fetched"
                                ariaLabelForGrid="Item details"
                            />
                            <Permission only="ScanOnShipment.Edit">
                                <Stack tokens={{ childrenGap: 40 }} horizontal verticalAlign="end">
                                    <OrganizationAssign
                                        label="Assign company name"
                                        selectedOrganization={selectedOrganization}
                                        setSelectedOrganization={setSelectedOrganization}
                                    />
                                    <DefaultButton text={t('Assign company name submit')} onClick={handleOrganizationNameAssign} />
                                </Stack>
                                <Stack tokens={{ childrenGap: 40 }} horizontal verticalAlign="end">
                                    <PrimaryButton text={t('Confirm shiping status')} onClick={() => setIsHiddenModal(!isHiddenModal)} />
                                </Stack>
                            </Permission>
                        </React.Fragment>
                    )}
                    {isError && <MessageBar
                        messageBarType={MessageBarType.error}
                    >{t(`Barcode ${scannedCode} not found in the inventory.`)}</MessageBar>}
                    {isDuplicated && 
                    <MessageBar
                        messageBarType={MessageBarType.error}
                    >{t(`Barcode ${scannedCode} already scanned.`)}</MessageBar>}
                </Stack>
            </div>
            <Dialog
                hidden={isHiddenModal}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("Confirm shiping status"),
                    subText:
                        t("Are you sure you want to confirm to ship these items?")
                }}
                modalProps={{
                    isBlocking: false
                }}
            >
                <DialogFooter>
                    <DefaultButton text={t("No")} onClick={() => { setIsHiddenModal(true) }} />
                    <PrimaryButton text={t("Yes")} onClick={() => { handleConfirmClick() }} />
                </DialogFooter>
            </Dialog>            
        </div>
    );
}



export const scannedCodesColumns = ({ 
    t, 
    handleRemoveItem,
    handlePriorityClick,
    handleProofClick,
}) => {
    return [
        {
            key: 'barcode',
            name: t('Barcode'),
            fieldName: 'barcode',
            className: 'barcode',
            onRender: item => format(item.barcode),
        },
        {
            key: 'additions',
            name: t('Additions'),
            fieldName: 'additions',
            className: 'additions',
            minWidth: 200,
            onRender: item => (<div className="badges">
                <Badge type={ item.isPriority ? 'danger' : 'default' } onClick={() => handlePriorityClick(item)}>{ t('Rapid') }</Badge>&nbsp;
                <Badge type={ item.needsProof ? 'primary' : 'default' } onClick={() => handleProofClick(item)}>{ t('PONR') }</Badge>
            </div>),
        },
        {
            key: 'organizationName',
            name: t('Company name'),
            fieldName: 'organizationName',
            className: 'organizationName',
            onRender: item => format(item.organization?.name || 'N/A'),
        },
        {
            key: 'operation',
            name: t('Operation'),
            fieldName: 'operation',
            className: 'barcode-info',
            onRender: item => <RemoveItemButton deleteMessage={`${t('Remove barcode')} ${item.barcode}`} id={item.id} onClick={() => handleRemoveItem(item)} />
        },
    ]
}



const styles = css`
    .barcode {
        min-width: 150px;
    }

    .badge {
        cursor: pointer;
    }
    .organizationName {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
`;