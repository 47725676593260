import React from 'react';
import { Dropdown } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { status_options } from '../config/status-map';
    
const dropdownStyles = {
    dropdown: { width: 100 },
};

const options = t => [
    { key: 'RECEIVED_IN_LAB', text: t(status_options['RECEIVED_IN_LAB']) },
    { key: 'IN_PROGRESS', text: t(status_options['IN_PROGRESS']) },
    { key: 'COMPLETED', text: t(status_options['COMPLETED']) },
];

export const StatusDropdown = ({ item, ...rest }) => {
    const { t }  = useTranslation();

    return <Dropdown
            defaultSelectedKey={item.newStatus}
            options={options(t)}
            styles={dropdownStyles}
            {...rest}
        />
}