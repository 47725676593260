export const status_options = {
    'SHIPPING': 'Shipping',
    'IN_PROGRESS': 'In Progress',
    'IN_PROGRESS_BARCODE_REGISTERED': 'In Progress Barcode Registered',
    'PATIENT_COMPLETED': 'Patient Completed',
    'RECEIVED_IN_LAB': 'Received In Lab',
    "IN_TESTING" :"In Testing",
    'COMPLETED': 'Completed',
    'EVALUATING_RESULT': 'Evaluating results',
    'PENDING_FOLLOW_UP': 'Pending follow up'
}