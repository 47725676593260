import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@fluentui/react';

const ClassificationOptions = [
    { key: 'A', text: 'A' },
    { key: 'B', text: 'B' },
    { key: 'C', text: 'C' },
    { key: 'D', text: 'D' },
    { key: 'E', text: 'E' },
    { key: 'F', text: 'F' }
]

/** @jsx jsx */
export const PatientClassificationDropdown = ({ selectedClassification, onChange }) => {
    const { t } = useTranslation();
    return (
        <Dropdown
        label={t('Classification')}
        onChange={onChange}
        options={ClassificationOptions}
        selectedKey={selectedClassification ? selectedClassification : undefined} />
    );
}