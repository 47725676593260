import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './states/authentication.slice';
import patientsReducer from './states/patients.slice';

export default configureStore({
    reducer: {
        authentication: authenticationReducer,
        patients: patientsReducer
    },
});
