import { useEffect, useState } from 'react';

export const useScanner = () => {

    const [ barcode, setBarcode ] = useState('');
    //detect 12 char input
    const validChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    const timeout = 1500;
    let validCode = false;
    let input = "";
    let startTime = +new Date();
    const keyEvent = function (e) {
        //prevent enter key after valid code enter (e.g. accidental button press)
        if (validCode && e.keyCode === 13 && +new Date() - startTime <= timeout) {
            e.preventDefault();
            return false;
        }
        const char = String.fromCharCode(e.keyCode)

        if (validChars.indexOf(char) <= -1) {
            input = ""
            validCode = false;
            return;
        }

        //first char
        if (input === '') {
            startTime = +new Date();
        }
        //1500ms timeout
        if (+new Date() - startTime > timeout) {
            startTime = +new Date();
            input = "";
            validCode = false;
        }

        input += char;

        if (input.length >= 12) {
            setBarcode(input);
            input = "";
            validCode = true;
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", keyEvent);
        return () => window.removeEventListener("keydown", keyEvent);
    }, [keyEvent]);

    return [ barcode, setBarcode ];
}
