import { css, jsx } from '@emotion/core';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, MessageBar, MessageBarType, TextField } from '@fluentui/react';
import i18next from 'i18next';
import { resetPassword, verifyToken } from '../../services/users.service';

const changeLanguage = (e, language) => {
    i18next.changeLanguage(language);
};

/** @jsx jsx */
export const ResetPasswordPage = () => {

    const history = useHistory();
    const { t } = useTranslation();

    const { loggedIn } = useSelector(s => s.authentication);
    const { token } = useParams();

    const [showLoginAnimation, setShowLoginAnimation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const onLoginClick = useCallback(() => {
        if (password !== repeatPassword) {
            setError(t('Please make sure that your passwords are the same.'));
            return;
        }
        setError('');
        setLoading(true);
        resetPassword(token, password)
            .then(r => {
                setLoading(false);
                history.push('/?success=password_changed')
            })
            .catch(e => { setLoading(false); setError(t(e.response.data.message)); });
    }, [history, token, repeatPassword, password, setError, setLoading, t]);

    useEffect(() => {
        if (loggedIn) {
            history.push(`/patients`);
        }
    }, [loggedIn, history]);

    useEffect(() => {
        setShowLoginAnimation(true);
    }, []);

    useEffect(() => {
        if (token) {
            verifyToken(token)
                .then(() => setTokenExpired(false))
                .catch(e => {
                    setError(t(e.response.data.message));
                    setTokenExpired(true);
                });
        }
    }, [token, t]);

    return <div css={styles}>
        <div className={`login-section ${showLoginAnimation ? 'animate' : ''}`}>
            <h1>{t('Reset password')}</h1>
            <p>{t('Please use the form below to reset the password for your administrator account')}</p>
            {error && <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>{error}</MessageBar>}
            <TextField disabled={tokenExpired || loading} label={t('Password')} type="password" value={password} onChange={e => setPassword(e.target.value)} />
            <TextField disabled={tokenExpired || loading} label={t('Repeat password')} type="password" value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} />
            <div className="actions">
                <PrimaryButton disabled={tokenExpired || loading} text={t('Reset password')} onClick={onLoginClick} allowDisabledFocus />
            </div>
        </div>
        <div className="footer">
            { /* eslint-disable-next-line */}
            Change language: <a href="#" className={i18next.language === 'en-US' ? 'selected' : ''} onClick={e => changeLanguage(e, 'en-US')}>English</a> | <a href="#" className={i18next.language === 'ja' ? 'selected' : ''} onClick={e => changeLanguage(e, 'ja')}>日本語</a>
        </div>
    </div>;
};


const styles = css`
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efefef;
    flex-direction: column;

    .login-section {
        background: white;
        width: 650px;
        box-shadow: 0 0 5px #e0e0e0;
        padding: 45px;
        opacity: 0;
        transition: all 1s;

        &.animate {
            opacity: 1;
        }

        .actions {
            margin-top: 25px;
            text-align: right;
        }
    }

    .footer {
        padding: 25px;
        font-size: 10px;
        color: #666666;

        a {
            color: #666666;

            &.selected {
                font-weight: bold;
            }
        }
    }
`;
