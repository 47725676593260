import { css, jsx } from '@emotion/core';

import { Dropdown, Panel, Stack, TextField, DefaultButton, PrimaryButton, DatePicker, MessageBarType, MessageBar } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { CrizitDropdown } from '../../../../components/crizit-dropdown.component';
import { CrizitSelect } from '../../../../components/crizit-select.component';
import { getPatients } from '../../../../services/patients.service';
import { addOrganization, getOrganizations } from '../../../../services/scanning.service';

import { getFullNameKanji } from '../../../../utils/full-name';

const patients = [
    { value: '0', label: 'New patient' },
];

const initialForm = bulkScanItemId => ({
    patientId: '',
    lastName: '',
    firstName: '',
    lastNameJa: '',
    firstNameJa: '',
    dateOfBirth: new Date('1990-01-02'),
    emailAddress: '',
    collectedDate: new Date(Date.now()),
    organizationId: 0,
    note: '',
    bulkScanItemId
});

/** @jsx jsx */
export const EnterInformation = ({
    isVisible,
    handleClosePanel,
    handleSubmitPanel,
    requestError,
    isSaving,
    bulkScanItemId
}) => {

    const [ formErrors, setFormErrors ] = useState([]);
    const [ generalError, setGeneralError ] = useState('');
    const { t } = useTranslation();
    const [ form, setForm ] = useState(initialForm(bulkScanItemId));
    const [ emailError, setEmailError ] = useState('');
    
    const [organizations, setOrganizations] = useState([]);
    const [isLoadingDropdown, setIsLoadingDropdown] = useState(false);
    const [ selectedOrganization, setSelectedOrganization ] = useState(null);

    const onLoadOptions = (inputValue, callback) => {
        getPatients({ keywords: inputValue }).then(response => {
            const options = [...response.data.items.map(p => ({ value: p.id, label: `${getFullNameKanji(p)}` })),  { value: '0', label: 'New patient' }];
            callback(options);
        });
    };

    const handleFieldChange = useCallback((e) => {
        const f = { ...form };
        f[e.target.name] = e.target.value;
        setForm(f);
    }, [ form ]);

    const handleDateChange = useCallback((name, e) => {
        const f = { ...form };
        f[name] = e;
        setForm(f);
    }, [ form ]);

    const handlePatientChange = useCallback((selected) => {
        const f = { ...form };
        f['patientId'] = selected.value;
        setForm(f);
    }, [ form ]);

    const handleSelectOrganization = useCallback( newValue => {
        if (newValue) {
            setSelectedOrganization(newValue);
            const f = { ...form };
            f['organizationId'] = newValue.value;
            setForm(f);
        } else {
            setSelectedOrganization(null);
            const f = { ...form };
            f['organizationId'] = 0;
            setForm(f);
        }
    }, [ form, selectedOrganization ]);

    const handleAddNewOrganization= useCallback((newOrganizationName) => {
        setIsLoadingDropdown(true);
        addOrganization({ name: newOrganizationName })
        .then(res => {
            setOrganizations(res.data.map(organization => ({ value: organization.id, label: organization.name })));
            const newAdded = res.data.find(org => org.name === newOrganizationName);
            if (newAdded) {
                setSelectedOrganization({ value: newAdded.id, label: newAdded.name });
            }
        })
        .catch(error => console.error('add new organization', error))
        .finally(() => setIsLoadingDropdown(false));
    }, [ setOrganizations ]);

    useEffect(() => {
        if(requestError) {
            const body = requestError.response.data;
            if(body.error === 'validation-error') {
                setFormErrors(body.message);
                const emailError = body.message.find(m => m.property === 'emailAddress');
                if (emailError) {
                    setEmailError(emailError.errors.toString());
                }
            }
        }
        else {
            setFormErrors([]);
            setGeneralError('');
        }
    }, [ requestError ]);

    useEffect(() => {
        setForm(initialForm(bulkScanItemId));
    }, [ bulkScanItemId ]);

    // load organizations
    useEffect(() => {
        getOrganizations()
        .then(res => {
            if (res.data.length > 0) {
                setOrganizations(res.data.map(organization => ({ value: organization.id, label: organization.name })));
            }
        })
        .catch(error => console.error('load organizations', error));
    }, []);

    return <Panel
        headerText={t('Enter patient information')}
        isLightDismiss={true}
        isOpen={isVisible}
        onDismiss={handleClosePanel}
        closeButtonAriaLabel="Close">
        <Stack tokens={{ childrenGap: 10 }}>
            { generalError && <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>{ generalError }</MessageBar> }
            <CrizitSelect label={t('Patient')} 
                options={patients}
                onChange={handlePatientChange}
                onLoadOptions={onLoadOptions}
            />
            {form.patientId === '0' && form.patientId !== '' &&
            <React.Fragment>
                <TextField
                    name="lastName"
                    label={t('Last name')}
                    value={form.lastname}
                    onChange={handleFieldChange}
                />
                <TextField
                    name="firstName"
                    label={t('First name')}
                    value={form.firstName}
                    onChange={handleFieldChange}
                />
                <TextField
                    name="lastNameJa"
                    label={t('Last name katakana')}
                    value={form.lastNameJa}
                    onChange={handleFieldChange}
                />
                <TextField
                    name="firstNameJa"
                    label={t('First name katakana')}
                    value={form.firstNameJa}
                    onChange={handleFieldChange}
                />
                <DatePicker
                    label="Date of birth"
                    placeholder="Date of birth"
                    value={form.dateOfBirth}
                    onSelectDate={(val) => handleDateChange('dateOfBirth', val)}
                />
                <TextField
                    name="emailAddress"
                    label={t('Email')}
                    value={form.emailAddress}
                    onChange={handleFieldChange}
                    errorMessage={emailError}
                />
            </React.Fragment>
            }
            {form.patientId !== '' && (
                <React.Fragment>
                    <DatePicker
                        label="Collected date"
                        placeholder="Collected date"
                        value={form.collectedDate}
                        onSelectDate={(val) => handleDateChange('collectedDate', val)}
                    />

                    <CrizitDropdown
                        options={organizations}
                        value={selectedOrganization}
                        isLoading={isLoadingDropdown}
                        label={t('Organization name')}
                        onChange={handleSelectOrganization}
                        onCreateOption={handleAddNewOrganization}
                    />

                    <TextField
                        name="note"
                        label={t('Note')}
                        value={form.note}
                        multiline={true}
                        row={4}
                        onChange={handleFieldChange}
                    />
                </React.Fragment>
            )}
        </Stack>
        <div css={actionStyles}>
            <DefaultButton onClick={handleClosePanel}>Cancel</DefaultButton>
            <PrimaryButton onClick={() => handleSubmitPanel({ ...form, bulkScanItemId })} disabled={isSaving || form.patientId === ''}>Save</PrimaryButton>
        </div>
    </Panel>;
}

const actionStyles = css`
    margin-top: 15px;
    text-align: right;
     > button {
         margin-left: 8px;
     }
`;