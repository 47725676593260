import React from 'react';
import { Helmet } from 'react-helmet';

export default function BarcodePrint () {
    return (
        <Helmet>
            <script type="module">{`
                import * as bpac from './bpac.js';
                const HOST = window.location.hostname === 'localhost' ? 'http://localhost:3001' : 'https://my.labost.com';
                window.DoPrint = async function DoPrint(number, copiesS=2, copiesL=2)
                {
                    if(bpac.IsExtensionInstalled() == false)
                    {
                        const agent = window.navigator.userAgent.toLowerCase();
                        const ischrome = (agent.indexOf('chrome') !== -1) && (agent.indexOf('edge') === -1)  && (agent.indexOf('opr') === -1)
                        if(ischrome)
                        window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank');
                        return;
                    }
                    
                    const codeToAssign = number;
                    const barcodeToAssign = HOST + "/?c=" + number;

                    try{
                        const strPath1 = HOST + "/pcr-customer.lbx";
                        const strPath2 = HOST + "/pcr-lab.lbx";
                        const objDoc = bpac.IDocument;
                        const ret1 = await objDoc.Open(strPath1);
                        if(ret1 == true)
                        {
                            const objNumber = await objDoc.GetObject("objNumber");
                            objNumber.Text = codeToAssign;
                            await objNumber.Text;
                            const objQrcode = await objDoc.GetObject("objQrcode");
                            objQrcode.Text = barcodeToAssign;
                            await objQrcode.Text;
                            await objDoc.StartPrint("", 0);
                            await objDoc.PrintOut(copiesL, 0);
                            await objDoc.EndPrint();
                            await objDoc.Close();
                        } else {
                            console.log('unable to load template, path: ', strPath1);
                        }
                        const ret2 = await objDoc.Open(strPath2);
                        if(ret2 == true)
                        {
                            const objNumber = await objDoc.GetObject("objNumber");
                            objNumber.Text = codeToAssign;
                            const objQrcode = await objDoc.GetObject("objQrcode");
                            objQrcode.Text = barcodeToAssign;
                            await objDoc.StartPrint("", 0);
                            await objDoc.PrintOut(copiesS, 0);
                            await objDoc.EndPrint();
                            await objDoc.Close();
                        }
                    }
                    catch(e)
                    {
                        console.error('printer error', e);
                    }
                    }
            `}</script>
        </Helmet>
    );
}