import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export const dateFormatter = (datestr) => dayjs(datestr).format("DD/MMM/YYYY");
export const dateFormatJp = (datestr) => dayjs(datestr).format("YYYY年MM月DD日");
export const dateTimeFormatJp = (datestr) => dayjs(datestr).format("YYYY年MM月DD日 HH:MM");
export const dateTimeFormatter = (datestr) => dayjs(datestr).format("LLL");

export const getDateObj = (dateStr) => dateStr ? new Date(dateStr) : null;

export const DATE_FORMAT = {
  JP: {
    YEAR_MONTH: "YYYY年MM月",
    YEAR_MONTH_DAY: "YYYY年MM月DD日",
    YMD_HM: "YYYY年MM月DD日 HH:MM",
  },
  EN: {
    // ... If need
  },
};
