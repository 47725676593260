import { css, jsx } from "@emotion/core";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { addPatient } from "../../services/patients.service";
import { PatientEditForm } from "./components/patient-form.component";

/** @jsx jsx */
export const AddPage = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({});
  const history = useHistory();
  const { userId } = useParams();
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (
      form.firstNameJa && form.lastNameJa && form.dateOfBirth && form.firstName && form.lastName
      && form.gender && form.postalCode && form.prefectureName && form.city && form.addressOne
      && form.emailAddress && form.phoneNumber
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [form, setIsValid]);

  const handleAddPatient = useCallback(() => {
    addPatient(userId, form)
      .then((response) => {
        history.push(`/patients/${response.data.id}`);
      })
      .catch((error) => console.error("add patient"));
  }, [form, history, userId]);

  return (
    <div css={styles} className="animationIn">
      <div className="left-right">
        <h1>{t("Add new patient")}</h1>
        <div className="buttons">
          <DefaultButton text={t("Back")} onClick={() => history.goBack()} />
          <PrimaryButton
            disabled={!isValid}
            text={t("Add new")}
            onClick={() => handleAddPatient()}
          />
        </div>
      </div>
      <div className="information-section" dir="ltr">
        <div className="ms-depth-4 bg-white section-container">
          <PatientEditForm form={form} setForm={setForm} />
        </div>
      </div>
    </div>
  );
};

const styles = css`
    max-width: 960px;
    .left-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .buttons {
            button {
                margin-left: 15px;
            }
        }
    }
`;
