import { css, jsx } from '@emotion/core';
import React, { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';

import { changeBarcodeStatus, removeItem, getScannedItems, processScannedItems, addScannedItem, addPatientInformation } from '../../../services/scanning.service';
import { useScanner } from '../../../hooks/use-scanner.hook';
import { status_options } from '../../../config/status-map';
import { StatusDropdown } from '../../../components/status-dropdown.component';
import { RemoveItemButton } from '../../../components/delete-button.component';
import { ShimmeredDetailsList, DetailsRow, MessageBar, MessageBarType, SelectionMode, Dialog, DialogFooter, DefaultButton, PrimaryButton, DialogType, Stack, TextField } from '@fluentui/react';
import { checkDuplicate } from '../../../utils/duplicate-check';
import { dateFormatter } from '../../../utils/date-format';
import { EmptyNotification } from '../../../components/empty-notification.component';
import { useZusStore } from '../../../zusStore';
import { Badge } from '../../../components/badge.component';
import scanErrorSfx from '../../../assets/sounds/Check.mp3';
import newAddSfx from '../../../assets/sounds/NewAdd.mp3';
import { EnterInformation } from './components/enter-information.component';
import { getFullNameKanji } from '../../../utils/full-name';
import { Permission } from '../../../components/permission.component';
const shimmeredDetailsListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

/** @jsxFrag React.Fragment */
/** @jsx jsx */
export const IndexPage = () => {    
    const { t } = useTranslation();
    const errorTimerReference = useRef();
    const duplicateTimerReference = useRef();
    const [isHiddenModal, setIsHiddenModal] = useState(true);
    const [scannedItems, setScannedItems] = useState([]);
    const [isDuplicated, setIsDuplicated] = useState(false);
    const [duplicatedBarcode, setDuplicatedBarcode] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorBarcode, setErrorBarcode] = useState('');
    const setIsHiddenDeleteModal = useZusStore((state) => state.setIsHiddenDeleteModal);
    const [ barcode, setBarcode ] = useScanner();
    const [ scanErrorSound ] = useSound(scanErrorSfx);
    const [ scanNewAddSound ] = useSound(newAddSfx);
    const [ editingId, setEditingId ] = useState(0);
    const [ newPatientAddError, setNewPatientAddError ] = useState(null);

    const fetchReceivalCodes = () => {
        getScannedItems('RECEIVING')
            .then(response => setScannedItems(response.data))
            .catch(error => console.error('fetching scanned items', error));
    }

    const addCodeToList = useCallback(async (barcode) => {
        try {
            const addedItem = await addScannedItem(barcode, 'RECEIVING');
            if (addedItem.data) {
                setScannedItems([...scannedItems, addedItem.data]);
                scanNewAddSound();
                setTimeout(() => {
                    const element = document.querySelector('.content');
                    element.scrollTop = element.scrollHeight;
                }, 50);
            }
        } catch (error) {
            scanErrorSound();
            if(errorTimerReference.current) {
                clearTimeout(errorTimerReference.current);
            }
            setIsError(true);
            setErrorBarcode(barcode);
            errorTimerReference.current = setTimeout(() => {
                setIsError(false);
                setErrorBarcode('');
            }, 5000);
        }
    }, [ setScannedItems, setIsError, setErrorBarcode, scannedItems ]);

    useEffect(() => {
        if (barcode && barcode.length === 12) {
            const isDuplicated = checkDuplicate(scannedItems, barcode);
            if (isDuplicated) {
                setIsDuplicated(true);
                setDuplicatedBarcode(barcode);
                scanErrorSound();
                if(duplicateTimerReference.current) {
                    clearTimeout(duplicateTimerReference.current);
                }
                duplicateTimerReference.current = setTimeout(() => {
                    setIsDuplicated(false)
                    setDuplicatedBarcode('');
                }, 5000);
            } else {
                addCodeToList(barcode);
            }
        }
    }, [ barcode ]);

    // load the unprocessed scanned bulk list
    useEffect(() => {
        fetchReceivalCodes();
    }, [])

    // highlight the duplicated barcode
    useEffect(() => {
        setScannedItems(scannedItems.map(item => {
            item.isDuplicated = item.barcode === duplicatedBarcode;
            return item;
        }));
    }, [duplicatedBarcode])



    const handleProcessClick = useCallback(() => {
        processScannedItems('RECEIVING')
        .then(response => {
            setIsHiddenModal(true)
            setScannedItems([])
        })
    }, [ setIsHiddenModal, setScannedItems ]);
    
    const handleDropdownChange = async (item, selectedStatus) => {
        try {
            const updatedItem = await changeBarcodeStatus(item.id, selectedStatus.key);
            
            setScannedItems(oldItems => oldItems.map(olditem => {
                if (olditem.id === updatedItem.data.id) {
                    olditem.newStatus = updatedItem.data.newStatus;
                }
                return olditem;
            }));

        } catch (error) {
            console.error('update status', error);
        }
    };
    
    const handleRemoveItem = async (id) => {
        try {
            await removeItem(id);
            setScannedItems(prevItemsList => prevItemsList.filter(item => item.id !== id));
            setIsHiddenDeleteModal(true);
        } catch (error) {
            console.error(error);
            setIsHiddenDeleteModal(true);
        }
    }

    const handleSubmitPanel = (form) => {
        addPatientInformation(form)
        .then(res => {
            setEditingId(0);
            fetchReceivalCodes();
        })
        .catch(err => setNewPatientAddError(err));
    }

    return (
        <div css={styles} className="animationIn">
            <div className="header-splitted">
                <div className="left">
                    <h1>{t('Scan on receival')}</h1>
                </div>
                <Permission only="ScanOnReceival.Edit">
                    <div className="right">
                        <TextField
                            value={barcode}
                            onChange={(e) => {
                                const code = e.target.value;
                                if(!Number.isNaN(Number(code))) {
                                    setBarcode(code);
                                }
                            }}
                        />
                    </div>
                </Permission>
            </div>
            <div className="ms-depth-4 bg-white section-container">
                {isError && <MessageBar
                        messageBarType={MessageBarType.error}
                    >{t(`Barcode ${errorBarcode} not found.`)}</MessageBar>}
                {isDuplicated && 
                    <MessageBar
                        messageBarType={MessageBarType.error}
                    >{t('Duplicate barcode.')}</MessageBar>}
                <Stack tokens={{ childrenGap: 30 }}>
                    {scannedItems.length === 0 && (
                        <EmptyNotification>{t('Current receival list is empty. Scan a barcode to begin.')}</EmptyNotification>
                    )}
                    {scannedItems.length > 0 && (
                        <React.Fragment>
                            <ShimmeredDetailsList
                            setKey="items"
                            items={scannedItems}
                            columns={scannedItemsColumns(t, handleDropdownChange, handleRemoveItem, setEditingId)}
                            selectionMode={SelectionMode.none}
                            enableShimmer={scannedItems.length === 0}
                            ariaLabelForShimmer="Content is being fetched"
                            ariaLabelForGrid="Item details"
                            listProps={shimmeredDetailsListProps}
                            onRenderRow={onRenderRow}
                        />
                        <Permission only="ScanOnReceival.Edit">
                            <PrimaryButton style={{ width: 100 }} text={t("process")} onClick={() => { setIsHiddenModal(!isHiddenModal) }} />
                        </Permission>
                    </React.Fragment>
                    )}
                </Stack>
            </div>
            <EnterInformation
                isVisible={editingId !== 0}
                bulkScanItemId={editingId}
                requestError={newPatientAddError}
                handleSubmitPanel={handleSubmitPanel}
                handleClosePanel={() => setEditingId(0)}
            />
            <Dialog
                hidden={isHiddenModal}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: t("process"),
                    subText:
                        t("Are you sure you want to save scanned barcodes?")
                }}
                modalProps={{
                    isBlocking: false
                }}
            >
                <DialogFooter>
                    <DefaultButton text={t("No")} onClick={() => { setIsHiddenModal(true) }} />
                    <PrimaryButton text={t("Yes")} onClick={() => { handleProcessClick() }} />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export const onRenderRow = props => {
    const customStyles = {};
    if (props) {
        if (props.item.isDuplicated) {
            customStyles.root = { backgroundColor: '#FFE6E9' };
        }
        return <DetailsRow 
            {...props} 
            styles={customStyles} 
            />;
    }
    return null
};

export const scannedItemsColumns = (t, handleDropdownChange, handleRemoveItem, setEditingId) => {
    const statusHasError = (status) => {
        const statusValues = Object.keys(status_options);
        return statusValues.indexOf(status) > statusValues.indexOf('PATIENT_COMPLETED')
    }
    return [
        {
            key: 'barcode',
            name: t('Barcode'),
            fieldName: 'barcode',
            className: 'barcode-info',
            onRender: item => item.barcode,
        },
        {
        key: 'Scanned At',
        name: t('Scanned at'),
        fieldName: 'scannedTime',
        className: 'barcode-info',
        onRender: item => <span>{dateFormatter(item.scannedAt)}</span>
    }, {
        key: 'patientName',
        name: t('Patient name'),
        fieldName: 'patientName',
        className: 'barcode-info',
        onRender: item => item.registration ? `${getFullNameKanji(item.registration?.patient)}` : <a href="#" onClick={() => setEditingId(item.id)}>Enter</a>,
    }, {
        key: 'status',
        name: t('Status'),
        fieldName: 'status',
        className: 'barcode-info',
        onRender: item => item.registration ? <span className={statusHasError(item.registration?.status) ? 'status error' : 'status'}>{t(status_options[item.registration?.status])}</span> : 'N/A'
    },
    {
        key: 'additions',
        name: t('Additions'),
        fieldName: 'additions',
        className: 'additions',
        minWidth: 200,
        onRender: item => (<div className="badges">
            <Badge type={ item?.isPriority ? 'danger' : 'default' }>{ t('Rapid') }</Badge>&nbsp;
            <Badge type={ item?.needsProof ? 'primary' : 'default' }>{ t('PONR') }</Badge>
        </div>),
    },
    {
        key: 'newstatus',
        name: t('New status'),
        fieldName: 'status',
        className: 'barcode-info barcode-info--newstatus',
        onRender: item => <StatusDropdown item={item} onChange={(_, value) => handleDropdownChange(item, value)} />
    }, {
        key: 'operation',
        name: t('Operation'),
        fieldName: 'operation',
        onRender: item => (<>
            <RemoveItemButton deleteMessage={t("Are you sure you want to remove this barcode record?")} onClick={() => handleRemoveItem(item.id)} />
        </>)
    },
];}


// center align barcode info cell
const styles = css`
    .header-splitted {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;

            .right {
                width: 250px;
            }
    }
    .barcode-info {
        display: inline-flex;
        align-items: center;
    }

    .status.error {
        font-weight: bold;
        color: #FF5209;
        border: 1px solid #FF5209;
        padding: 7px 6px;
        border-radius: 2px;
    }
`;
