import { createSlice } from "@reduxjs/toolkit";
import { login } from "../services/users.service";

const inititalState = () => {
  try {
    const initialUserState = window.localStorage.getItem("admin");
    if (initialUserState) {
      return JSON.parse(initialUserState);
    }
  } catch (e) {
    // Don't do anything
  }

  return {
    loggedIn: false,
    authenticationError: "",
    user: null,
    permissions: [],
  };
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: inititalState(),
  reducers: {
    setUser: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload;
      state.authenticationError = "";
      state.permissions = action.payload.role.permissions.length
        ? action.payload.role.permissions
        : [];
      window.localStorage.setItem("admin", JSON.stringify(state));
    },
    setAuthenticationError: (state, action) => {
      state.loggedIn = false;
      state.authenticationError = action.payload;
    },
    logoutUser: (state, action) => {
      state.loggedIn = false;
      localStorage.removeItem("admin");
    },
  },
});

export const { setUser, setAuthenticationError, logoutUser } = authenticationSlice.actions;

export const loginAsync = (username, password) => (dispatch) => {
  login(username, password)
    .then((response) => dispatch(setUser(response.data)))
    .catch((error) => dispatch(setAuthenticationError(error.response.data.message)));
};

export const selectUser = (state) => state.authentication.user;

export const selectLoggedIn = (state) => state.authentication.loggedIn;

export const selectPermissions = (state) => state.authentication.permissions;

export default authenticationSlice.reducer;
