import { configureLoadStyles } from 'office-ui-fabric-react-pagination';
import { useTranslation } from 'react-i18next';

const iconStyle = {root: {marginLeft: 15}};

const hasPermission = (permissions, menuPermission) => {
    if (permissions.includes('*')) {
        return true;
    }
    if (typeof menuPermission === 'string') {
        return permissions.includes(menuPermission);
    } else if (Array.isArray(menuPermission)) {
        return menuPermission.find(perm => permissions.includes(perm));
    } else {
        return false;
    }
}

export const AdminSettingsMenu = () => {
    const { t } = useTranslation();
    return [{
        links: [
            {
                name: t('Administrators'),
                url: '/administrators',
            },
            {
                name: t('Roles'),
                url: '/roles',
            },
            {
                name: t('Back'),
                url: '/patients',
                iconProps: {
                    iconName: 'Back',
                    styles: iconStyle
                }
            },
        ]
    }];
}

export const SideMenu = (permissions) => {
    const { t } = useTranslation();

    return [{
        links: [
            {
                name: t('Users'),
                url: '/users',
                permission: 'User.View',
                iconProps: {
                    iconName: 'ContactInfo',
                    styles: iconStyle
                }
            },
            {
                name: t('Patients'),
                url: '/patients',
                permission: 'Patient.View',
                iconProps: {
                    iconName: 'Family',
                    styles: iconStyle
                }
            },
            {
                name: t('Organizations'),
                url: '/organizations',
                permission: 'Organization.View',
                iconProps: {
                    iconName: 'CityNext',
                    styles: iconStyle
                }
            },
            {
                name: t('Registrations'),
                url: '/registrations',
                permission: 'Registration.View',
                iconProps: {
                    iconName: 'DocumentSet',
                    styles: iconStyle
                }
            },
            {
                name: t('Completed Tests'),
                url: '/completedtests',
                permission: 'Cycle.View',
                iconProps: {
                    iconName: 'WaitlistConfirm',
                    styles: iconStyle
                }
            },
            {
                name: t('Scan'),
                expandAriaLabel: 'Expand Scan section',
                collapseAriaLabel: 'Collapse Scan section',
                permission: ['Cycle.View', 'ScanOnShipment.View', 'ScanOnReceival.View'],
                links: [
                    {
                        name: t('Scan on shipment'),
                        url: '/scan/shipment',
                        key: 'key1',
                        permission: 'ScanOnShipment.View',
                        iconProps: {
                            iconName: 'Airplane',
                            styles: iconStyle
                        }
                    },
                    {
                        name: t('Scan on receival'),
                        url: '/scan/receival',
                        key: 'key2',
                        
                        permission: 'ScanOnReceival.View',
                        iconProps: {
                            iconName: 'Package',
                            styles: iconStyle
                        }
                    },
                    {
                        name: t('Cycles'),
                        url: '/scan/cycles',
                        key: 'key3',
                        permission: 'Cycle.View',
                        iconProps: {
                            iconName: 'TestBeaker',
                            styles: iconStyle
                        }
                    }
                ].filter(menuItem => hasPermission(permissions, menuItem.permission)),
                isExpanded: true
            },
            {
                name: t('Inventory'),
                url: '/inventory',
                permission: 'Inventory.View',
                iconProps: {
                    iconName: 'QRCode',
                    styles: iconStyle
                }
            },
            {
                name: t('Shipped Barcode'),
                url: '/shipped-barcode',
                permission: 'Inventory.View',
                iconProps: {
                    iconName: 'DeliveryTruck',
                    styles: iconStyle
                }
            },
            {
                name: t('Settings'),
                url: '/settings',
                permission: 'Administrator.View',
                iconProps: {
                    iconName: 'Settings',
                    styles: {
                        root: {
                            marginLeft: 15
                        }
                    }
                }
            },
        ].filter(menuItem => hasPermission(permissions, menuItem.permission)),
    }];
}