import { css, jsx } from "@emotion/core";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Spinner,
  Stack,
  Dropdown,
} from "@fluentui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Badge } from "../../../components/badge.component";
import { NoteToolTip } from "../../../components/note-tooltip.component";
import { Permission } from "../../../components/permission.component";
import { PlainCycleList } from "../../../components/plain-cycle-list.component";
import { confirmResult, getCycleById } from "../../../services/scanning.service";
import { format } from "../../../utils/code-format";
import { getFullNameKanji } from "../../../utils/full-name";

/** @jsx jsx */
export const IndexPage = () => {
  const { t } = useTranslation();
  const { cycleId } = useParams();
  let history = useHistory();
  const [isHiddenModal, setIsHiddenModal] = useState(true);
  const [currentCycleList, setCurrentCycleList] = useState([]);
  const [cycle, setCycle] = useState({});

  const [isSendingResult, setIsSendingResult] = useState(false);

  const handleConfirmClick = () => {
    setIsSendingResult(true);

    const rack = {
      cycleid: cycleId,
      rack: currentCycleList
        .filter((item) => item.barcode !== "FFFFFFFFFFFF")
        .map((item) => ({
          barcode: item.barcode,
          result: item.result || "NEGATIVE",
          trayPosition: item.trayPosition,
        })),
    };

    confirmResult(rack)
      .then(() => {
        setIsSendingResult(false);
        history.push("/scan/cycles");
      })
      .catch((error) => console.error("confirm result", error));
  };

  const handleResultAssign = (result, i) => {
    const updatedList = currentCycleList.map((item) => {
      if (item.trayPosition === i.trayPosition) {
        item.result = result;
        return item;
      } else {
        return item;
      }
    });
    setCurrentCycleList(updatedList);
  };

  useEffect(() => {
    if (!cycleId) history.push("/scan/cycles");
    getCycleById(cycleId)
      .then((response) => {
        setCycle(response.data);
        setCurrentCycleList(response.data.bulkScanItems.map(z => ({...z, result: "ε3/ε3"})));
      })
      .catch((error) => console.error(`fetching cycle ${cycleId} error`, error));
  }, [cycleId, history]);

  const ResultSelectDropdown = ({ item }) => (
    <Dropdown
      name="result"
      selectedKey={item.result}
      defaultSelectedKey={item.result}
      options={[
        {
          key: "ε4/ε4",
          text: "ε4/ε4",
        },
        {
          key: "ε4/ε3",
          text: "ε4/ε3",
        },
        {
          key: "ε4/ε2",
          text: "ε4/ε2",
        },
        {
          key: "ε3/ε3",
          text: "ε3/ε3",
        },
        {
          key: "ε3/ε2",
          text: "ε3/ε2",
        },
        {
          key: "ε2/ε2",
          text: "ε2/ε2",
        },
        {
          key: "RETEST",
          text: "RETEST",
        },
      ]}
      onChange={(e, options) => handleResultAssign(options.key, item)}
    />
  )

  const getInventoryCompanyNameByBarcode = (barcode) =>
    cycle.inventoryItems.find((a) => a.barcode === barcode)?.preassignedOrganizationName || "";

  const cycleListColumnsConfig = () => {
    return [
      {
        minWidth: 30,
        maxWidth: 30,
        name: "#",
        key: "positionnumber",
        fieldName: "positionnumber",
        className: "cycleListItem cycleListItem--number",
        onRender: (item) => `# ${item.trayPosition}`,
      },
      {
        key: "result",
        name: t("Result"),
        fieldName: "result",
        className: "cycleListItem",
        isResizable: true,
        minWidth: 270,
        onRender: (item) => item.barcode !== "000000000000" && item.barcode !== "FFFFFFFFFFFF" && <ResultSelectDropdown item={item} />
      },
      {
        minWidth: 180,
        maxWidth: 200,
        key: "barcode",
        name: t("Testing number"),
        fieldName: "barcode",
        className: "cycleListItem",
        isResizable: true,
        onRender: (item) => (
          <p className="cycleListItem__barcode">
            {item.barcode === "000000000000" ? "その他" : format(item.barcode)}
          </p>
        ),
      },
      {
        minWidth: 120,
        maxWidth: 160,
        key: "fullname",
        name: t("Full name"),
        fieldName: "fullname",
        className: "cycleListItem",
        isResizable: true,
        onRender: (item) => (
          <span className="cycleListItem__name">
            {getFullNameKanji(item.registration?.patient)}
          </span>
        ),
      },
      {
        minWidth: 120,
        maxWidth: 200,
        key: "orgName",
        name: t("Org name"),
        fieldName: "orgName",
        className: "cycleListItem",
        isResizable: true,
        onRender: (
          item,
        ) => (`${item.registration?.patient?.companyName || ""}/${
          getInventoryCompanyNameByBarcode(item.registration?.barcode) || ""
        }`),
      },
      {
        key: "registered",
        name: t("Registered"),
        fieldName: "registered",
        className: "cycleListItem",
        minWidth: 120,
        maxWidth: 120,
        onRender: (item) => (item.registration?.modifiedAt
          ? dayjs(item.registration.modifiedAt).format("YYYY年MM月DD日")
          : "N/A"),
      },
      {
        key: "note",
        name: t("Note"),
        fieldName: "note",
        className: "cycleListItem",
        minWidth: 120,
        maxWidth: 200,
        onRender: (item) =>
          item.registration?.patient?.note
            ? <NoteToolTip noteDetail={item.registration?.patient?.note} tooltipId={item.barcode} />
            : "N/A",
      },
      {
        key: "additions",
        name: t("Additions"),
        fieldName: "additions",
        className: "additions",
        minWidth: 200,
        onRender: (item) => (
          <div className="badges">
            <Badge type={item?.registration?.isPriority ? "danger" : "default"}>{t("Rapid")}
            </Badge>&nbsp;
            <Badge type={item?.registration?.requiresProof ? "primary" : "default"}>
              {t("PONR")}
            </Badge>
          </div>
        ),
      },
    ];
  };

  const cycleListColumns = cycleListColumnsConfig();

  return (
    <div css={styles} className="animationIn">
      <h1>{`${t("Current cycle")} #${cycleId}`}</h1>
      <div className="ms-depth-4 bg-white section-container">
        <Stack tokens={{ childrenGap: 40 }} style={{ overflowX: "scroll" }}>
          <PlainCycleList cycleList={currentCycleList} cycleListColumns={cycleListColumns} />
          <Stack className="cycle-operations" horizontal tokens={{ childrenGap: 40 }}>
            <DefaultButton text={t("Back")} onClick={() => history.push("/scan/cycles")} />
            <DefaultButton
              text={t("Print")}
              onClick={() => {
                window.print();
              }}
            />
            <Permission only="Cycle.Edit">
              <PrimaryButton
                text={t("Confirm results")}
                onClick={() => {
                  setIsHiddenModal(false);
                }}
              />
            </Permission>
          </Stack>
        </Stack>
      </div>
      <Dialog
        hidden={isHiddenModal}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("Confirm testing result"),
          subText: t("Are you sure you want to confirm the testing result?"),
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        <DialogFooter>
          {isSendingResult
            ? (
              <Spinner
                label={t("Sending email, please wait...")}
                ariaLive="assertive"
                labelPosition="right"
              />
            )
            : (
              <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
                <DefaultButton
                  text={t("No")}
                  onClick={() => {
                    setIsHiddenModal(true);
                  }}
                />
                <PrimaryButton
                  text={t("Yes")}
                  onClick={() => {
                    handleConfirmClick();
                  }}
                />
              </Stack>
            )}
        </DialogFooter>
      </Dialog>
    </div>
  );
};

const styles = css`

.cycleListItem {
    display: inline-flex;
    align-items: center;

    &--delete{
        .clearButton {
            margin-left: 16px;
        }
    }
    &--number {
        font-size: 14px;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
    }
    &__barcode {
        opacity: .9;
        font-weight: bold;
    }
}
.result-assign-buttons {
    width: 270px;
    display: flex;
}

.result-assign-button {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    min-width: 80px;
    height: 32px;
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(138, 136, 134);
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
    transition: 300ms ease-in color, 300ms ease-in background-color, 300ms ease-in border-color;
    &:hover {
        background-color: rgb(243, 242, 241);
        color: rgb(32, 31, 30);
    }

    &--positive.active {
        color: #fff;
        border-color: #FF5209;
        background-color: #FF5209;
    }
    
    &--negative.active {
        color: #fff;
        border-color: #66AC46;
        background-color: #66AC46;
    }
    &--retest.active {
        color: #fff;
        border-color: #FFB526;
        background-color: #FFB526;
    }
}
.result-assign-button:not(:first-of-type) {
    margin-left: -1px;
}

@media only print {
    padding: 5px 10px;
    h1 {
        font-size: 14px;
    }
    .section-container {
        padding: 0;
        box-shadow: none;
    }
    .cycle-operations {
        display: none;
    }
    table {
        th, th > * , td, td > * {
            font-size: 12px !important;
            padding: 0 !important;
            margin: 0 !important;
        }
        tr, tr > * {
            height: auto !important;
            padding: 0 !important;
            max-height: 16px !important;
            margin: 0 !important;
        }

        
        tr td:nth-of-type(2),
        tr td:nth-of-type(7),
        tr th:nth-of-type(2),
        tr th:nth-of-type(7) {
            display: none;
        }
    }
}
`;
