import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'
import { SelectionMode, DetailsList, DetailsListLayoutMode, CheckboxVisibility, SearchBox, Dropdown, Stack, DatePicker, IconButton } from 'office-ui-fabric-react';

const filtersUserStatus = t => [
    { key: 'all', name: t('All')},
    { key: 'emailVerified', name: t('Email verified')},
];

const invisibleColumns = [
    { key: 'column1', name: 'View by', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true, className: 'users-filter-options' }
];

const getDateObj = dateStr => dateStr ? new Date(dateStr) : null

/** @jsx jsx */
export const Filters = ({ 
    query,
    setQuery,
    selectionUserStatus
 }) => {
    const { t } = useTranslation();

    return (
        <Stack tokens={{ childrenGap: 30 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <span className="filter-header ms-fontSize-12">{t('Search')}</span>
                <SearchBox placeholder={t("Search")} value={query.keywords} onSearch={newValue => setQuery({ keywords: (newValue || '')})} onClear={() => setQuery({ keywords: ''})} />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
                <span className="filter-header ms-fontSize-12">{t('User status')}</span>
                <DetailsList
                    isHeaderVisible={false}
                    compact={true}
                    items={filtersUserStatus(t)}
                    columns={invisibleColumns}
                    setKey="set"
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionMode={SelectionMode.single}
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={selectionUserStatus.current}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
                <span className="filter-header ms-fontSize-12">{t('Created date')}</span>
                <DatePicker
                    textField={{
                        onRenderSuffix: query.dateFrom ? () => <IconButton iconProps={{ iconName: 'trash' }} onClick={() => { setQuery({ dateFrom: undefined }) }} /> : null,
                        styles: { suffix: { padding: "0px", backgroundColor: "#fff" } },
                        iconProps: {
                            iconName: query.dateFrom ? '' : 'Calendar',
                          } 
                    }}
                    value={getDateObj(query.dateFrom)}
                    placeholder={t('Created date')}
                    formatDate={date => dayjs(date).format('YYYY年MM月DD日')}
                    onSelectDate={value => setQuery({ dateFrom: value.toISOString() })}
                />
            </Stack>
        </Stack>);
};