import { css, jsx } from "@emotion/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { addOrganization, getOrganizations } from "../services/scanning.service";
import { CrizitDropdown } from "./crizit-dropdown.component";

export const OrganizationAssign = (
  { label = "", selectedOrganization, setSelectedOrganization, preSelectedName = "" },
) => {
  const { t } = useTranslation();

  const [organizations, setOrganizations] = useState([]);
  const [isLoadingDropdown, setIsLoadingDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getOrganizations()
      .then((res) => {
        if (res.data?.data.length > 0) {
          setOrganizations(
            res.data.data.map((organization) => ({
              value: organization.id,
              label: organization.name,
            })),
          );
          setErrorMessage("");
        }
      })
      .catch(() => setErrorMessage(t("fetch organization error")));
  }, []);

  const handleSelectOrganization = useCallback((newValue) => {
    setSelectedOrganization(newValue);
  }, [selectedOrganization]);

  const handleAddNewOrganization = useCallback((newOrganizationName) => {
    setIsLoadingDropdown(true);
    addOrganization({ name: newOrganizationName })
      .then((res) => {
        setOrganizations(
          res.data.map((organization) => ({ value: organization.id, label: organization.name })),
        );
        const newAdded = res.data.find((org) => org.name === newOrganizationName);
        if (newAdded) {
          setSelectedOrganization({ value: newAdded.id, label: newAdded.name });
        }
      })
      .catch((error) => {
        console.error("add new organization", error);
      })
      .finally(() => {
        setIsLoadingDropdown(false);
      });
  }, [organizations]);

  return (
    <CrizitDropdown
      options={organizations}
      value={selectedOrganization}
      isLoading={isLoadingDropdown}
      label={t(label)}
      onChange={handleSelectOrganization}
      onCreateOption={handleAddNewOrganization}
      errorMessage={errorMessage}
    />
  );
};
