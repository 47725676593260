/*
 * NOTE: Value of PRODUCT_MASTER has to be matched with productId.
 */

export const PRODUCT_MASTER = {
  PCR_COVID_SALIVA: 1,
  PCR_COVID_NASALSWAB: 2,
  PCR_APOE: 3,
  // ADD another product here
};
