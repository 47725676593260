import fileDownload from "js-file-download";
import qs from "query-string";
import axios from "../config/axios";

const url = process.env.REACT_APP_BASE_API;

export const login = (username, password) => {
  return axios.post(`${url}/administrators/login`, { username, password });
};

export const refreshToken = () => {
  return axios.get(`${url}/administrators/refresh-token`);
};

export const forgotPassword = (username) => {
  return axios.post(`${url}/administrators/forgot-password`, { username });
};

export const verifyToken = (token) => {
  return axios.post(`${url}/administrators/verify-token`, { token });
};

export const resetPassword = (token, password) => {
  return axios.post(`${url}/administrators/reset-password`, { token, password });
};

export const getAdministrators = (options) => {
  return axios.get(`${url}/administrators/admin/?${qs.stringify(options)}`);
};

export const getAdministrator = (administratorId, options = {}) => {
  return axios.get(`${url}/administrators/admin/view/${administratorId}?${qs.stringify(options)}`);
};

export const createAdministrator = (data, options = {}) => {
  return axios.post(`${url}/administrators/admin/create/?${qs.stringify(options)}`, {
    administrator: data,
  });
};

export const saveAdministrator = (administratorId, data, options = {}) => {
  return axios.post(
    `${url}/administrators/admin/edit/${administratorId}?${qs.stringify(options)}`,
    { administrator: data },
  );
};

export const deleteAdministrator = (administratorId, options = {}) => {
  return axios.post(
    `${url}/administrators/admin/delete/${administratorId}?${qs.stringify(options)}`,
    {},
  );
};

export const invite = (userId) => {
  return axios.post(`${url}/users/admin/invite`, { id: userId });
};

export const getAllUsers = (options) => {
  return axios.get(`${url}/users/admin/findall?${qs.stringify(options)}`);
};

export const downloadUserCsv = () => {
  return axios.get(`${url}/users/admin/download`, { responseType: "blob" }).then((response) => {
    fileDownload(response.data, "export.csv");
  });
};

export const getUserDetail = (userId) => {
  return axios.get(`${url}/users/admin/getUserDetail/${userId}`);
};
