import { css, jsx } from '@emotion/core';

/** @jsx jsx */
export const InformationRow = ({ label, children }) => <div className="ms-Grid-row" css={informationRowStyles}>
    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 strong">
        {label}
    </div>
    <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8">
        {children}
    </div>
</div>;

const informationRowStyles = css`
    padding: 5px 0;

    .strong {
        font-weight: bold;
    }
`;