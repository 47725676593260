import { RESULT_MASTER } from '../../utils/result-enums';

export const registrationResultOptionsMaster = (t) => ({
  COVID: [
    {
      key: RESULT_MASTER.COVID.NEGATIVE,
      text: t('Mark negative'),
      className: 'radio-negative'
    },
    {
        key: RESULT_MASTER.COVID.POSITIVE,
        text: t('Mark positive'),
        className: 'radio-positive'
    },
    {
        key: 'SUSPECTED_POSITIVE',
        text: t('Mark suspected positive'),
        className: 'radio-suspected-positive'
    },
  ],
  APOE: [
    {
      key: RESULT_MASTER.APOE.HIGHEST_RISK,
      text: RESULT_MASTER.APOE.HIGHEST_RISK,
    },
    {
        key: RESULT_MASTER.APOE.HIGHER_RISK,
        text: RESULT_MASTER.APOE.HIGHER_RISK,
    },
    {
      key: RESULT_MASTER.APOE.HIGH_RISK,
      text: RESULT_MASTER.APOE.HIGH_RISK,
    },
    {
      key: RESULT_MASTER.APOE.MIDDLE_RISK,
      text: RESULT_MASTER.APOE.MIDDLE_RISK,
    },
    {
      key: RESULT_MASTER.APOE.LOWER_RISK,
      text: RESULT_MASTER.APOE.LOWER_RISK,
    },
    {
      key: RESULT_MASTER.APOE.LOWEST_RISK,
      text: RESULT_MASTER.APOE.LOWEST_RISK,
    },
    {
      key: RESULT_MASTER.APOE.RETEST,
      text: t("Mark re test"),
    },
  ]
})