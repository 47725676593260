import axios from '../config/axios';
import qs from 'query-string';

const url = process.env.REACT_APP_BASE_API;

export const getPatients = (options) => {
    return axios.get(`${url}/patients/admin/?${qs.stringify(options)}`);
};

export const getPatient = (patientId, options = {}) => {
    return axios.get(`${url}/patients/admin/view/${patientId}?${qs.stringify(options)}`);
};

export const getPatientUpload = (fileName) => {
    return axios.get(`${url}/registrations/admin/patient-upload/${fileName}`)
    .then(response => `data:${response.data.mimetype};base64,${Buffer.from(response.data.buffer.data).toString('base64')}`)
    .catch(e => console.log(e));
}

export const updatePatient = (patient) => {
    return axios.patch(`${url}/patients/admin/update`, { patient });
}

export const addPatient = (userId, patient) => {
    return axios.post(`${url}/patients/admin/add/${userId}`, { patient });
}
