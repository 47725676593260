import axios from '../config/axios';
import qs from 'query-string';

const url = process.env.REACT_APP_BASE_API;

export const getRoles = (options) => {
    return axios.get(`${url}/roles/admin/?${qs.stringify(options)}`);
};

export const getRole = (roleId, options = {}) => {
    return axios.get(`${url}/roles/admin/view/${roleId}?${qs.stringify(options)}`);
};

export const createRole = (data, options = {}) => {
    return axios.post(`${url}/roles/admin/create/?${qs.stringify(options)}`, {role: data});
};

export const saveRole = (roleId, data, options = {}) => {
    return axios.post(`${url}/roles/admin/edit/${roleId}?${qs.stringify(options)}`, {role: data});
};

export const deleteRole = (roleId, options = {}) => {
    return axios.post(`${url}/roles/admin/delete/${roleId}?${qs.stringify(options)}`, {});
};

export const getAllPermissions = (options = {}) => {
    return axios.get(`${url}/roles/admin/get-all-permissions`);
};