import React, { useEffect, useState, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ShimmeredDetailsList, SelectionMode, Stack, FontIcon, mergeStyles, DefaultButton, SearchBox  } from '@fluentui/react';
import {
    useQueryParams,
    StringParam,
    NumberParam,
  } from 'use-query-params';

import { getOrganizations } from '../../services/organizations.service';
import Pagination from 'office-ui-fabric-react-pagination';
import { Permission } from '../../components/permission.component';

const shimmeredDetailsListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

/** @jsx jsx */
export const IndexPage = () => {

    const history = useHistory();
    const { t } = useTranslation();
    const [totalPages, setTotalPages] = useState(1);
    const [organizations, setOrganizations] = useState([]);

    const [query, setQuery] = useQueryParams({
        page: NumberParam,
        sortBy: StringParam,
        keywords: StringParam,
        sortByDirection: StringParam,
      });
    const { page, sortBy, keywords, sortByDirection } = query;

    const fetchOrganizations = useCallback(filter => {
        getOrganizations(filter).then(response => {
            setOrganizations(response.data.items);
            setTotalPages(Number(response.data.meta?.totalPages || 1));
            setQuery({ page: Number(response.data.meta?.currentPage || 1)});
        }).catch(error => console.error('get filtered organizations', error))
    
    }, [ sortBy, sortByDirection, keywords, page ]);

    useEffect(() => {
        const filters = {};
        filters.page = page;
        filters.sortBy = sortBy;
        filters.keywords = keywords;
        filters.sortByDirection = sortByDirection;
        fetchOrganizations(filters);
    }, [sortBy, sortByDirection, keywords, page]);

    const onColumnClick = column => {
        if (column.key === 'name') {
            if(column.key === sortBy) {
                setQuery({ sortByDirection: (sortByDirection === 'ASC' ? 'DESC' : 'ASC')});
            }
            else {
                setQuery({ sortByDirection: ('ASC'), sortBy: column.key });
            }
        }
    };

    const iconClass = mergeStyles({
        fontSize: 15,
        height: 15,
        width: 15,
        marginRight: 4,
        color: 'rgb(102, 172, 70)'
      });

    const Operations = ({item}) => (
        <React.Fragment>
            <Link to={`/organizations/${item.id}`}><FontIcon iconName="PageEdit" className={iconClass} /></Link>
        </React.Fragment>
    );

    const organizationsColumns = [
        {
            key: 'name',
            name: t('Organization name'),
            fieldName: 'name',
            onRender: item => <Link to={`/organizations/${item.id}`}>{item.name}</Link>
        }, {
            key: 'operations',
            name: t('Operation'),
            fieldName: 'operations',
            isResizable: true,
            onRender: item => <Operations item={item} />
        }
    ];

    
    return <div css={styles} className="animationIn">
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <h1>{t('Organizations')}</h1>
            <Permission only="Organization.Edit">
                <DefaultButton
                    text={t('Add organization')}
                    onClick={() => history.push('/organizations/create')}
                />
            </Permission>
        </Stack>
        <div className="ms-depth-4 bg-white section-container">
            <main>
                <section>
                    <ShimmeredDetailsList
                        setKey="items"
                        items={organizations}
                        columns={organizationsColumns}
                        selectionMode={SelectionMode.none}
                        enableShimmer={organizations === null}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="Item details"
                        listProps={shimmeredDetailsListProps}
                        onColumnHeaderClick={(_, column) => onColumnClick(column)}
                    />
                    <Pagination
                        currentPage={(page || 1) > totalPages ? totalPages : (page || 1)}
                        totalPages={totalPages}
                        onChange={page => setQuery({ page })}
                    />
                </section>
                <aside>
                <Stack tokens={{ childrenGap: 30 }}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <span className="filter-header ms-fontSize-12">{t('Search')}</span>
                        <SearchBox placeholder={t("Search")} value={keywords} onSearch={newValue => setQuery({ keywords: (newValue || '') })} onClear={() => setQuery({ keywords: ''})} />
                    </Stack>
                </Stack>
                </aside>
            </main>            
        </div>
    </div>
};

const styles = css`
max-width: 1080px;
main {
    display: flex;
    section {
        width: 100%;
        padding-right: 20px;
    }
    aside {
        min-width: 220px;
        margin-left: auto;
    }

}
.pagination {
    margin: 50px auto 0 auto;
}
.pagination .ms-Button {
    min-width: 40px;
    border: none;
}
.pagination .ms-Button i {
    font-size: 12px;
}

.pagination .ms-Button--primary {
    background-color: #66AC46;
    &:hover {
        background-color: #5c9a3f;
    }
}
`;

