import { css, jsx } from '@emotion/core';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Stack, TextField, PrimaryButton, Dropdown } from '@fluentui/react';

import { createOrganization } from '../../services/organizations.service';

/** @jsx jsx */
export const CreatePage = () => {

    const history = useHistory();
    const { t } = useTranslation();
    const [organization, setOrganization] = useState({});
    const [errors, setErrors] = useState({});

    const onChange = useCallback((e, newValue) => {
        setOrganization({ ...organization, [e.target.name]: newValue });
    }, [setOrganization, organization]);

    const parseErrors = useCallback((errors) => {
        if (!Array.isArray(errors.message)) {
            return;
        }
        const newErrors = {};
        for (let err of errors.message) {
            if (err.property) {
                //translate the messages returned from the backend and join them together
                t((err.errors.map(message => t(message))))
                newErrors[err.property] = err.errors.map(message => t(message)).join(', ');
            }
        }
        setErrors(newErrors);
    }, [setErrors, t]);

    const onSaveOrganization = useCallback(() => {
        createOrganization(organization).then((response) => {
            setErrors({});
            history.push(`/organizations/${response.data.id}`);
        }).catch((error) => {
            if (error.response && error.response.data) {
                parseErrors(error.response.data);
            }
        });
    }, [organization, setErrors, history, parseErrors]);

    return organization && <div css={styles} className="animationIn">
        <div className="left-right">
            <h1>{t('Add organization')}</h1>
            <PrimaryButton
                text={t('Save')}
                onClick={onSaveOrganization}
            />
        </div>
        <div className="ms-depth-4 bg-white section-container">
            <Stack>
                <div className="create-organization">
                    <TextField
                        name="name"
                        label={t('Organization name')}
                        value={organization.name}
                        errorMessage={errors.name}
                        onChange={onChange}
                    />
                </div>
            </Stack>
        </div>
    </div>
};

const styles = css`
.create-organization {
    max-width: 460px;
}
`;