import { Selection } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";

export const useSingleSelection = (initialValue) => {

    const [ val, setValue ] = useState(initialValue);
    
    const selection = useRef(new Selection({
        onSelectionChanged: function() {
            const [ localSelection ] = this.getSelection();
            if(localSelection) {
                setValue(localSelection.key);
            }
        },
    }));

    useEffect(() => {
        selection.current.setKeySelected(initialValue, true, true);
    }, [ initialValue ]);

    return [ val, selection, value => {
        selection.current.setAllSelected(false);
        selection.current.setKeySelected(value, true, true);
        setValue(value);
    }];
};