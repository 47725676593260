import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  disableBodyScroll,
  IconButton,
  PrimaryButton,
} from "@fluentui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const RemoveItemButton = ({ onClick, deleteMessage }) => {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(true);

  return (
    <React.Fragment>
      <IconButton
        iconProps={{ iconName: "trash" }}
        className="clearButton"
        onClick={() => {
          setIsHidden(false);
        }}
      />
      <Dialog
        hidden={isHidden}
        onDismiss={() => setIsHidden(true)}
        dialogContentProps={{
          type: DialogType.normal,
          title: t("Delete"),
          subText: deleteMessage,
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        <DialogFooter>
          <DefaultButton
            text={t("No")}
            onClick={() => {
              setIsHidden(true);
            }}
          />
          <PrimaryButton
            text={t("Yes")}
            onClick={() => {
              onClick();
              setIsHidden(true);
            }}
          />
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  );
};
