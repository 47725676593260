import axios from 'axios';
import { logoutUser, setUser } from '../states/authentication.slice';
import { refreshToken } from '../services/users.service';
import store from '../store';

const api = axios.create({
     withCredentials: true,
     crossDomain: true,
});

api.interceptors.request.use(function (config) {
     const authentication = store.getState().authentication;
     if (authentication.user && authentication.user.accessToken) {
          config.headers.Authorization = `Bearer ${authentication.user.accessToken}`;
     }
     return config;
});

api.interceptors.response.use(function (response) {
     if (response.headers['x-must-refresh-token'] === '1') {
          refreshToken().then(response => {
               store.dispatch(setUser(response.data));
          });
     }
     // return {...response, data: response.data?.data};
     //TODO 全てのルートが新レスポンス構造になった時点でコメントインする
     return response
}, function (error) {
     if (error.response && 401 === error.response.status) {
          store.dispatch(logoutUser());
     } else {
          return Promise.reject(error);
     }
});

export default api;