import { css, jsx } from '@emotion/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link } from 'react-router-dom';
import { ShimmeredDetailsList, SelectionMode, DefaultButton, Stack, MessageBar, MessageBarType } from '@fluentui/react';

import { getUserDetail } from '../../services/users.service';
import { InformationRow } from '../../components/information-row.component';
import { dateFormatter } from '../../utils/date-format';
import { status_options } from '../../config/status-map';

import { EmptyNotification } from '../../components/empty-notification.component';
import { Permission } from '../../components/permission.component'

/** @jsx jsx */
export const ViewPage = () => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [errorBarState, setErrorBarState] = useState({ show: false, text: "" });
    const history = useHistory();

    useEffect(() => {
        if (userId) {
            getUserDetail(userId).then(response => {
                //TODO
                //全てのルートが新レスポンス構造になった時点で、 res.data.itemsに戻す
                setUser(response.data?.data);
            }).catch(() => {
                setErrorBarState(s => ({
                    ...s, 
                    show: true,
                    text: t("Something Went Wrong")
                }))
            });
        }
    }, [userId]);

    useEffect(() => {
        if(errorBarState.show) {
            setTimeout(() => {
                setErrorBarState({ show: false, text: "" })
            }, 2000);
        }
    }, [errorBarState.show])

    const patientsListConfig = () => {
        return [
            {
                key: 'companyName',
                name: t('Org name'),
                fieldName: 'companyName',
                onRender: item => item.organization ? <Link to={`/organizations/${item.organization.id}`}>{item.organization.name}</Link> : 'N/A',
            },
            {
                key: 'fullName',
                name: t('Full name'),
                fieldName: 'fullName',
                onRender: item => <Link to={`/patients/${item.id}`}>{item.lastName} {item.firstName}</Link>,
            },
            {
                key: 'emailAddress',
                name: t('E-mail address'),
                fieldName: 'emailAddress',
                minWidth: 150
            },
            {
                key: 'phoneNumber',
                name: t('Phone number'),
                fieldName: 'phoneNumber',
            },
            {
                key: 'createdAt',
                name: t('Created date'),
                fieldName: 'createdAt',
                onRender: item => dayjs(item.createdAt).format('YYYY年MM月DD日')
            },
        ]
    }

    const patientsListColumns = patientsListConfig();


    return (
        <React.Fragment>
            {errorBarState.show ? 
                <MessageBar
                    messageBarType={MessageBarType.error}
                    onDismiss={() => setErrorBarState({ show: false, text: "" })}
                >
                    {errorBarState.text}
            </MessageBar>
            : null}
            {user && <div css={styles} className="animationIn">
            <div className="left-right">
                <Stack horizontal horizontalAlign="space-between" grow={1}>
                    <h1>{user.emailAddress} <span className="subtitle ms-fontSize-14 ms-fontWeight-regular">{t('User detail')}</span></h1>
                    <Permission only="Patient.Edit">
                        <DefaultButton text={t('Add new patient')} onClick={() => { history.push(`/patients/add/${user.id}`) }} />
                    </Permission>
                </Stack>
            </div>

            <div className="information-section" dir="ltr">
                <div className="ms-depth-4 bg-white section-container">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <InformationRow label={t("Email address")}>{user.emailAddress}</InformationRow>
                            <InformationRow label={t("Created at")}>{dayjs(user.createdAt).format('YYYY年MM月DD日')}</InformationRow>
                            <InformationRow label={t("Email verified")}>{user.emailVerified ? t('Yes') : t('No')}</InformationRow>
                            <InformationRow label={t("Verified Email sent")}>{user.verificationEmailSent ? t('Yes') : t('No')}</InformationRow>
                        </div>
                    </div>
                </div>
            </div>
            <h3>{t('Registered patients')}</h3>
            <div className="ms-depth-4 bg-white section-container">
                {user.patients.length !== 0 ?
                    <ShimmeredDetailsList
                        setKey="items"
                        items={user.patients}
                        columns={patientsListColumns}
                        selectionMode={SelectionMode.none}
                        enableShimmer={user.patients.length === 0}
                        ariaLabelForShimmer="Content is being fetched"
                        ariaLabelForGrid="Item details"
                    />
                : <EmptyNotification>{t('User has no patient yet')}</EmptyNotification>
                }
            </div>
        </div>}
    </React.Fragment>
    )
};

const styles = css`
    max-width: 960px;
    .left-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }



.result-assign-buttons {
    width: 270px;
    display: flex;
}

.result-assign-button {
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    min-width: 80px;
    height: 32px;
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    user-select: none;
    outline: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(138, 136, 134);
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
    &--positive.active {
        color: #fff;
        border-color: #FF5209;
        background-color: #FF5209;
    }
    
    &--negative.active {
        color: #fff;
        border-color: #66AC46;
        background-color: #66AC46;
    }
    &--retest.active {
        color: #fff;
        border-color: #FFB526;
        background-color: #FFB526;
    }
}
`;

export const registrationsColumns = (t, getProductNameByBarcode, ResultLabel) => [
    {
        key: 'name',
        name: t('Created at'),
        fieldName: 'createdtime',
        onRender: item => dateFormatter(item.createdAt),
    },
    {
        key: 'productname',
        name: t('Product name'),
        fieldName: 'productname',
        onRender: item => getProductNameByBarcode(item.barcode)
    },
    {
        key: 'barcode',
        name: t('Barcode'),
        fieldName: 'barcode',
    },
    {
        key: 'status',
        name: t('Status'),
        fieldName: 'status',
        onRender: item => t(status_options[item.status])
    },
    {
        key: 'result',
        name: t('Result'),
        fieldName: 'result',
        onRender: item => ResultLabel(item.result)
    },
];

