export const RESULT_MASTER = {
  COVID: {
    POSITIVE: "POSITIVE",
    NEGATIVE: "NEGATIVE",
    RETEST: "RETEST",
  },
  APOE: {
    HIGHEST_RISK: "ε4/ε4",
    HIGHER_RISK: "ε4/ε3",
    HIGH_RISK: "ε4/ε2",
    MIDDLE_RISK: "ε3/ε3",
    LOWER_RISK: "ε3/ε2",
    LOWEST_RISK: "ε2/ε2",
    RETEST: "RETEST",
  },
  // Add other category of test here
};
