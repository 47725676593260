import { jsx } from "@emotion/core";
import { DefaultButton, SearchBox, SelectionMode, ShimmeredDetailsList } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { getRoles } from "../../services/roles.service";

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

/** @jsx jsx */
export const IndexPage = ({ children }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [roles, setRoles] = useState(null);
  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    const filters = {};

    if (keywords) {
      filters.keywords = keywords;
    }

    getRoles(filters).then((response) => {
      setRoles(response.data);
    });
  }, [keywords, setRoles]);

  return (
    <div>
      <div className="left-right">
        <h1>{t("Roles")}</h1>
        <DefaultButton
          text={t("Add role")}
          onClick={() => history.push(`/roles/create`)}
        />
      </div>
      <SearchBox placeholder={t("Search")} onSearch={(newValue) => setKeywords(newValue)} />
      <ShimmeredDetailsList
        setKey="items"
        items={roles}
        columns={rolesColumns(t)}
        selectionMode={SelectionMode.none}
        enableShimmer={roles === null}
        listProps={shimmeredDetailsListProps}
      />
    </div>
  );
};

export const rolesColumns = (t) => [
  {
    key: "name",
    name: t("Name"),
    fieldName: "name",
    isSorted: true,
    onRender: (item) => <Link to={`/roles/${item.id}`}>{item.name}</Link>,
  },
  {
    key: "permissions",
    name: t("Permissions"),
    fieldName: "permissions",
    onRender: (item) => item.permissions.length,
  },
];
