import { css, jsx } from '@emotion/core';

/** @jsx jsx */
export const Badge = ({ children, type, ...rest }) => <div css={badgeStyles} className={`badge type-${type || 'danger'}`} {...rest}>{ children }</div>;

const badgeStyles = css`
    display: inline-block;
    color: white;
    padding: 3px 8px;
    border-radius: 3px;
    font-weight: bold;
    margin-top: 7px;

    &.type-danger {
        background-color: red;
        color: white;
    }

    &.type-default {
        background-color: #FAF9F8;
        color: #323130;
    }

    &.type-primary {
        background-color: #66AC46;
        color: #ffffff;
    }
`;