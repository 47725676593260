import { css, jsx } from "@emotion/core";
import {
  DatePicker,
  DefaultButton,
  Dropdown,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationAssign } from "../../../components/organization-assign.component";
import { zipcodeLookup } from "../../../services/zipcode.service";
import { PatientClassificationDropdown } from "./patient-classification.component";

const GENDER_OPTIONS = (t) => {
  return [
    { key: "MALE", text: t("Male") },
    { key: "FEMALE", text: t("Female") },
    { key: "OTHER", text: t("Other") },
  ];
};

/** @jsx jsx */
export const PatientEditForm = ({ form, setForm }) => {
  const { t } = useTranslation();
  const [zipCodeError, setZipCodeError] = useState("");
  const [isFetchingAddress, setIsFetchingAddress] = useState(false);
  const handleFieldChange = useCallback((fieldName, newValue) => {
    setForm({ ...form, [fieldName]: newValue });
  }, [form, setForm]);

  const handleGenderChange = useCallback((event, selectedGender) => {
    setForm({ ...form, ["gender"]: selectedGender.key });
  }, [form, setForm]);

  const handleDateChange = useCallback((name, date) => {
    setForm({ ...form, [name]: date });
  }, [form, setForm]);

  const handleZipcodeLookup = useCallback(() => {
    setIsFetchingAddress(true);
    setZipCodeError("");
    zipcodeLookup(form.postalCode).then((res) => {
      if (res.data?.data === null) {
        setZipCodeError(t("not exist zipcode"));
        setIsFetchingAddress(false);
        setForm({
          ...form,
          ["prefectureName"]: "",
          ["city"]: "",
          ["addressOne"]: "",
        });
        return;
      }
      const address = res.data?.data[0];
      setForm({
        ...form,
        ["prefectureName"]: address?.address1,
        ["city"]: address?.address2,
        ["addressOne"]: address?.address3,
        ["postalCode"]: address?.zipcode,
      });
      setZipCodeError("");
      setIsFetchingAddress(false);
    }).catch((e) => {
      setZipCodeError(
        t(
          e.response.data.error.code === "General.Validate"
            ? "Input 7 digit"
            : "unknown API error",
        ),
      );
      setIsFetchingAddress(false);
      setForm({
        ...form,
        ["prefectureName"]: "",
        ["city"]: "",
        ["addressOne"]: "",
      });
    });
  }, [form, setForm]);

  const handleDropdownChange = useCallback((fieldName, selectedKey) => {
    setForm({ ...form, [fieldName]: selectedKey });
  }, [form, setForm]);

  return (
    <div>
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 60 }}>
          <Stack tokens={{ childrenGap: 15 }}>
            <OrganizationAssign
              label="Company name"
              selectedOrganization={form.selectedOrganization}
              setSelectedOrganization={(v) => setForm({ ...form, selectedOrganization: v })}
              preSelectedName={form?.companyName}
            />

            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <TextField
                value={form.lastName}
                label={t("Last name")}
                onChange={(e, value) => handleFieldChange("lastName", value)}
              />
              <TextField
                value={form.firstName}
                label={t("First name")}
                onChange={(e, value) => handleFieldChange("firstName", value)}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <TextField
                value={form.lastNameJa}
                label={t("Last name katakana")}
                onChange={(e, value) => handleFieldChange("lastNameJa", value)}
              />
              <TextField
                value={form.firstNameJa}
                label={t("First name katakana")}
                onChange={(e, value) => handleFieldChange("firstNameJa", value)}
              />
            </Stack>
            <TextField
              value={form.emailAddress}
              label={t("Email address")}
              onChange={(e, value) => handleFieldChange("emailAddress", value)}
            />
            <TextField
              value={form.phoneNumber}
              label={t("Phone number")}
              onChange={(e, value) => handleFieldChange("phoneNumber", value)}
            />
            <Dropdown
              label={t("Gender")}
              defaultSelectedKey={form.gender}
              onChange={handleGenderChange}
              options={GENDER_OPTIONS(t)}
            />
            <PatientClassificationDropdown
              selectedClassification={form.patientClassification}
              onChange={(_, item) => handleDropdownChange("patientClassification", item.key)}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 15 }}>
            <DatePicker
              label={t("Date of birth")}
              placeholder="Date of birth"
              value={form.dateOfBirth}
              onSelectDate={(date) => handleDateChange("dateOfBirth", date)}
            />
            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
              verticalAlign="end"
              horizontalAlign="space-between"
            >
              <TextField
                value={form.postalCode}
                label={t("Zipcode")}
                errorMessage={zipCodeError}
                onChange={(_, value) => handleFieldChange("postalCode", value)}
              />
              <DefaultButton
                text={t("zipcode")}
                onClick={handleZipcodeLookup}
                disabled={isFetchingAddress}
              />
              {isFetchingAddress && <Spinner size={SpinnerSize.small} style={{ marginLeft: 6 }} />}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <TextField
                value={form.prefectureName}
                label={t("Prefecture name")}
                onChange={(e, value) => handleFieldChange("prefectureName", value)}
              />
              <TextField
                value={form.city}
                label={t("City")}
                onChange={(e, value) => handleFieldChange("city", value)}
              />
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <TextField
                value={form.addressOne}
                label={t("Address one")}
                onChange={(e, value) => handleFieldChange("addressOne", value)}
              />
              <TextField
                value={form.addressTwo}
                label={t("Address two")}
                onChange={(e, value) => handleFieldChange("addressTwo", value)}
              />
            </Stack>
            <TextField
              rows={5}
              multiline
              value={form.note}
              label={t("Note")}
              onChange={(e, value) => handleFieldChange("note", value)}
            />
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
