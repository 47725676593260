import React from 'react';
import { css, jsx } from '@emotion/core';

/** @jsx jsx */
export default function Questionnaire({ questionnaire }) {
    
    const QUESTIONS_MAP = {
        0: "あなたのご家族に認知症の診断歴の方はいますか？",
        1: "今までにかかった、もしくは現在治療中の病気はありますか？（複数回答可）",
        2: "喫煙されていますか？",
        3: "週何回飲酒されますか？また、1日あたり何杯飲まれますか？",
        4: "週にどのくらいの頻度で運動（例：ウォーキング、ジョギング、ウェイトトレーニング、水泳、サイクリング、エアロビクスなど）を行っていますか？",
        5: "栄養バランスが悪く、高カロリー・高脂肪・高糖質な食事を頻繁にとっていますか？",
        6: "身長と体重を入れてください",
        7: "1日の平均睡眠時間はどれくらいですか？",
        8: "週にどのくらいの頻度で社会的活動に参加していますか？（仕事、学校、家族や友人との交流、地域の活動など）",
        9: "日常的に新しい情報や知識を得るための活動（読書、学習、ゲーム、ニュースのチェックなど）をどのくらいの頻度で行っていますか？",
        10: "これまでに頭部損傷（転倒して頭を強く打つ）を経験したことがありますか？",
        11: "頭を打った後に意識障害や記憶障害などの症状が出たことはありますか？",
        12: "車の排気ガスや工場のけむりなどによって大気汚染された地域に5年以上住んでいますか？もしくは、過去に住んでいましたか？",
        13: "検査を受けようと思ったきっかけを、差し支えの無い範囲で記載ください。"
    };

    const ANSWERS_MAP = {
        0: getQuestionOneAnswer,
        1: getQuestionTwoAnswer,
        2: getQuestionThreeAnswer,
        3: getQuestionFourAnswer,
        4: getQuestionFiveAnswer,
        5: getQuestionSixAnswer,
        6: getQuestionSevenAnswer,
        7: getQuestionEightAnswer,
        8: getQuestionNineAnswer,
        9: getQuestionTenAnswer,
        10: getQuestionElevenAnswer,
        11: getQuestionEleven_Sub_Answer,
        12: getQuestionTwelveAnswer,
        13: getQuestionThirteenAnswer
    }

    

    const questionnaireObj = JSON.parse(questionnaire);
    const questions = Object.keys(questionnaireObj);
    const answers = Object.values(questionnaireObj);

    return (
        <div css={styles}>
            {questions.map((question, i) => (
                <div className="questionnaire-result" key={i}>
                    <p className="question">{QUESTIONS_MAP[i]}</p>
                    <p className="answer">{ANSWERS_MAP[i](answers[i])}</p>
                </div>
                
            ))}
        </div>
    )
}

const styles = css`
    max-width: 680px;
    .questionnaire-result {
        border-radius: 2px;
        border: 1px solid #ddd;
        color: rgb(96, 94, 92);
        padding: 10px;
        margin-bottom: 18px;
        .question {
            font-size: 16px;
            margin-bottom: 5px;
        }
        .answer {
            font-size: 14px;
            padding-left: 25px;
            margin-top: 5px;
            margin-bottom: 15px;
            font-weight: bold;
        }
    }
`;

const getQuestionOneAnswer = (answer) => {
    if (answer === '') return '';
    if (answer === 'no') return 'no';
    return `Yes`;
}

const getQuestionTwoAnswer = (answer) => {
    if(!answer.length) return 'NO_ANSWER';
    return answer.map((item) => item).join(', ');
}
const getQuestionThreeAnswer = (answer) => {
    return answer
}
const getQuestionFourAnswer = (answer) => {
    return `1週間あたりの飲酒日数: ${answer.drinkAlcoholPerWeek}, 1日あたり何杯: ${answer.drinkAlcoholPerDay}`
}
const getQuestionFiveAnswer = (answer) => {
    return answer
}

const getQuestionSixAnswer = (answer) => {
    return answer
}

const getQuestionSevenAnswer = (answer) => {
    return `身長: ${answer.height} cm, 体重: ${answer.weight} kg, BMI: ${Math.floor(Number(answer.weight)/(Number(answer.height)*Number(answer.height))*1000000)/100}`
}

const getQuestionEightAnswer = (answer) => {
    return answer
}

const getQuestionNineAnswer = (answer) => {
    return answer
}

const getQuestionTenAnswer = (answer) => {
    return answer
}

const getQuestionElevenAnswer = (answer) => {
    return answer
}

const getQuestionEleven_Sub_Answer = (answer) => {
    if(!answer) return 'NO_ANSWER';
    if (answer === 'no') return 'no';
    if (answer === 'yes') return 'yes';
    return "unknown"
}

const getQuestionTwelveAnswer = (answer) => {
    return answer
}

const getQuestionThirteenAnswer = (answer) => {
    return answer
}
