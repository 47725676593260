import { css, jsx } from "@emotion/core";
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { useBoolean } from "@uifabric/react-hooks";
import dayjs from "dayjs";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { InformationRow } from "../../components/information-row.component";
import { Permission } from "../../components/permission.component";
import Questionnaire from "../../components/questionnaire.component";
import {
  downloadResultPdf,
  getRegistration,
  getUploadedFile,
  markContacted,
  sendEmail,
  updateRegistrationResult,
  remove,
  postSwitchTestMethod
} from "../../services/registrations.service";
import { dateFormatter } from "../../utils/date-format";
import { getFullNameKanji } from "../../utils/full-name";
import { PRODUCT_MASTER } from "../../utils/product-master";
import { registrationResultOptionsMaster } from "./registrationResultOptionsMaster";

const shimmeredDetailsListProps = {
  renderedWindowsAhead: 0,
  renderedWindowsBehind: 0,
};

const initUploadFileDisplayModalState = () => ({
  open: false,
  fileName: "",
  originalName: "",
  loading: false,
  base64string: "",
  errorMessage: "",
});

/** @jsx jsx */
export const ViewPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  // const fileUploadRef = useRef();
  const { registrationId } = useParams();
  const [uploadFileDisplayModalState, setUploadFileDisplayModalState] = useState(
    initUploadFileDisplayModalState(),
  );
  const [registration, setRegistration] = useState(null);
  const [upload, setUpload] = useState({
    type: "PROOF_OF_NEGATIVE_RESULT",
  });
  const [
    isConfirmContactedModalOpen,
    { setTrue: showConfirmContactedModal, setFalse: hideConfirmContactedModal },
  ] = useBoolean(false);
  const [
    isConfirmEmailModalOpen,
    { setTrue: showConfirmEmailModal, setFalse: hideConfirmEmailModal },
  ] = useBoolean(false);
  const [resultType, setResultType] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [retestDialogOpen, setRetestDialogOpen] = useState(false);
  // const [fileUploadText, setFileUploadText] = useState(t("Select file"));
  const resultTypeToString = {
    "NEGATIVE": t("negative"),
    "POSITIVE": t("positive"),
    "SUSPECTED_POSITIVE": t("suspected positive"),
    "ε4/ε4": "ε4/ε4",
    "ε2/ε4, ε3/ε4": "ε2/ε4, ε3/ε4",
    "ε4 NOT FOUND": "ε4なし",
    "RETEST": "再検査",
  };
  const [shouldSendEmail, { toggle: toggleShouldSendEmail }] = useBoolean(
    true,
  );
  const [
    isConfirmationModalOpen,
    { setTrue: showConfirmationModal, setFalse: hideConfirmationModal },
  ] = useBoolean(false);

  const hideDeleteButton = registration?.status === "InTesting" || registration?.status === "COMPLETED";

  useEffect(() => {
    if (registrationId) {
      getRegistration(registrationId).then((response) => {
        console.log("response.data", response.data);
        setRegistration(response.data);
      }).catch((error) => console.error("get registration", error));
    }
  }, [registrationId]);

  useEffect(() => {
    if (uploadFileDisplayModalState.fileName && uploadFileDisplayModalState.open) {
      getUploadedFile(uploadFileDisplayModalState.fileName).then((response) => {
        setUploadFileDisplayModalState((s) => ({
          ...s,
          loading: false,
          base64string: response?.data?.data.base64String,
          originalName: response?.data?.data.originalName,
        }));
      }).catch((e) => {
        if (e.response.status === 404) {
          setUploadFileDisplayModalState((s) => ({
            ...s,
            loading: false,
            base64string: "",
            originalName: "",
            errorMessage: "File not found",
          }));
        } else {
          setUploadFileDisplayModalState((s) => ({
            ...s,
            loading: false,
            base64string: "",
            originalName: "",
            errorMessage: t("Something went wrong"),
          }));
        }
      });
    }
  }, [uploadFileDisplayModalState.fileName, uploadFileDisplayModalState.open, t]);

  const onMailResult = useCallback(() => {
    hideConfirmEmailModal();
    sendEmail(registration.id).then((reponse) => {
      setRegistration(reponse.data);
    }).catch((error) => console.error("sendEmail", error));
  }, [registration, setRegistration, hideConfirmEmailModal]);

  const onMarkContacted = useCallback(() => {
    hideConfirmContactedModal();
    markContacted(registration.id).then((response) => {
      setRegistration(response.data);
    }).catch((error) => console.error("markContacted", error));
  }, [registration, setRegistration, hideConfirmContactedModal]);

  const saveResult = useCallback(async (result) => {
    try {
      updateRegistrationResult(registrationId, result, shouldSendEmail)
        .then((response) => {
          setRegistration(response.data);
          showConfirmationModal();
        });
    } catch (error) {
      console.error("updating registration result: ", error);
    }
  }, [showConfirmationModal, setRegistration, shouldSendEmail, registrationId]);

  const deleteRegistration = useCallback(() => {
      if(window.confirm(t("Are you sure you want to delete this registration?"))) {
        remove(registrationId)
        .then(() => history.push("/registrations"))
        .catch(() => {
          alert("エラーが発生しました");
          return false;
        });
      } else {
        return false;
      }
  }, [registrationId, history])

  const switchTestMethod = useCallback(() => {
    if(window.confirm(t("Are you sure you want to switch {{fromTo}} test method?", {
      fromTo: registration.selectedTestMethod === "HAIR" ? "毛根からSWAB" : "SWABから毛根"
    }))) {
      postSwitchTestMethod(registrationId)
      .then(() => history.push("/registrations"))
      .catch(() => {
        alert("エラーが発生しました");
        return false;
      });
    } else {
      return false;
    }
}, [registrationId, history, registration])

  // const onSelectChange = useCallback((e, newValue) => {
  //   console.log("onSelectChange", e, newValue)
  //   // setUpload({ ...upload, [e.target.getAttribute("name")]: newValue.key });
  //   if(window.confirm("Are you sure you want to change the result?")) {
  //     console.log("newValue.key", newValue.key)
  //     return true;
  //   } else {
  //     console.log("cancel");
  //     return false;
  //   }
  // }, [setUpload, upload]);

  // const onSubmitForm = () => {
  //   const data = { ...upload };
  //   data.registrationId = registration.id;
  //   uploadFile(fileUploadRef, data).then((response) => {
  //     setRegistration(response.data);
  //     setFileUploadText(t("Select file"));
  //   });
  // };

  const updateResultFromRetest = (result) => {
    try {
      updateRegistrationResult(registrationId, result, false)
        .then((response) => {
          console.log("response.data yade", response.data);
          const res = { ...response.data.data };
          setRegistration((s) => ({
            ...s,
            result: res.result,
            status: res.status,
            testingDate: res.testingDate,
            fixedPatientInformation: res.fixedPatientInformation,
          }));
          setRetestDialogOpen(false)
        });
    } catch (error) {
      console.error("updating registration result: ", error);
    }
  }

  return registration && (
    <React.Fragment>
      <dialog
        open={retestDialogOpen}
        style={{ zIndex: 2, width: "500px", height: "500px", backgroundColor: "#f0f0f0" }}
      >
        <p>再入力の結果を選択してください</p>
        <div style={{ display: "flex", flexDirection: "column", width: "100%"}}>
          <div style={{ display: "flex", justifyContent: "space-around", width: "100%", marginBottom: "30px"}}>
            <button onClick={() => updateResultFromRetest("ε4/ε4")}>ε4/ε4</button>
            <button onClick={() => updateResultFromRetest("ε4/ε3")}>ε4/ε3</button>
            <button onClick={() => updateResultFromRetest("ε4/ε2")}>ε4/ε2</button>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around", width: "100%", marginBottom: "50px"}}>
            <button onClick={() => updateResultFromRetest("ε3/ε3")}>ε3/ε3</button>
            <button onClick={() => updateResultFromRetest("ε3/ε2")}>ε3/ε2</button>
            <button onClick={() => updateResultFromRetest("ε2/ε2")}>ε2/ε2</button>
          </div>
          <div>
            <button onClick={() => setRetestDialogOpen(false)}>閉じる</button>
          </div>
        </div>
      </dialog>
      <dialog
        open={dialogOpen}
        style={{ zIndex: 2, width: "300px", height: "300px", backgroundColor: "#f0f0f0" }}
      >
        <p>確定しても良いですか？</p>
        <div style={{ display: "flex", justifyContent: "space-between"}}>
        <button
          onClick={() => {
            setResultType(null);
            setDialogOpen(false);
          }}
        >
          キャンセル
        </button>
        <button
          onClick={() => {
            try {
              updateRegistrationResult(registrationId, resultType, shouldSendEmail)
                .then((response) => {
                  console.log("response.data yade", response.data);
                  const res = { ...response.data.data };
                  setRegistration((s) => ({
                    ...s,
                    result: res.result,
                    status: res.status,
                    testingDate: res.testingDate,
                    fixedPatientInformation: res.fixedPatientInformation,
                  }));
                  setDialogOpen(false);
                });
            } catch (error) {
              console.error("updating registration result: ", error);
            }
          }}
        >
          確定
        </button>
        </div>
      </dialog>
      <div
        style={{
          display: dialogOpen ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <div css={styles} className="animationIn">
        <div className="left-right">
          <h1>
            {getFullNameKanji(registration.patient)}
            <span className="subtitle ms-fontSize-14 ms-fontWeight-regular">
              {t("Registration detail")}
            </span>
          </h1>
          {hideDeleteButton ? null :
          <Permission only="Registration.Edit">
            <DefaultButton
            text={t("Operation")}
            split
            menuProps={{
              "items": [{
                key: "remove",
                text: t("Remove registration"),
                iconProps: { iconName: "Delete" },
                onClick: () => deleteRegistration(),
              },
              {
                key: "editRegistrationType",
                text: t("SwitchTestMethod"),
                disabled: registration.status !== "PATIENT_COMPLETED",
                iconProps: { iconName: "Edit" },
                onClick: () => switchTestMethod(),
              }],
            }}
          />
          </Permission>
          }
        </div>
        <div className="ms-Grid information-section" dir="ltr">
          <div className="ms-Grid-row ms-depth-4 bg-white section-container">
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <h2>Registration</h2>
              <InformationRow label={t("Testing number")}>
                {registration.barcode}
              </InformationRow>
              <InformationRow label={t("Shipping barcode")}>
                {registration.shippingBarcode}
              </InformationRow>
              <InformationRow label={t("Requires oroof")}>
                {registration.requiresProof ? t("Yes") : t("No")}
              </InformationRow>
              <InformationRow label={"検体"}>
                {registration.selectedTestMethod === "HAIR" ? "毛根" : "スワブ"}
              </InformationRow>
              <InformationRow label={t("Status")}>
                {registration.status}
              </InformationRow>
              {registration?.testingDate && (
                <InformationRow label={t("Finished date")}>
                  {dayjs(registration.testingDate).format(
                    "YYYY年MM月DD日",
                  )}
                </InformationRow>
              )}
              {!registration.result && (
                <InformationRow label={t("Result")}>
                  <Stack
                    tokens={{ childrenGap: 20 }}
                    horizontalAlign="start"
                  >
                    {/* <ChoiceGroup
                    options={registrationResultOptionsMaster(
                      t,
                    )[
                      findOptionsFromProductId(
                        registration.inventory
                          .productId,
                      )
                    ]}
                    className="update-results"
                    selectedKey={resultType}
                    onChange={(e, item) => setResultType(item.key)}
                  /> */}
                    <Dropdown
                      className="dropdown-upload"
                      name="type"
                      // label={"kokoyade"}
                      selectedKey={upload.type}
                      // errorMessage={errors.defaultLanguage}
                      options={registrationResultOptionsMaster(
                        t,
                      )["APOE"]}
                      // onChange={onSelectChange}
                      onChange={(e, item) => {
                        if (dialogOpen) {
                          // このスコープが無いと、dialogでconfirmボタンを押した時に、onChangeが呼ばれてしまって、自動的にε4/ε4が選択された状態になる
                          return;
                        }
                        console.log("is this called", item.key);
                        // if(window.confirm("Are you sure you want to change the result?")) {
                        //   console.log("newValue.key", item.key)
                        //   return;
                        // } else {
                        //   console.log("cancel");
                        //   return;
                        // }
                        setResultType(item.key);
                        setDialogOpen(true);
                      }}
                    />
                    {resultType !== null && (
                      <Fragment>
                        {resultType === "NEGATIVE"
                          && registration.inventory
                              .productId
                            !== PRODUCT_MASTER.PCR_APOE
                          && (
                            <Checkbox
                              label={t(
                                "E-mail customer that test results are ready.",
                              )}
                              checked={shouldSendEmail}
                              onChange={toggleShouldSendEmail}
                            />
                          )}
                        <PrimaryButton
                          text={registration.inventory
                              .productId
                              === PRODUCT_MASTER
                                .PCR_APOE
                            ? t("Confirm result")
                            : t(
                              "Confirm {{result}} result",
                              {
                                result: resultTypeToString[
                                  resultType
                                ],
                              },
                            )}
                          onClick={() => saveResult(resultType)}
                        />
                      </Fragment>
                    )}
                  </Stack>
                </InformationRow>
              )}
              {registration.result && (
                <Fragment>
                  <InformationRow label={t("Result")}>
                    {/* {resultTypeToString[registration.result]} */}
                    {registration.result}
                  </InformationRow>

                  {/** -- For COVID PCR test start -- */}
                  {registration.result === "POSITIVE" && (
                    <InformationRow
                      label={t("Customer has been contacted")}
                    >
                      <Stack
                        tokens={{ childrenGap: 20 }}
                        horizontalAlign="start"
                      >
                        <span>
                          {registration.isContacted
                            ? t("Yes")
                            : t("No")}
                        </span>
                        <Permission only="Registration.Edit">
                          {!registration.isContacted && (
                            <PrimaryButton
                              text={t(
                                "Customer has been contacted",
                              )}
                              onClick={showConfirmContactedModal}
                            />
                          )}
                          <PrimaryButton
                            text={t("Download result")}
                            onClick={() =>
                              downloadResultPdf(
                                registration.id,
                              )}
                          />
                        </Permission>
                      </Stack>
                    </InformationRow>
                  )}

                  {registration.result === "NEGATIVE" && (
                    <InformationRow
                      label={t("Results sent to customer")}
                    >
                      <Stack
                        tokens={{ childrenGap: 20 }}
                        horizontalAlign="start"
                      >
                        <span>
                          {registration.isEmailed
                            ? t("Yes")
                            : t("No")}
                        </span>
                        <Permission only="Registration.Edit">
                          <PrimaryButton
                            text={registration.isEmailed
                              ? t("Resend result")
                              : t("Send result")}
                            onClick={showConfirmEmailModal}
                          />
                          <PrimaryButton
                            text={t("Download result")}
                            onClick={() =>
                              downloadResultPdf(
                                registration.id,
                              )}
                          />
                        </Permission>
                      </Stack>
                    </InformationRow>
                  )}
                  {/** -- For COVID PCR test end -- */}

                  {/** -- For APOE test start -- */}
                  {(registration.result === "ε4/ε4"
                    || registration.result === "ε4/ε2"
                    || registration.result === "ε4/ε3"
                    || registration.result === "ε3/ε3"
                    || registration.result === "ε3/ε2"
                    || registration.result === "ε2/ε2") && (
                    <InformationRow
                      label={t("Results sent to customer")}
                    >
                      <Stack
                        tokens={{ childrenGap: 20 }}
                        horizontalAlign="start"
                      >
                        <span>
                          {registration.isEmailed
                            ? t("Yes")
                            : t("No")}
                        </span>
                      </Stack>
                    </InformationRow>
                  )}
                  {registration.result === "RETEST" && (
                      <PrimaryButton
                      text={"再検査入力"}
                      onClick={() => setRetestDialogOpen(true)}
                    />
                    )}
                  {/** -- For APOE test end -- */}
                </Fragment>
              )}
            </div>
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <h2>Patient</h2>
              <InformationRow label={t("Name")}>
                {getFullNameKanji(registration.patient)}
              </InformationRow>
              <InformationRow label={t("Address one")}>
                {registration.patient.addressOne}
              </InformationRow>
              <InformationRow label={t("Address two")}>
                {registration.patient.addressTwo}
              </InformationRow>
              <InformationRow label={t("Prefecture name")}>
                {registration.patient.prefectureName}
              </InformationRow>
              <InformationRow label={t("City")}>
                {registration.patient.city}
              </InformationRow>
              <InformationRow label={t("Zip")}>
                {registration.patient.postalCode}
              </InformationRow>
              <InformationRow label={t("E-mail address")}>
                {registration.patient.emailAddress}
              </InformationRow>
            </div>
          </div>

          {
            /* <div className="ms-Grid-row ms-depth-4 bg-white section-container">
          <h3>{t("Uploads")}</h3>
          <ShimmeredDetailsList
            setKey="items"
            items={registration.uploads}
            columns={uploadColumns(t, setUploadFileDisplayModalState)}
            selectionMode={SelectionMode.none}
            enableShimmer={registration === null}
            listProps={shimmeredDetailsListProps}
          />
        </div> */
          }

          {
            /* <Permission only="Registration.Edit">
          <div className="ms-Grid-row ms-depth-4 bg-white section-container">
            <h3>{t("Upload file")}</h3>
            <Stack
              horizontal
              verticalAlign="end"
              tokens={{ childrenGap: 20 }}
            >
              <DefaultButton
                className="upload-file-button"
                type="button"
              >
                {fileUploadText}
                <input
                  type="file"
                  ref={fileUploadRef}
                  onChange={() => {
                    if (fileUploadRef.current.files.length > 0) {
                      setFileUploadText(t("File selected"));
                    } else {
                      setFileUploadText(t("Select file"));
                    }
                  }}
                />
              </DefaultButton>

              <Dropdown
                className="dropdown-upload"
                name="type"
                label={t("Default language")}
                selectedKey={upload.type}
                // errorMessage={errors.defaultLanguage}
                options={[
                  {
                    key: "PROOF_OF_NEGATIVE_RESULT",
                    text: t("Proof of negative result"),
                  },
                  {
                    key: "OTHER",
                    text: t("other"),
                  },
                ]}
                onChange={onSelectChange}
              />
              <PrimaryButton onClick={onSubmitForm}>
                Upload
              </PrimaryButton>
            </Stack>
          </div>
        </Permission> */
          }
          {registration.questionnaireAnswers !== "" && (
            <div className="ms-Grid-row ms-depth-4 bg-white section-container">
              <h3>{t("Questionnaire")}</h3>
              <Questionnaire
                questionnaire={registration.questionnaireAnswers}
              />
            </div>
          )}
        </div>

        <Modal
          isOpen={isConfirmContactedModalOpen}
          onDismiss={hideConfirmContactedModal}
          containerClassName={modalStyles.container}
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <div className={modalStyles.header}>
              <h1 className={modalStyles.title}>
                {t("Confirm contacting {{result}} result", {
                  result: resultTypeToString[registration.result],
                })}
              </h1>
            </div>
            <p className={modalStyles.paragraph}>
              {t(
                "Are you sure you contacted about this {{result}} result?",
                { result: resultTypeToString[registration.result] },
              )}
            </p>
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 40 }}
            >
              <DefaultButton
                text={t("Cancel")}
                onClick={hideConfirmContactedModal}
              />
              <PrimaryButton
                text={t("Confirm")}
                onClick={onMarkContacted}
              />
            </Stack>
          </Stack>
        </Modal>
        <Modal
          isOpen={isConfirmEmailModalOpen}
          onDismiss={hideConfirmEmailModal}
          containerClassName={modalStyles.container}
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <div className={modalStyles.header}>
              <h1 className={modalStyles.title}>
                {t("Confirm mailing {{result}} result", {
                  result: resultTypeToString[registration.result],
                })}
              </h1>
            </div>
            <p className={modalStyles.paragraph}>
              {t(
                "Are you sure you want to mail this {{result}} result?",
                { result: resultTypeToString[registration.result] },
              )}
            </p>
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 40 }}
            >
              <DefaultButton
                text={t("Cancel")}
                onClick={hideConfirmEmailModal}
              />
              <PrimaryButton
                text={t("Confirm")}
                onClick={onMailResult}
              />
            </Stack>
          </Stack>
        </Modal>
        <Modal
          isOpen={isConfirmationModalOpen}
          onDismiss={hideConfirmationModal}
          containerClassName={modalStyles.container}
        >
          <Stack tokens={{ childrenGap: 20 }}>
            <div className={modalStyles.header}>
              <h1 className={modalStyles.title}>
                {t("{{result}} Result Confirmed", {
                  result: resultTypeToString[registration.result],
                })}
              </h1>
            </div>
            <p className={modalStyles.paragraph}>
              {registration.isEmailed
                ? t("Customer has been e-mailed.")
                : t("Customer has not yet been e-mailed.")}
            </p>
            <Stack
              horizontal
              horizontalAlign="end"
              tokens={{ childrenGap: 40 }}
            >
              <PrimaryButton
                text={t("Confirm")}
                onClick={hideConfirmationModal}
              />
            </Stack>
          </Stack>
        </Modal>
        <Modal
          isOpen={uploadFileDisplayModalState.open}
          onDismiss={() => setUploadFileDisplayModalState(initUploadFileDisplayModalState())}
        >
          <img
            width={"700px"}
            height={"500px"}
            alt=""
            src={uploadFileDisplayModalState.base64string}
          />
          {uploadFileDisplayModalState.errorMessage && (
            <p style={{ color: "red", fontSize: "20px", fontWeight: "700" }}>
              {uploadFileDisplayModalState.errorMessage}
            </p>
          )}
          <Stack style={{ marginTop: 12 }}>
            <PrimaryButton
              text={t("Close")}
              onClick={() => setUploadFileDisplayModalState(initUploadFileDisplayModalState())}
            />
          </Stack>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const styles = css`
max-width: 1080px;
.left-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hide-radio .ms-ChoiceField-field {
    &::before, &::after {
        display: none !important;
    }
}

.upload-file-button {
  position: relative;
}
.upload-file-button input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropdown-upload {
    width: 250px;
}

.radio-negative ~ label,
.radio-positive ~ label,
.radio-suspected-positive ~label,
.radio-re-test ~label {
    font-weight: bold;
}

.radio-negative ~ label {
    color: #66AC46;
}
.radio-negative ~ label::before,
.radio-negative ~ label.is-checked::before,
.radio-negative ~ label.is-checked::after {
    border-color: #66AC46;
}

.radio-positive ~ label {
    color: #FF5209;
}

.radio-positive ~ label::before,
.radio-positive ~ label.is-checked::before,
.radio-positive ~ label.is-checked::after {
    border-color: #FF5209;
}

.radio-suspected-positive ~ label {
    color: #FFB526;
}

.radio-suspected-positive ~ label::before,
.radio-suspected-positive ~ label.is-checked::before,
.radio-suspected-positive ~ label.is-checked::after {
    border-color: #FFB526;
}

.radio-re-test ~ label {
    color: #B0D3F8;
}

.radio-re-test ~ label::before,
.radio-re-test ~ label.is-checked::before,
.radio-re-test ~ label.is-checked::after {
    border-color: #B0D3F8;
}
`;

const modalStyles = mergeStyleSets({
  container: {
    padding: 20,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    marginBottom: 0,
    textTransform: "capitalize",
  },
  paragraph: {
    margin: 0,
  },
});
const uploadColumns = (t, setUploadFileDisplayModalState) => [
  {
    key: "originalName",
    name: t("File Name"),
    fieldName: "originalName",
    onRender: (item) => (
      <DefaultButton
        onClick={() =>
          setUploadFileDisplayModalState((s) => ({
            ...s,
            open: true,
            fileName: item.fileName,
            loading: true,
          }))}
      >
        {item.originalName}
      </DefaultButton>
    ),
  },
  {
    key: "type",
    name: t("Type"),
    fieldName: "type",
  },
  {
    key: "mimetype",
    name: t("File Type"),
    fieldName: "mimetype",
  },
  {
    key: "createdAt",
    name: t("Upload Date"),
    fieldName: "createdAt",
    onRender: (item) => dateFormatter(item.createdAt),
  },
];
