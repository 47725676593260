import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import { QueryParamProvider } from 'use-query-params';

import { MainLayout } from './pages/layouts/main.layout';
import { LoginPage } from './pages/authentication/login.page';
import { IndexPage as PatientIndexPage } from './pages/patients/index.page';
import { ViewPage as PatientViewPage } from './pages/patients/view.page';
import { EditPage as PatientEditPage } from './pages/patients/edit.page';
import { AddPage as PatientAddPage } from './pages/patients/add.page';
import { IndexPage as UserIndexPage } from './pages/users/index.page';
import { ViewPage as UserViewPage } from './pages/users/view.page';
import { IndexPage as RegistrationIndexPage } from './pages/registrations/index.page';
import { ViewPage as RegistrationViewPage } from './pages/registrations/view.page';
import { IndexPage as RoleIndexPage } from './pages/roles/index.page';
import { ViewPage as RoleViewPage } from './pages/roles/view.page';
import { EditPage as RoleEditPage } from './pages/roles/edit.page';
import { CreatePage as RoleCreatePage } from './pages/roles/create.page';
import { IndexPage as AdministratorIndexPage } from './pages/administrators/index.page';
import { ViewPage as AdministratorViewPage } from './pages/administrators/view.page';
import { EditPage as AdministratorEditPage } from './pages/administrators/edit.page';
import { CreatePage as AdministratorCreatePage } from './pages/administrators/create.page';
import { IndexPage as ReceivalScanPage } from './pages/scan/receival/index.page';
import { IndexPage as ShipmentIndexPage } from './pages/scan/shipment/index.page';
import { IndexPage as TestingCyclePage } from './pages/scan/testingcycle/index.page';
import { ResultPage as CycleResultPage } from './pages/scan/cycles/cycle-result.page';
import { IndexPage as CyclesIndexPage } from './pages/scan/cycles/index.page';
import { NewCyclePage } from './pages/scan/cycles/new-cycle.page';
import { IndexPage as InventoryIndexPage } from './pages/inventory/index.page';
import { ShippedBarcode } from './pages/inventory/shipped-barcode.page';
import { IndexPage as SettingsIndexPage } from './pages/settings/index.page';
import { IndexPage as CompletedTestsIndexPage } from './pages/labtests/labtests.page';
import { IndexPage as OrganizationsIndexPage } from './pages/organizations/index.page';
import { ViewPage as OrganizationsViewPage } from './pages/organizations/view.page';
import { CreatePage as OrganizationCreatePage } from './pages/organizations/create.page';

import { ResetPasswordPage } from './pages/authentication/reset-password.page';

import { lightTheme } from './themes';

initializeIcons();

export const App = () => {
    return (
        <ThemeProvider theme={lightTheme}>
            <Suspense fallback="loading">
                <Router basename={'/admin'}>
                    <QueryParamProvider ReactRouterRoute={Route}>
                    <Switch>
                        <Route path="/" exact={true}><LoginPage /></Route>
                        <Route path="/reset-password/:token" exact={true}><ResetPasswordPage /></Route>
                        <Route path="/patients" exact={true}>
                            <MainLayout>
                                <PatientIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/patients/edit/:patientId" exact={true}>
                            <MainLayout>
                                <PatientEditPage />
                            </MainLayout>
                        </Route>
                        <Route path="/patients/add/:userId" exact={true}>
                            <MainLayout>
                                <PatientAddPage />
                            </MainLayout>
                        </Route>
                        <Route path="/patients/:patientId" exact={true}>
                            <MainLayout>
                                <PatientViewPage />
                            </MainLayout>
                        </Route>
                        <Route path="/registrations" exact={true}>
                            <MainLayout>
                                <RegistrationIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/registrations/:registrationId" exact={true}>
                            <MainLayout>
                                <RegistrationViewPage />
                            </MainLayout>
                        </Route>
                        {/* <Route path="/bulk-scanning/receive" exact={true}>
                            <MainLayout disableScanner={true}>
                                <RegistrationIndexPage />
                            </MainLayout>
                        </Route> */}
                        <Route path="/roles" exact={true}>
                            <MainLayout>
                                <RoleIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/roles/create" exact={true}>
                            <MainLayout>
                                <RoleCreatePage />
                            </MainLayout>
                        </Route>
                        <Route path="/roles/edit/:roleId" exact={true}>
                            <MainLayout>
                                <RoleEditPage />
                            </MainLayout>
                        </Route>
                        <Route path="/roles/:roleId" exact={true}>
                            <MainLayout>
                                <RoleViewPage />
                            </MainLayout>
                        </Route>
                        <Route path="/administrators" exact={true}>
                            <MainLayout>
                                <AdministratorIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/administrators/create" exact={true}>
                            <MainLayout>
                                <AdministratorCreatePage />
                            </MainLayout>
                        </Route>
                        <Route path="/administrators/edit/:administratorId" exact={true}>
                            <MainLayout>
                                <AdministratorEditPage />
                            </MainLayout>
                        </Route>
                        <Route path="/administrators/:administratorId" exact={true}>
                            <MainLayout>
                                <AdministratorViewPage />
                            </MainLayout>
                        </Route>
                        <Route path="/scan/receival" exact={true}>
                            <MainLayout disableScanner={true}>
                                <ReceivalScanPage />
                            </MainLayout>
                        </Route>
                        <Route path="/scan/shipment" exact={true}>
                            <MainLayout disableScanner={true}>
                                <ShipmentIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/scan/cycles" exact={true}>
                            <MainLayout disableScanner={true}>
                                <CyclesIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/scan/cycles/:cycleId" exact={true}>
                            <MainLayout disableScanner={true}>
                                <NewCyclePage />
                            </MainLayout>
                        </Route>
                        <Route path="/scan/testingcycle/:cycleId" exact={true}>
                            <MainLayout disableScanner={true}>
                                <TestingCyclePage />
                            </MainLayout>
                        </Route>
                        <Route path="/scan/cycleresult/:cycleId" exact={true}>
                            <MainLayout disableScanner={true}>
                                <CycleResultPage />
                            </MainLayout>
                        </Route>
                        <Route path="/inventory" exact={true}>
                            <MainLayout>
                                <InventoryIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/shipped-barcode" exact={true}>
                            <MainLayout>
                                <ShippedBarcode />
                            </MainLayout>
                        </Route>
                        <Route path="/settings" exact={true}>
                            <MainLayout>
                                <SettingsIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/completedtests" exact={true}>
                            <MainLayout>
                                <CompletedTestsIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/organizations" exact={true}>
                            <MainLayout>
                                <OrganizationsIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/organizations/create" exact={true}>
                            <MainLayout>
                                <OrganizationCreatePage />
                            </MainLayout>
                        </Route>
                        <Route path="/organizations/:organizationId" exact={true}>
                            <MainLayout>
                                <OrganizationsViewPage />
                            </MainLayout>
                        </Route>
                        <Route path="/users/" exact={true}>
                            <MainLayout>
                                <UserIndexPage />
                            </MainLayout>
                        </Route>
                        <Route path="/users/:userId" exact={true}>
                            <MainLayout>
                                <UserViewPage />
                            </MainLayout>
                        </Route>
                    </Switch>
                    </QueryParamProvider>
                </Router>
            </Suspense>
        </ThemeProvider>
    );
};
