import { css, jsx } from "@emotion/core";
import { DefaultButton, SelectionMode, ShimmeredDetailsList, Stack } from "@fluentui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { createNewCycle, getAllCycles, removeCycle } from "../../../services/scanning.service";
import { RemoveItemButton } from "../../../components/delete-button.component";
import { EmptyNotification } from "../../../components/empty-notification.component";
import { Permission } from "../../../components/permission.component";
import { useZusStore } from "../../../zusStore";

/** @jsx jsx */
export const IndexPage = () => {
  const { t } = useTranslation();
  const [cycles, setCycles] = useState([]);
  const setIsHidden = useZusStore((state) => state.setIsHiddenDeleteModal);
  let history = useHistory();

  const loadCycles = () => {
    getAllCycles()
      .then((response) => setCycles(response.data))
      .catch((error) => console.error("fetching all cycles", error));
  };

  const handleNewCycleClick = () => {
    createNewCycle()
      .then((response) => {
        const cycle = response.data;
        history.push(`/scan/cycles/${cycle.id}`);
      })
      .catch((error) => console.error("Add new cycle", error));
  };

  const handleRemoveCycle = (id) => {
    removeCycle(id)
      .then(() => {
        loadCycles();
        setIsHidden(true);
      })
      .catch((error) => console.error("remove cycle", error));
  };

  useEffect(() => {
    loadCycles();
  }, []);

  return (
    <div css={styles} className="animationIn">
      <Stack
        tokens={{ childrenGap: 40 }}
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
      >
        <h1>{t("All cycles")}</h1>
        <Permission only="Cycle.Edit">
          <DefaultButton text={t("New cycle")} onClick={handleNewCycleClick} />
        </Permission>
      </Stack>
      <div className="ms-depth-4 bg-white section-container">
        <Stack tokens={{ childrenGap: 40 }}>
          {cycles.length === 0 && (
            <EmptyNotification>
              Current cycles list is empty. Click New Cycle to begin.
            </EmptyNotification>
          )}
          {cycles.length > 0 && (
            <ShimmeredDetailsList
              setKey="items"
              items={cycles}
              columns={cyclesColumns(t, dayjs, handleRemoveCycle)}
              selectionMode={SelectionMode.none}
              enableShimmer={cycles.length === 0}
              ariaLabelForShimmer="cycles is being fetched"
              ariaLabelForGrid="Cycle detail"
            />
          )}
        </Stack>
      </div>
    </div>
  );
};

export const cyclesColumns = (t, dayjs, handleRemoveCycle) => {
  // scan/testingcycle
  const getCycleLink = (status) =>
    status === "NEW_CREATED"
      ? `/scan/cycles/`
      : status === "INTESTING"
      ? `/scan/testingcycle/`
      : `/scan/cycleresult/`;

  return [
    {
      key: "cyclenumber",
      name: t("Cycle #"),
      fieldName: "cyclenumber",
      className: "cycle-row",
      onRender: (item) => <Link to={`${getCycleLink(item.status)}${item.id}`}>{item.id}</Link>,
    },
    {
      key: "status",
      name: t("Current status"),
      fieldName: "status",
      className: "cycle-row",
      minWidth: 220,
      maxWidth: 220,
      onRender: (item) => (
        <Link to={`${getCycleLink(item.status)}${item.id}`}>{t(item.status)}</Link>
      ),
    },
    {
      key: "createdat",
      name: t("Created at"),
      fieldName: "createdat",
      className: "cycle-row",
      onRender: (item) => (
        <Link to={`${getCycleLink(item.status)}${item.id}`}>
          {dayjs(item.createdAt).format("YYYY年MM月DD日")}
        </Link>
      ),
    },
    {
      key: "lastupdate",
      name: t("Last update"),
      fieldName: "lastupdate",
      className: "cycle-row",
      minWidth: 160,
      maxWidth: 160,
      onRender: (item) => (
        <Link to={`${getCycleLink(item.status)}${item.id}`}>
          {dayjs(item.modifiedAt).format("YYYY年MM月DD日 HH:MM")}
        </Link>
      ),
    },
    {
      key: "operation",
      name: t("Operation"),
      fieldName: "operation",
      className: "cycle-row cycle-row--button",
      onRender: (item) => (
        <RemoveItemButton
          deleteMessage={t("Are you sure you want to delete this cycle?")}
          onClick={() => handleRemoveCycle(item.id)}
        />
      ),
    },
  ];
};

const styles = css`
    max-width: 960px;
    .cycle-row {
        display: inline-flex;
        align-items: center;
    }
    .cycle-row--button {
        padding-left: 25px;
    }
`;
