import { css, jsx } from "@emotion/core";
import { Nav } from "@fluentui/react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AdminSettingsMenu, SideMenu } from "./sidenav-menu-items";

const navLinkGroups = (location, permissions) => {
  if (
    location.pathname === "/settings" || location.pathname.includes("/administrators")
    || location.pathname.includes("/roles")
  ) {
    return AdminSettingsMenu();
  } else {
    return SideMenu(permissions);
  }
};

/** @jsx jsx */
export const SideNav = () => {
  const history = useHistory();
  const location = useLocation();
  const { permissions } = useSelector((s) => s.authentication);

  return (
    permissions && (
      <Nav
        css={navStyle(permissions)}
        onLinkClick={(e, item) => {
          e.preventDefault();
          history.push(item.url);
        }}
        groups={navLinkGroups(location, permissions)}
      />
    )
  );
};

const navStyle = (permissions) => {
  if (permissions.includes("*") || permissions.includes("Administrator.View")) {
    return css`
        height: 100%;
        .ms-Nav-group {
            height: 100%;
            .ms-Nav-groupContent {
                height: 100%;
                margin-bottom: 0;
                > ul.ms-Nav-navItems {
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                    > li.ms-Nav-navItem:last-of-type {
                        margin-top: auto;
                    }
        
                }
            }
        }
        `;
  }
};
