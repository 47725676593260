import { css, jsx } from "@emotion/core";
import { useTranslation } from "react-i18next";
import { EmptyNotification } from "./empty-notification.component";

const columnStyle = (c) => ({
  minWidth: c.minWidth,
  maxWidth: c.maxWidth,
});

/** @jsx jsx */
export const PlainCycleList = ({ cycleList, cycleListColumns }) => {
  const { t } = useTranslation();
  return (
    cycleList && cycleList.length !== 0
      ? (
        <div css={styles}>
          <table width="100%">
            <thead>
              <tr>
                {cycleListColumns.map((c, i) => (
                  <th key={`cycleListHead_${i}`} style={columnStyle(c)}>{c.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {cycleList.map((item, z) => {
                return (
                  <tr key={`cycleListRowBody_${z}`}>
                    {cycleListColumns.map((c, i) => (
                      <td key={`cycleListBody_${i}_${z}`} style={columnStyle(c)}>
                        {c.onRender(item)}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )
      : (
        <EmptyNotification>
          {t("Current cycle list is empty, scan a barcode to begin")}
        </EmptyNotification>
      )
  );
};

const styles = css`
    background: white;
    padding: 20px;
    table {
        td, th {
            padding: 5px;
            text-align: left;
            border-bottom: #F0EFEE 1px solid;
        }
        tr {
        }
    }
`;
