import { jsx } from "@emotion/core";
import { DefaultButton, Stack } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { InformationRow } from "../../components/information-row.component";
import { Permission } from "../../components/permission.component";
import { getAdministrator } from "../../services/users.service";

/** @jsx jsx */
export const ViewPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { administratorId } = useParams();
  const [administrator, setAdministrator] = useState(null);

  useEffect(() => {
    if (administratorId) {
      getAdministrator(administratorId).then((response) => {
        setAdministrator(response.data);
      });
    }
  }, [administratorId]);

  return administrator && (
    <div>
      <div className="left-right">
        <h1>{administrator.firstName} {administrator.lastName}</h1>
        <Permission only={["Administrator.Edit", "Role.View"]}>
          <DefaultButton
            text={t("Edit Administrator")}
            onClick={() => history.push(`/administrators/edit/${administratorId}`)}
          />
        </Permission>
      </div>
      <Stack>
        <InformationRow label={t("First name")}>{administrator.firstName}</InformationRow>
        <InformationRow label={t("Last name")}>{administrator.lastName}</InformationRow>
        <InformationRow label={t("Email address")}>{administrator.emailAddress}</InformationRow>
        <InformationRow label={t("Default language")}>
          {administrator.defaultLanguage}
        </InformationRow>
      </Stack>

      <h2>{t("Role")}</h2>
      <Stack>
        <InformationRow label={t("Id")}>
          {administrator.role && administrator.role.id}
        </InformationRow>
        <InformationRow label={t("Name")}>
          {administrator.role && administrator.role.name}
        </InformationRow>
      </Stack>
    </div>
  );
};

export const registrationsColumns = (t) => [
  {
    key: "name",
    name: t("Created Date & Time"),
    fieldName: "name",
    isSorted: true,
    onRender: (item) => item.createdAt,
  },
  {
    key: "barcode",
    name: t("Barcode"),
    fieldName: "barcode",
  },
  {
    key: "status",
    name: t("Status"),
    fieldName: "status",
    onRender: (item) => t(item.status),
  },
];
