import axios from '../config/axios';
import qs from 'query-string';
import fileDownload from 'js-file-download';

const url = process.env.REACT_APP_BASE_API;

export const getScannedItems = (type) => {
    return axios.get(`${url}/scanning/all?${qs.stringify({ type })}`);
};

export const addScannedItem = (barcode, type) => {
    return axios.post(`${url}/scanning/add`, { barcode, type });
};

export const changeBarcodeStatus = (scanId, newStatus) => {
    return axios.post(`${url}/scanning/change-status`, { scanId, newStatus });
};

export const processScannedItems = ( type ) => {
    return axios.post(`${url}/scanning/process`, { type });
};

export const removeItem = (id) => {
    return axios.delete(`${url}/scanning/remove-item/${id}`);
}


export const updateItem = ({ barcode, isPriority, needsProof}) => {
    return axios.post(`${url}/scanning/update-item/`, { barcode, isPriority, needsProof});
}

export const removeItemByBarcode = (barcode) => {
    return axios.delete(`${url}/scanning/remove-item-by-barcode/${barcode}`);
}

export const assignOrganizationName = (type, organizationId) => {
    return axios.post(`${url}/scanning/assign-organization-name`, { type, organizationId });
}

export const createNewCycle = () => {
    return axios.post(`${url}/scanning/cycle/new-cycle`);
}

export const getAllCycles = () => {
    return axios.get(`${url}/scanning/all-cycles`);
}

export const getCycleById = (cycleid) => {
    return axios.get(`${url}/scanning/cycle/cycledetail/${cycleid}`);
}

export const addItemToCycle = (cycleid, barcode) => {
    return axios.post(`${url}/scanning/cycle/add-item`, { cycleid, barcode });
}

export const removeItemFromCycleByPosition = (cycleid, position) => {
    return axios.delete(`${url}/scanning/cycle/remove-cycle-item/${cycleid}/${position}`);
}


export const sendCycleToTest = (cycleid) => {
    return axios.post(`${url}/scanning/v2/cycle/totest`, { cycleid });
}

export const confirmResult = (rack) => {
    return axios.post(`${url}/scanning/v2/cycle/confirm-result`, { rack });
}

export const sendEmailToRegistration = (confirmEmail) => {
    return axios.post(`${url}/scanning/cycle/confirm-email`, { confirmEmail });
}

export const getCycleResultById = (cycleid) => {
    return axios.get(`${url}/scanning/cycle/cycleresult/${cycleid}`);
}

export const removeCycle = (cycleid) => {
    return axios.delete(`${url}/scanning/cycle/delete/${cycleid}`);
}

export const getAllCyclesResults = (options) => {
    return axios.get(`${url}/scanning/all-cycles-results/?${qs.stringify(options)}`);
}

export const addPatientInformation = (patientInformation) => {
    return axios.post(`${url}/scanning/enter-patient-information`, patientInformation);
}

export const updateCycleItemPosition = (cycleItemsUpdate) => {
    return axios.post(`${url}/scanning/update-tray-position`, { cycleItemsUpdate });
}

export const getOrganizations = () => {
    return axios.get(`${url}/organizations/admin/dropdown-options`);
}

export const addOrganization = (organization) => {
    return axios.post(`${url}/organizations/admin/addnew`, { organization })
}

export const generateResultsReport = filters => {
    return axios.get(`${url}/scanning/results-report/?${qs.stringify(filters)}`, { responseType: 'blob' }).then(response => {
        fileDownload(response.data, 'export.csv');
    });
}

export const generateShippedReport = filters => {
    return axios.get(`${url}/scanning/shipped-barcode-report/?${qs.stringify(filters)}`, { responseType: 'blob' }).then(response => {
        fileDownload(response.data, 'export.csv');
    });
}