import { css, jsx } from "@emotion/core";
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useSound from "use-sound";
import scanErrorSfx from "../../../assets/sounds/Check.mp3";
import newAddSfx from "../../../assets/sounds/NewAdd.mp3";
import { Badge } from "../../../components/badge.component";
import { CycleList } from "../../../components/cycle-list.component";
import { RemoveItemButton } from "../../../components/delete-button.component";
import { NoteToolTip } from "../../../components/note-tooltip.component";
import { Permission } from "../../../components/permission.component";
import { useScanner } from "../../../hooks/use-scanner.hook";
import {
  addItemToCycle,
  getCycleById,
  removeItemFromCycleByPosition,
  sendCycleToTest,
  updateCycleItemPosition,
} from "../../../services/scanning.service";
import { format } from "../../../utils/code-format";
import { getFullNameKanji } from "../../../utils/full-name";
import { dynamicSort } from "../../../utils/list-sort";

/** @jsx jsx */
export const NewCyclePage = () => {
  const { t } = useTranslation();
  const { cycleId } = useParams();
  const history = useHistory();
  const duplicateTimerReference = useRef();
  const notFoundTimerReference = useRef();
  const [currentCycleList, setCurrentCycleList] = useState([]);
  const [isAlreadyScannedError, setIsAlreadyScannedError] = useState("");
  const [isNotFoundError, setIsNotFoundError] = useState("");
  const [scanErrorSound] = useSound(scanErrorSfx);
  const [scanNewAddSound] = useSound(newAddSfx);
  const [cycle, setCycle] = useState({});
  const [sortOrder, setSortOrder] = useState("ASC");

  const handleProcessClick = () => {
    sendCycleToTest(cycleId)
      .then(() => history.push("/scan/cycles"))
      .catch((error) => console.error(`fetching cycle ${cycleId} error`, error));
  };

  const [scannedCode, setBarcode] = useScanner();

  const isOnList = useCallback((code) => {
    if (code === "000000000000") {
      return false;
    } else {
      return currentCycleList.find((item) => item.barcode === code);
    }
  }, [currentCycleList]);

  // load items in this cycle id
  useEffect(() => {
    if (cycleId) {
      getCycleById(cycleId)
        .then((response) => {
          setCycle(response.data);
        })
        .catch((error) => console.error(`fetching cycle ${cycleId} error`, error));
    }
  }, [cycleId]);

  useEffect(() => {
    if (cycle) {
      setCurrentCycleList(cycle.bulkScanItems);
    }
  }, [cycle]);

  useEffect(() => {
    if (scannedCode !== "" && scannedCode.length === 12) {
      const code = scannedCode;
      setIsAlreadyScannedError("");
      if (!isOnList(code)) {
        addItemToCycle(cycleId, code)
          .then((response) => {
            setCycle(response.data);
            setCurrentCycleList(response.data.bulkScanItems);
            scanNewAddSound();
            setTimeout(() => {
              const element = document.querySelector(".content");
              element.scrollTop = element.scrollHeight;
            }, 50);
          })
          .catch((error) => {
            scanErrorSound();
            setIsNotFoundError(`Barcode ${code} is not bind to registration.`);
            if (notFoundTimerReference.current) {
              clearTimeout(notFoundTimerReference.current);
            }
            notFoundTimerReference.current = setTimeout(() => {
              setIsNotFoundError("");
            }, 5000);
            console.error(`Barcode ${code} is not bind to registration.`, error);
          });
      } else {
        scanErrorSound();
        setIsAlreadyScannedError(scannedCode);
        if (duplicateTimerReference.current) {
          clearTimeout(duplicateTimerReference.current);
        }
        duplicateTimerReference.current = setTimeout(() => {
          setIsAlreadyScannedError("");
        }, 5000);
      }
      setBarcode("");
    }
  }, [scannedCode, isOnList, scanErrorSound, scanNewAddSound, cycleId, setBarcode]);

  const handleRemoveItem = (position) => {
    removeItemFromCycleByPosition(cycleId, position)
      .then((response) => {
        setCurrentCycleList(response.data.bulkScanItems);
      })
      .catch((error) => console.error(`remove item at position ${position}`, error));
  };

  const handleColumnHeaderClick = (column) => {
    if (column.key === "positionnumber") {
      let sortedList = [...currentCycleList];
      if (sortOrder === "ASC") {
        sortedList.sort(dynamicSort("trayPosition"));
        setSortOrder("DESC");
      } else {
        sortedList.sort(dynamicSort("-trayPosition"));
        setSortOrder("ASC");
      }
      setCurrentCycleList(sortedList);
    }
  };

  const getInventoryCompanyNameByBarcode = (barcode) =>
    cycle.inventoryItems?.find((a) => a.barcode === barcode)?.preassignedOrganizationName || "";

  const cycleListColumnsConfig = () => {
    return [
      {
        minWidth: 30,
        maxWidth: 30,
        name: "#",
        key: "positionnumber",
        fieldName: "positionnumber",
        className: "cycleListItem cycleListItem--number",
        onRender: (item) => `# ${item.trayPosition}`,
      },
      {
        minWidth: 120,
        maxWidth: 120,
        key: "barcode",
        name: t("Testing number"),
        fieldName: "barcode",
        className: "cycleListItem",
        isResizable: true,
        onRender: (item) => <p className="cycleListItem__barcode">{format(item.barcode)}</p>,
      },
      {
        minWidth: 120,
        maxWidth: 160,
        key: "fullname",
        name: t("Full name"),
        fieldName: "fullname",
        className: "cycleListItem",
        isResizable: true,
        onRender: (item) => (
          <span className="cycleListItem__name">
            {item.barcode === "000000000000"
              ? "その他"
              : `${getFullNameKanji(item.registration?.patient)}`}
          </span>
        ),
      },
      {
        minWidth: 120,
        maxWidth: 200,
        key: "orgName",
        name: t("Org name"),
        fieldName: "orgName",
        className: "cycleListItem",
        isResizable: true,
        onRender: (
          item,
        ) => (`${item.registration?.patient?.companyName || ""}/${
          getInventoryCompanyNameByBarcode(item.registration?.barcode) || ""
        }`),
      },
      {
        key: "registered",
        name: t("Registered"),
        fieldName: "registered",
        className: "cycleListItem",
        minWidth: 120,
        maxWidth: 200,
        onRender: (item) => (item.registration?.modifiedAt
          ? dayjs(item.registration.modifiedAt).format("YYYY年MM月DD日")
          : "N/A"),
      },
      {
        key: "additions",
        name: t("Additions"),
        fieldName: "additions",
        className: "additions",
        minWidth: 200,
        onRender: (item) => (
          <div className="badges">
            <Badge type={item?.registration?.isPriority ? "danger" : "default"}>{t("Rapid")}
            </Badge>&nbsp;
            <Badge type={item?.registration?.requiresProof ? "primary" : "default"}>
              {t("PONR")}
            </Badge>
          </div>
        ),
      },
      {
        key: "note",
        name: t("Note"),
        fieldName: "note",
        className: "cycleListItem",
        minWidth: 120,
        maxWidth: 200,
        onRender: (item) =>
          item.registration?.patient?.note
            ? <NoteToolTip noteDetail={item.registration?.patient?.note} tooltipId={item.barcode} />
            : "N/A",
      },
      {
        key: "operation",
        name: t("Operation"),
        fieldName: "operation",
        className: "cycleListItem cycleListItem--delete",
        onRender: (item) =>
          item.barcode
            ? (
              <RemoveItemButton
                deleteMessage={t("Are you sure you want to delete this item?")}
                onClick={() => handleRemoveItem(item.trayPosition)}
              />
            )
            : "",
      },
    ];
  };

  const cycleListColumns = cycleListColumnsConfig();

  const handleSkipClick = useCallback(() => {
    addItemToCycle(cycleId, "FFFFFFFFFFFF")
      .then((response) => {
        setCurrentCycleList(response.data.bulkScanItems);
      })
      .catch((error) =>
        console.error(`add barcode #FFFFFFFFFFFF to cycle #${cycleId} failed`, error)
      );
  }, [setCurrentCycleList, cycleId]);

  const stackStyles = {
    root: {
      width: `100%`,
    },
  };

  const handleUpdateList = useCallback((source, destination) => {
    const cycleItemsUpdate = { cycleId: Number(cycleId), source, destination };
    // update item tray position avoid jump back effect
    const cycleBeforeUpdate = [...currentCycleList];
    const swapItem = currentCycleList.find((item) => item.trayPosition === destination);
    if (swapItem) {
      const updatingList = currentCycleList.map((item) => {
        if (item.trayPosition === source) {
          item.trayPosition = destination;
        } else if (item.trayPosition === destination) {
          item.trayPosition = source;
        }
        return item;
      });
      setCurrentCycleList(updatingList);
    } else {
      const updatingList = currentCycleList.map((item) => {
        if (item.trayPosition === source) {
          item.trayPosition = destination;
        }
        return item;
      });
      setCurrentCycleList(updatingList);
    }

    // backend update
    updateCycleItemPosition(cycleItemsUpdate)
      .then((response) => {
        console.log("updated");
      })
      .catch((error) => {
        console.error("update list", error);
        // reverse list items if backend update failed
        setCurrentCycleList(cycleBeforeUpdate);
      });
  }, [currentCycleList, cycleId]);

  return (
    <div css={styles} className="animationIn">
      <div className="header-splitted">
        <div className="left">
          <h1 style={{ marginBottom: "0" }}>{`${t("Cycle")} #${cycleId}`}</h1>
        </div>
        <Permission only="Cycle.Edit">
          <div className="right hide-in-print">
            <TextField
              value={scannedCode}
              placeholder="Barcode"
              onChange={(e) => {
                const code = e.target.value;
                if (!Number.isNaN(Number(code))) {
                  setBarcode(code);
                }
              }}
            />
          </div>
        </Permission>
      </div>
      <CycleList
        cycleList={currentCycleList}
        updateList={handleUpdateList}
        cycleListColumns={cycleListColumns}
        onColumnHeaderClick={handleColumnHeaderClick}
      />

      <div className="ms-depth-4 bg-white section-container hide-in-print">
        <Stack tokens={{ childrenGap: 40 }} horizontalAlign="start">
          {isAlreadyScannedError !== ""
            && (
              <MessageBar
                messageBarType={MessageBarType.error}
              >
                Barcode {scannedCode} is already on the list.
              </MessageBar>
            )}

          {isNotFoundError !== ""
            && (
              <MessageBar
                messageBarType={MessageBarType.error}
              >
                {isNotFoundError}
              </MessageBar>
            )}
          <Permission only="Cycle.Edit">
            <Stack horizontalAlign="end" styles={stackStyles}>
              <PrimaryButton text={t("Add skip")} onClick={() => handleSkipClick()} />
            </Stack>
          </Permission>

          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <DefaultButton text={t("Back")} onClick={() => history.push("/scan/cycles")} />
            <Permission only="Cycle.View">
              <DefaultButton
                text={t("Print")}
                onClick={() => {
                  window.print();
                }}
              />
            </Permission>
            <Permission only="Cycle.Edit">
              <PrimaryButton text={t("Process")} onClick={handleProcessClick} />
            </Permission>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

const styles = css`

    .header-splitted {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .right {
            width: 250px;
        }
    }

    .cycleListItem {
        display: inline-flex;
        align-items: center;
        
        &--number {
            font-size: 14px;
            font-weight: bold;
        }

        &--delete{
            .clearButton {
                margin-left: 16px;
            }
        }
        &__barcode {
            opacity: .9;
            font-weight: bold;
        }
    }
`;
