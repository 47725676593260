import React from 'react';
import { useTranslation } from 'react-i18next';

export const IndexPage = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h1>{t('Setting')}</h1>
            <p>{t('navigation on the left')}</p>
        </div>
    );
}