import axios from '../config/axios';
import qs from 'query-string';
import fileDownload from 'js-file-download';

const url = process.env.REACT_APP_BASE_API;

export const getOrganizations = (options) => {
    return axios.get(`${url}/organizations?${qs.stringify(options)}`);
};

export const getOrganization = (organizationId, filters) => {
    return axios.get(`${url}/organizations/admin/view/${organizationId}${ filters ? `?${qs.stringify(filters)}` : ''}`);
};

export const checkOrganizationDefaultUser = organizationId => {
    return axios.get(`${url}/organizations/admin/check-user/${organizationId}`);
};

export const getOrganizationReport = (organizationId, filters) => {
    return axios.get(`${url}/organizations/admin/report/${organizationId}${ filters ? `?${qs.stringify(filters)}` : ''}`, { responseType: 'blob' }).then(response => {
        fileDownload(response.data, 'export.csv');
    });
};

export const createOrganization = (organization) => {
    return axios.post(`${url}/organizations/admin/create`, { organization });
};

export const updateOrganization = (organizationId, updatedOrganization) => {
    return axios.patch(`${url}/organizations/admin/view/${organizationId}`, updatedOrganization);
}

export const deleteOrganization = (organizationId) => {
    return axios.delete(`${url}/organizations/admin/delete/${organizationId}`);
}

export const bulkImport = (bulkImportCsv, organizationId) => {
    const formData = new FormData();
    formData.append('file', bulkImportCsv);
    return axios.post(`${url}/organizations/admin/import/${organizationId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const inviteOrganizationUser = organization => {
    return axios.post(`${url}/organizations/admin/invite-organization-user`, { organization });
}