import { css, jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { ShimmeredDetailsList, SelectionMode, SearchBox, DefaultButton } from '@fluentui/react';

import { getAdministrators } from '../../services/users.service';
import { Permission } from '../../components/permission.component';

const shimmeredDetailsListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

/** @jsx jsx */
export const IndexPage = ({ children }) => {

    const history = useHistory();
    const { t } = useTranslation();
    const [administrators, setAdministrators] = useState(null);
    const [keywords, setKeywords] = useState('');

    useEffect(() => {
        const filters = {};

        if (keywords) {
            filters.keywords = keywords;
        }

        getAdministrators(filters).then(response => {
            setAdministrators(response.data);
        })
    }, [keywords]);

    return <div css={styles}>
        <div className="left-right">
            <h1>{t('Administrators')}</h1>
            <Permission only="Administrator.Edit">
                <DefaultButton
                    text={t('Add administrator')}
                    onClick={() => history.push(`/administrators/create`)}
                />
            </Permission>
        </div>
        <SearchBox placeholder={t("Search")} onSearch={newValue => setKeywords(newValue)} />
        <ShimmeredDetailsList
            setKey="items"
            items={administrators}
            columns={administratorsColumns(t)}
            selectionMode={SelectionMode.none}
            enableShimmer={administrators === null}
            listProps={shimmeredDetailsListProps}
        />
    </div>
};

const styles = css``;

export const administratorsColumns = t => [
    {
        key: 'name',
        name: t('Name'),
        fieldName: 'name',
        isSorted: true,
        onRender: item => <Link to={`/administrators/${item.id}`}>{item.firstName} {item.lastName}</Link>
    },
    {
        key: 'emailAddress',
        name: t('Email address'),
        fieldName: 'emailAddress',
        minWidth: 220
    },
    {
        key: 'role',
        name: t('Role'),
        fieldName: 'role',
        onRender: item => item.role && <Link to={`/roles/${item.role.id}`}>{item.role.name}</Link>
    }
];